/*
 * FORM VALIDATION
 */

.form-group {
    position: relative;
}

.form-control-error {
    background: #fb434a;
    padding: 6px 8px 5px 0;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    position: absolute;
    right: 0;
    bottom: 37px;
    margin-bottom: 8px;
    max-width: 230px;
    font-size: 80%;
    z-index: 1;
}

.form-control-input-error {
    color: red;
    border-color: red;
}

.form-control-error {
    &:after {
        width: 0;
        height: 0;
        content: '';
        display: block;
        border-style: solid;
        border-width: 5px 5px 0;
        border-color: #fb434a transparent transparent;
        position: absolute;
        right: 20px;
        bottom: -4px;
        margin-left: -5px;
    }

    ul {
        list-style: none;
        color: #ffffff;
        padding: 0;
        margin: 0;
    }
}

.form-control-error-list ul {
    list-style: none;
    color: #fb434a;
    padding: 0;
    margin: 5px 0 0 0;
    font-size: 80%;
    font-weight: 400;
}

.has-danger {
    .select2-selection--single, .select2-selection--multiple {
        border-color: #fb434a !important;
    }
}

/* MATERIAL DESIGN
.mat-form-field .mat-hint {
    margin-top: 15px;
}
*/
