/*
 * BUTTONS
 */

.btn {
    -webkit-border-radius: 0;
    border-radius: 3px;
    -webkit-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    font-weight: bold !important;
    line-height: 1.7;
    outline: none !important;
    padding: 4px 20px;

    &:hover, &:active {
        -webkit-transition: all 0.05s ease-in-out;
        -o-transition: all 0.05s ease-in-out;
        transition: all 0.05s ease-in-out;
    }

    &.btn-rounded {
        -webkit-border-radius: 100px;
        border-radius: 100px;
    }

    &.btn-squared {
        -webkit-border-radius: 0;
        border-radius: 0;
    }

    &.btn-xs {
        padding: 1px 5px;
        font-size: 10px;
    }

    &.btn-sm {
        padding: 4px 11px;
    }

    &.btn-lg {
        padding: 12px 20px;
    }

    &.btn-link {
        color: #827ca1;
        border-color: transparent !important;
        background: none !important;
        text-decoration: none;

        &:hover, &:active, &:focus, &.active {
            color: #0088ff;
        }
    }

    &.btn-icon {
        padding: 10px;
        line-height: 1rem;
        text-decoration: none !important;

        &.btn-xs {
            padding: 5px;
            font-size: 10px;
        }

        &.btn-sm {
            padding: 7px;
        }

        &.btn-lg {
            padding: 20px;
        }
    }
}

.btn-group-vertical > label {
    margin-bottom: 0;
}

.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;

    > .btn-group {
        float: none;
        display: table-cell;
        width: 1%;

        > .btn {
            width: 100%;
        }
    }

    &.btn-group-vertical {
        display: block;

        > .btn-group {
            display: block;
            width: 100%;
        }
    }
}

.btn, .open > .btn {
    /* CLEAR BUTTON */
    color: #827ca1;
    background-color: #ffffff;
    border-color: #dfe4ed;

    /* DEFAULT BUTTONS */
    /* OUTLINED BUTTONS */
}

.btn {
    &:hover {
        cursor: pointer;
        background-color: #e6eaf0;
        border-color: #e6eaf0;
    }

    &:active {
        background-color: #e6eaf0;
        border-color: #e6eaf0;
    }
}

.open > .btn {
    &:hover, &:active {
        background-color: #e6eaf0;
        border-color: #e6eaf0;
    }
}

.btn {
    &:hover:active, &:focus, &.active {
        background: #d9dfe9;
        border-color: #d9dfe9;
    }
}

.open > .btn {
    &:hover:active, &:focus, &.active {
        background: #d9dfe9;
        border-color: #d9dfe9;
    }
}

.btn {
    &.btn-default, &.btn-primary, &.btn-secondary, &.btn-success, &.btn-info, &.btn-warning, &.btn-danger {
        color: #ffffff !important;
    }
}

.open > .btn {
    &.btn-default, &.btn-primary, &.btn-secondary, &.btn-success, &.btn-info, &.btn-warning, &.btn-danger {
        color: #ffffff !important;
    }
}

.btn.btn-default, .open > .btn.btn-default {
    background-color: #e3dfcb;
    border-color: #e3dfcb;
    color: #292b2c !important;
}

.btn.btn-default {
    &:hover, &:active {
        background-color: #f1efe5;
        border-color: #f1efe5;
    }
}

.open > .btn.btn-default {
    &:hover, &:active {
        background-color: #f1efe5;
        border-color: #f1efe5;
    }
}

.btn.btn-default {
    &:hover:active, &:focus, &.active {
        background: #cec7a5;
        border-color: #cec7a5;
    }
}

.open > .btn.btn-default {
    &:hover:active, &:focus, &.active {
        background: #cec7a5;
        border-color: #cec7a5;
    }
}

.btn.btn-primary, .open > .btn.btn-primary {
    background-color: #5ac4f1;
    border-color: #5ac4f1;
}

.btn.btn-primary {
    &:hover, &:active {
        background-color: #7ed1f4;
        border-color: #7ed1f4;
    }
}

.open > .btn.btn-primary {
    &:hover, &:active {
        background-color: #7ed1f4;
        border-color: #7ed1f4;
    }
}

.btn.btn-primary {
    &:hover:active, &:focus, &.active {
        background: #36b7ee !important;
        border-color: #36b7ee !important;
    }
}

.open > .btn.btn-primary {
    &:hover:active, &:focus, &.active {
        background: #36b7ee !important;
        border-color: #36b7ee !important;
    }
}

.btn.btn-secondary, .open > .btn.btn-secondary {
    background-color: #6a7a84;
    border-color: #6a7a84;
}

.btn.btn-secondary {
    &:hover, &:active {
        background-color: #7e8e97;
        border-color: #7e8e97;
    }
}

.open > .btn.btn-secondary {
    &:hover, &:active {
        background-color: #7e8e97;
        border-color: #7e8e97;
    }
}

.btn.btn-secondary {
    &:hover:active, &:focus, &.active {
        background: #59666e;
        border-color: #59666e;
    }
}

.open > .btn.btn-secondary {
    &:hover:active, &:focus, &.active {
        background: #59666e;
        border-color: #59666e;
    }
}

.btn.btn-success, .open > .btn.btn-success {
    background-color: #46be8a;
    border-color: #46be8a;
}

.btn.btn-success {
    &:hover, &:active {
        background-color: #63c89c;
        border-color: #63c89c;
    }
}

.open > .btn.btn-success {
    &:hover, &:active {
        background-color: #63c89c;
        border-color: #63c89c;
    }
}

.btn.btn-success {
    &:hover:active, &:focus, &.active {
        background: #39a375;
        border-color: #39a375;
    }
}

.open > .btn.btn-success {
    &:hover:active, &:focus, &.active {
        background: #39a375;
        border-color: #39a375;
    }
}

.btn.btn-info, .open > .btn.btn-info {
    background-color: #0190da;
    border-color: #0190da;
}

.btn.btn-info {
    &:hover, &:active {
        background-color: #04a9fe;
        border-color: #04a9fe;
    }
}

.open > .btn.btn-info {
    &:hover, &:active {
        background-color: #04a9fe;
        border-color: #04a9fe;
    }
}

.btn.btn-info {
    &:hover:active, &:focus, &.active {
        background: #0176b3;
        border-color: #0176b3;
    }
}

.open > .btn.btn-info {
    &:hover:active, &:focus, &.active {
        background: #0176b3;
        border-color: #0176b3;
    }
}

.btn.btn-warning, .open > .btn.btn-warning {
    background-color: #ffa300;
    border-color: #ffa300;
}

.btn.btn-warning {
    &:hover, &:active {
        background-color: #ffb127;
        border-color: #ffb127;
    }
}

.open > .btn.btn-warning {
    &:hover, &:active {
        background-color: #ffb127;
        border-color: #ffb127;
    }
}

.btn.btn-warning {
    &:hover:active, &:focus, &.active {
        background: #d88a00;
        border-color: #d88a00;
    }
}

.open > .btn.btn-warning {
    &:hover:active, &:focus, &.active {
        background: #d88a00;
        border-color: #d88a00;
    }
}

.btn.btn-danger, .open > .btn.btn-danger {
    background-color: #fb434a;
    border-color: #fb434a;
}

.btn.btn-danger {
    &:hover, &:active {
        background-color: #fc696f;
        border-color: #fc696f;
    }
}

.open > .btn.btn-danger {
    &:hover, &:active {
        background-color: #fc696f;
        border-color: #fc696f;
    }
}

.btn.btn-danger {
    &:hover:active, &:focus, &.active {
        background: #fa1d25;
        border-color: #fa1d25;
    }
}

.open > .btn.btn-danger {
    &:hover:active, &:focus, &.active {
        background: #fa1d25;
        border-color: #fa1d25;
    }
}

.btn {
    &.btn-default-outline, &.btn-primary-outline, &.btn-secondary-outline, &.btn-success-outline, &.btn-info-outline, &.btn-warning-outline, &.btn-danger-outline {
        background-color: #ffffff;
    }
}

.open > .btn {
    &.btn-default-outline, &.btn-primary-outline, &.btn-secondary-outline, &.btn-success-outline, &.btn-info-outline, &.btn-warning-outline, &.btn-danger-outline {
        background-color: #ffffff;
    }
}

.btn.btn-default-outline, .open > .btn.btn-default-outline {
    border-color: #e3dfcb;
    color: #e3dfcb;
}

.btn.btn-default-outline {
    &:hover, &:active {
        background-color: #f1efe5;
        border-color: #f1efe5;
    }
}

.open > .btn.btn-default-outline {
    &:hover, &:active {
        background-color: #f1efe5;
        border-color: #f1efe5;
    }
}

.btn.btn-default-outline {
    &:hover:active, &:focus, &.active {
        background: #cec7a5;
        border-color: #cec7a5;
        color: #ffffff;
    }
}

.open > .btn.btn-default-outline {
    &:hover:active, &:focus, &.active {
        background: #cec7a5;
        border-color: #cec7a5;
        color: #ffffff;
    }
}

.btn.btn-primary-outline, .open > .btn.btn-primary-outline {
    border-color: #5ac4f1;
    color: #5ac4f1;
}

.btn.btn-primary-outline {
    &:hover, &:active {
        background-color: #1fb2fe;
        border-color: #1fb2fe;
        color: #ffffff;
    }
}

.open > .btn.btn-primary-outline {
    &:hover, &:active {
        background-color: #1fb2fe;
        border-color: #1fb2fe;
        color: #ffffff;
    }
}

.btn.btn-primary-outline {
    &:hover:active, &:focus, &.active {
        background: #0194e0;
        border-color: #0194e0;
        color: #ffffff;
    }
}

.open > .btn.btn-primary-outline {
    &:hover:active, &:focus, &.active {
        background: #0194e0;
        border-color: #0194e0;
        color: #ffffff;
    }
}

.btn.btn-secondary-outline, .open > .btn.btn-secondary-outline {
    border-color: #6a7a84;
    color: #6a7a84;
}

.btn.btn-secondary-outline {
    &:hover, &:active {
        background-color: #7e8e97;
        border-color: #7e8e97;
        color: #ffffff;
    }
}

.open > .btn.btn-secondary-outline {
    &:hover, &:active {
        background-color: #7e8e97;
        border-color: #7e8e97;
        color: #ffffff;
    }
}

.btn.btn-secondary-outline {
    &:hover:active, &:focus, &.active {
        background: #59666e;
        border-color: #59666e;
        color: #ffffff;
    }
}

.open > .btn.btn-secondary-outline {
    &:hover:active, &:focus, &.active {
        background: #59666e;
        border-color: #59666e;
        color: #ffffff;
    }
}

.btn.btn-success-outline, .open > .btn.btn-success-outline {
    border-color: #46be8a;
    color: #46be8a;
}

.btn.btn-success-outline {
    &:hover, &:active {
        background-color: #63c89c;
        border-color: #63c89c;
        color: #ffffff;
    }
}

.open > .btn.btn-success-outline {
    &:hover, &:active {
        background-color: #63c89c;
        border-color: #63c89c;
        color: #ffffff;
    }
}

.btn.btn-success-outline {
    &:hover:active, &:focus, &.active {
        background: #39a375;
        border-color: #39a375;
        color: #ffffff;
    }
}

.open > .btn.btn-success-outline {
    &:hover:active, &:focus, &.active {
        background: #39a375;
        border-color: #39a375;
        color: #ffffff;
    }
}

.btn.btn-info-outline, .open > .btn.btn-info-outline {
    border-color: #0190da;
    color: #0190da;
}

.btn.btn-info-outline {
    &:hover, &:active {
        background-color: #04a9fe;
        border-color: #04a9fe;
        color: #ffffff;
    }
}

.open > .btn.btn-info-outline {
    &:hover, &:active {
        background-color: #04a9fe;
        border-color: #04a9fe;
        color: #ffffff;
    }
}

.btn.btn-info-outline {
    &:hover:active, &:focus, &.active {
        background: #0176b3;
        border-color: #0176b3;
        color: #ffffff;
    }
}

.open > .btn.btn-info-outline {
    &:hover:active, &:focus, &.active {
        background: #0176b3;
        border-color: #0176b3;
        color: #ffffff;
    }
}

.btn.btn-warning-outline, .open > .btn.btn-warning-outline {
    border-color: #ffa300;
    color: #ffa300;
}

.btn.btn-warning-outline {
    &:hover, &:active {
        background-color: #ffb127;
        border-color: #ffb127;
        color: #ffffff;
    }
}

.open > .btn.btn-warning-outline {
    &:hover, &:active {
        background-color: #ffb127;
        border-color: #ffb127;
        color: #ffffff;
    }
}

.btn.btn-warning-outline {
    &:hover:active, &:focus, &.active {
        background: #d88a00;
        border-color: #d88a00;
        color: #ffffff;
    }
}

.open > .btn.btn-warning-outline {
    &:hover:active, &:focus, &.active {
        background: #d88a00;
        border-color: #d88a00;
        color: #ffffff;
    }
}

.btn.btn-danger-outline, .open > .btn.btn-danger-outline {
    border-color: #fb434a;
    color: #fb434a;
}

.btn.btn-danger-outline {
    &:hover, &:active {
        background-color: #fc696f;
        border-color: #fc696f;
        color: #ffffff;
    }
}

.open > .btn.btn-danger-outline {
    &:hover, &:active {
        background-color: #fc696f;
        border-color: #fc696f;
        color: #ffffff;
    }
}

.btn.btn-danger-outline {
    &:hover:active, &:focus, &.active {
        background: #fa1d25;
        border-color: #fa1d25;
        color: #ffffff;
    }
}

.open > .btn.btn-danger-outline {
    &:hover:active, &:focus, &.active {
        background: #fa1d25;
        border-color: #fa1d25;
        color: #ffffff;
    }
}

/* Material Design overrides */
.mdc-button, .mdc-mat-button {
    border-radius: 3px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 1.7;
    letter-spacing: normal;
    outline: none;
    padding: 4px 20px;
    transition: all 0.1s ease-in-out;
}

.mat-action-row, .mat-dialog-actions {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
}

/* ladda buttons */

.ladda-button .ladda-spinner {
    position: relative;
    top: -10px;
    left: 0;
}
