/*
 * BREADCRUMBS
 */

.breadcrumb-custom {
    display: inline-block;
    padding: 0;
    margin-bottom: 0;

    li {
        padding: 0;

        > {
            a, span {
                display: inline-block;
                padding: 9px 30px 9px 30px;
                position: relative;
                background: #eceeef;
                margin: 0 8px 6px;
            }

            a:before, span:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: -10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 39px 10px 0 0;
                border-color: transparent #eceeef transparent transparent;
                -webkit-transition: all 0.2s ease-in-out;
                -o-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;
            }

            a:after, span:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: -10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 39px 10px;
                border-color: transparent transparent transparent #eceeef;
                -webkit-transition: all 0.2s ease-in-out;
                -o-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;
            }

            a {
                -webkit-transition: all 0.2s ease-in-out;
                -o-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background: #0088ff;
                    color: #ffffff;

                    &:before {
                        border-color: transparent #0088ff transparent transparent;
                    }

                    &:after {
                        border-color: transparent transparent transparent #0088ff;
                    }
                }
            }
        }

        &:first-child > {
            a, span {
                margin-left: 0;
            }

            a:before, span:before {
                display: none;
            }
        }

        &:last-child > {
            a:after, span:after {
                display: none;
            }
        }

        &:after {
            padding: 0;
            content: "";
        }
    }
}

.theme-inverse .breadcrumb-custom li > {
    a, span {
        color: #bbb8cb;
        background: #514d6a;
    }

    a:before, span:before {
        border-color: transparent #514d6a transparent transparent;
    }

    a:after, span:after {
        border-color: transparent transparent transparent #514d6a;
    }

    a:hover {
        background: #0088ff;
        color: #ffffff;

        &:before {
            border-color: transparent #0088ff transparent transparent;
        }

        &:after {
            border-color: transparent transparent transparent #0088ff;
        }
    }
}
