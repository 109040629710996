.partner-details {
    .mdc-text-field {
        letter-spacing: 0;
        padding: 0;

        &--filled:not(.mdc-text-field--disabled) {
            background-color: transparent;
        }
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
    }

    .mat-mdc-form-field {
        .mat-mdc-input-element {
            font-size: 14px;
        }
    }

    .mdc-floating-label {
        letter-spacing: normal;
    }

    .pd-name {
        .mdc-floating-label {
            font-size: 24px;
        }

        .mat-mdc-form-field {
            .mat-mdc-input-element {
                font-size: 24px;
            }
        }
    }

    .mat-mdc-select-value {
        font-size: 14px;
    }

    .mdc-form-field > label {
        color: #000000;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        padding-left: 0;
        margin-bottom: 0;
        margin-right: 5px;
    }

    mat-error {
        letter-spacing: normal;
    }

    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
        padding: 0;
    }

    .single-form {
        .mdc-floating-label {
            color: #FFFFFF;
            font-weight: 600;
            font-size: 14px;
        }

        .mat-mdc-form-field {
            color: #FFFFFF;

            .mat-mdc-input-element {
                color: #FFFFFF;
                font-weight: 600;
                font-size: 14px;

                &::placeholder {
                    color: #FFFFFF;
                }

                &.ng-invalid {
                    color: #f44336;
                }
            }

            .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
                border-bottom-color: rgba(255, 255, 255, 1);
            }

            .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
                border-bottom-color: #f44336;
            }

            &-icon-prefix {
                padding-right: 10px;
            }
        }
    }

    mat-radio-group, mat-checkbox {
        margin-left: -10px;
    }


    .config-settings-group {
        .mat-mdc-form-field-icon-suffix {
            color: #5E5F60;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            padding-left: 5px;
            transform: translateY(8px);
        }
    }

    .mat-radio-button {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;

        .mat-radio-label-content {
            display: flex;
            align-items: center;
        }
    }

    .mat-form-field.ng-touched.ng-dirty.mat-form-field-invalid {
        .mat-input-element {
            color: #f44336;
        }
    }
}

.list {
    form {
        .mat-mdc-checkbox {
            label {
                margin-bottom: 0;
            }
        }

        .mat-mdc-icon-button.mat-mdc-button-base {
            font-size: initial;
        }
    }
}
