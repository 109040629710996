/*
 * TABS
 */

.mat-ink-bar {
    background-color: #ff0069 !important;
    height: 3px !important;
}

.mat-tab-label {
    font-size: 16px !important;
    font-weight: normal;

    &.mat-tab-label-active {
        font-weight: bold;
    }
}

/* Styles for the active tab label */

.nav-tabs .nav-link {
    &:focus, &:hover {
        border-color: transparent;
    }
}

.nav-tabs-horizontal .nav-tabs {
    border-bottom: 1px solid #dfe4ed;

    .nav-item {
        margin-bottom: -2px;
        cursor: pointer;

        .nav-link {
            border: none;
            border-bottom: 3px solid transparent;

            &.active, &:focus {
                border-bottom-color: #ff0069 !important;
            }

            &:hover {
                border-bottom-color: #dfe4ed;
            }
        }
    }
}

.nav-tabs-vertical .nav-tabs {
    border-bottom: none;
    border-right: 1px solid #dfe4ed;
    float: left;
    margin-right: 30px;

    .nav-item {
        margin: 0 -2px 1px 0;
        float: none;
        cursor: pointer;

        .nav-link {
            border: none;
            border-right: 3px solid transparent;
            padding-left: 0;
            -webkit-border-radius: 0;
            border-radius: 0;

            &.active, &:focus {
                border-right-color: #5ac4f1 !important;
            }

            &:hover {
                border-right-color: #dfe4ed;
            }
        }
    }
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: normal;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #000;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: #96006B;
    border-width: 3px;
}

.mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    margin: 0 1rem;
}

.partners-tabs {
    .mat-mdc-tab-body-content {
        overflow: hidden;
    }

    .mdc-tab {
        padding: 0 48px;
    }

    .fees-form {
        .mat-mdc-tab-header {
            margin: 0 25px;
        }
    }
}
