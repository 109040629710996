/*
 * BADGES & LABELS
 */

.label {
    font-weight: normal;
    background: #ffffff;
    color: #6a7a84;
    font-size: 100%;

    &.label-default {
        background: #e3dfcb;
        color: #000000;
    }

    &.label-primary {
        background: #5ac4f1;
        color: #ffffff;
    }

    &.label-secondary {
        background: #6a7a84;
        color: #ffffff;
    }

    &.label-success {
        background: #46be8a;
        color: #ffffff;
    }

    &.label-info {
        background: #0190da;
        color: #ffffff;
    }

    &.label-danger {
        background: #fb434a;
        color: #ffffff;
    }

    &.label-warning {
        background: #ffa300;
        color: #ffffff;
    }
}
