/*
 * NOTIFICATIONS & ALERTS
 */

.alert {
    border: none;
    font-weight: normal;
    color: #ffffff;

    .close {
        color: #ffffff;
        opacity: .5;
        outline: none !important;

        &:hover {
            opacity: 1;
        }
    }

    a, .alert-link {
        font-weight: normal;
        color: #ffffff;
        opacity: .7;
        -webkit-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
    }

    a:hover, .alert-link:hover {
        opacity: 1;
    }

    &.alert-default {
        background: #e3dfcb;
    }

    &.alert-primary {
        background: #5ac4f1;
    }

    &.alert-secondary {
        background: #6a7a84;
    }

    &.alert-success {
        background: #46be8a;
    }

    &.alert-info {
        background: #0190da;
    }

    &.alert-warning {
        background: #ffa300;
    }

    &.alert-danger {
        background: #fb434a;
    }
}

.sweet-overlay {
    background: rgba(36, 34, 47, 0.3);
}

.sweet-alert {
    h2 {
        font-weight: 400;
        position: relative;
        margin: 30px 0 15px;
    }

    p {
        margin: 0 0 10px;
    }

    .lead {
        font-weight: 400;
        font-size: 18px;
        color: #514d6a;
        margin-bottom: 20px;
    }

    .btn {
        min-width: 150px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        margin: 0 5px 15px;
        border: none !important;
    }

    .btn-success {
        background: #46be8a;
    }

    .btn-warning {
        border-color: #ffa300;
    }

    .btn-danger {
        border-color: #fb434a;
    }

    .btn-info {
        border-color: #0190da;
    }

    .sa-icon {
        &.sa-success {
            .line {
                background-color: #46be8a;
            }

            .placeholder {
                border-color: #46be8a;
            }
        }

        &.sa-warning {
            border-color: #ffa300;

            span {
                background: #ffa300;
            }
        }

        &.sa-error {
            border-color: #fb434a;

            .line {
                background-color: #fb434a;
            }
        }

        &.sa-info {
            border-color: #0190da;

            &:before, &:after {
                background-color: #0190da;
            }
        }
    }
}

/* Toast notifications */

.toast {
    background-position: 10px 18px !important;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.24) !important;
    color: #FFFFFF;
    opacity: 0.8;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    width: 450px !important;

    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important;
        opacity: 1;
        cursor: pointer;
    }

    &.toast-success {
        background-color: #46be8a;
    }

    &.toast-error {
        background-color: #fb434a;
    }
}
