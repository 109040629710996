/*
 * WIDGETS
 */

.widget {
    background: #ffffff;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 16px;
    position: relative;
    border: 1px solid #dfe4ed;

    .widget-header {
        -webkit-border-radius: 5px 5px 0 0;
        border-radius: 5px 5px 0 0;
    }

    .widget-body {
        display: block;
        -webkit-border-radius: 0 5px 5px 0;
        border-radius: 0 5px 5px 0;
    }

    .label {
        font-size: 85%;
    }

    > .table {
        margin-bottom: 0;

        thead th {
            border-top: none !important;

            &:first-child {
                -webkit-border-radius: 5px 0 0 0;
                border-radius: 5px 0 0 0;
            }

            &:last-child {
                -webkit-border-radius: 0 5px 0 0;
                border-radius: 0 5px 0 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .widget {
        margin-bottom: 15px;
    }
}

@media (max-width: 543px) {
    .widget {
        margin-bottom: .9375rem;
    }
}

.widget-one {
    .widget-header {
        min-height: 100px;
        background-size: cover;
        padding: 40px;
        background-color: #dfe4ed;
        -webkit-border-radius: 5px 5px 0 0;
        border-radius: 5px 5px 0 0;
    }

    .widget-body {
        padding: 40px 25px 40px;

        .s1 {
            float: left;
            margin-top: -75px;
        }

        .s2 {
            margin-left: 130px;
        }

        .avatar {
            height: 70px;
            width: 70px;
            padding: 5px;
            background: #ffffff;

            img {
                -webkit-border-radius: 100%;
                border-radius: 100%;
            }
        }

        .widget-info {
            margin-bottom: 10px;
            max-height: 42px;
            overflow: hidden;
        }
    }
}

.widget-two {
    .widget-header {
        padding: 20px 25px;
        -webkit-border-radius: 5px 5px 0 0;
        border-radius: 5px 5px 0 0;
        border-bottom: 1px solid #eceeef;
    }

    .widget-body {
        padding: 25px;
    }
}

.widget-three {
    padding: 25px;
}

.widget-four {
    border: none;
    margin-bottom: 0 !important;

    .step-block {
        border: 1px solid #dfe4ed;
    }
}

@media (max-width: 1365px) {
    .widget-four .step-block {
        margin-bottom: 16px !important;
    }
}

@media (max-width: 767px) {
    .widget-four .step-block {
        margin-bottom: 15px !important;
    }
}

@media (max-width: 543px) {
    .widget-four .step-block {
        margin-bottom: 10px !important;
    }
}

.widget-five {
    .widget-header {
        padding: 20px 25px;
        -webkit-border-radius: 5px 5px 0 0;
        border-radius: 5px 5px 0 0;
        border-bottom: 1px solid #eceeef;
    }

    .table thead th {
        border-top: none;
    }
}

.widget-six {
    .widget-header {
        padding: 20px 25px;
        -webkit-border-radius: 5px 5px 0 0;
        border-radius: 5px 5px 0 0;
        border-bottom: 1px solid #eceeef;
    }

    .widget-body .chart {
        padding: 10px 10px 5px 0;
    }

    .chart-css-animations {
        -webkit-border-radius: 0 0 5px 5px;
        border-radius: 0 0 5px 5px;
    }
}

.widget-seven {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
    background-size: cover;
    position: relative;

    .widget-body {
        padding: 30px 25px 35px;
        height: 142px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        overflow: hidden;

        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
            color: inherit;
            margin-bottom: 10px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .widget-body-icon {
            display: inline-block;
            font-size: 80px;
            position: absolute;
            z-index: 1;
            bottom: -40px;
            right: -25px;
            opacity: .4;
        }

        .widget-body-inner {
            position: relative;
            z-index: 2;
            height: 100%;
        }
    }

    &.background-primary, &.background-secondary, &.background-default, &.background-info, &.background-danger, &.background-warning, &.background-info, &.background-success, &.background-light-green, &.background-dark-green {
        color: #ffffff;
    }

    &.background-primary {
        a, .counter-icon, .counter-count {
            color: #ffffff;
        }
    }

    &.background-secondary {
        a, .counter-icon, .counter-count {
            color: #ffffff;
        }
    }

    &.background-default {
        a, .counter-icon, .counter-count {
            color: #ffffff;
        }
    }

    &.background-info {
        a, .counter-icon, .counter-count {
            color: #ffffff;
        }
    }

    &.background-danger {
        a, .counter-icon, .counter-count {
            color: #ffffff;
        }
    }

    &.background-warning {
        a, .counter-icon, .counter-count {
            color: #ffffff;
        }
    }

    &.background-info {
        a, .counter-icon, .counter-count {
            color: #ffffff;
        }
    }

    &.background-success {
        a, .counter-icon, .counter-count {
            color: #ffffff;
        }
    }

    &.background-light-green {
        a, .counter-icon, .counter-count {
            color: #ffffff;
        }
    }

    &.background-dark-green {
        a, .counter-icon, .counter-count {
            color: #ffffff;
        }
    }

    h5 {
        display: inline-block;
        padding-bottom: 5px;
    }

    .carousel-item {
        overflow: hidden;
    }

    .counter-icon {
        position: absolute;
        left: 0;
        bottom: 0;

        /*margin-top: 18px;*/
        display: inline-block;
        color: inherit;
        font-size: 36px;
    }

    .counter-count {
        position: absolute;
        bottom: -10px;
        right: 0;
        font-size: 36px;
        color: inherit;

        i {
            position: relative;
            top: -1px;
            margin-right: -4px;
            font-size: 32px;
        }
    }
}

/*
 * WIDGETS GLOBAL
 */

.mode-superclean {
    .widget, .step-block {
        /*border: none;*/
    }
}

.conversation-block {
    overflow: auto;

    .conversation-item {
        padding: 10px 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }

        .s1 {
            float: left;

            .avatar {
                width: 50px;
                height: 50px;
            }
        }

        .s2 {
            padding: 10px 15px 1px;
            background: #f2f4f8;
            margin-left: 70px;
            -webkit-border-radius: 5px;
            border-radius: 5px;
            position: relative;

            &:after {
                position: absolute;
                top: 22px;
                left: -14px;
                width: 0;
                height: 0;
                display: block;
                content: '';
                border: 7px solid transparent;
                border-right-color: #f2f4f8;
            }
        }

        &.you {
            .s1 {
                float: right;
            }

            .s2 {
                margin-left: 0;
                margin-right: 70px;

                &:after {
                    left: auto;
                    right: -14px;
                    border: 7px solid transparent;
                    border-left-color: #f2f4f8;
                }
            }
        }
    }
}

.user-wall {
    .user-wall-item {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #dfe4ed;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        .s1 {
            float: left;

            .avatar {
                width: 50px;
                height: 50px;
            }
        }

        .s2 {
            margin-left: 70px;
        }

        .user-wall-item-head {
            margin-bottom: 10px;

            strong {
                display: block;
            }
        }
    }

    .user-wall-comments {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid #dfe4ed;

        .user-wall-item:last-child {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .user-wall-posting {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid #dfe4ed;
    }
}

@media (max-width: 992px) {
    .user-wall .user-wall-item {
        .s1 {
            float: none;
            margin-bottom: 5px;
        }

        .s2 {
            margin-left: 0;
        }
    }
}
