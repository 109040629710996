/*
 * AUTOCOMPLETE
 */

/* Auto complete */

.ng-autocomplete-dropdown {
    position: relative;

    /* margin-top: 25px; */
    /* height: 200px; */

    .ng-autocomplete-inputs {
        position: relative;
    }

    .ng-autocomplete-placeholder {
        position: absolute;
        margin-top: 3px;
        background-color: #fff;
        font-weight: normal;
        font-size: 15px;
        text-align: left;
        width: calc(100% - 20px);
    }

    .ng-autocomplete-inputs input {
        background: transparent;
        border-color: #dfe4ed;
        border-bottom: 1px solid;
        border-left: none;
        border-radius: 0 !important;
        border-right: none;
        border-top: none;
        font-size: 15px;
        font-weight: normal;
        height: 29px !important;
        outline: none !important;
        width: 100%;
    }

    .ng-dropdown {
        border: 1px solid #e0e0e0;
        display: none;
        max-height: 258px;
        overflow-x: hidden;
        position: absolute;
        width: 100%;
        z-index: 99999;

        &.open {
            display: block;
        }

        .dropdown-item {
            background-color: #ffffff;
            cursor: pointer;
            font-weight: normal;
            font-size: 15px;
            padding: 5px 10px;
            text-align: left;
            width: 100%;

            &:nth-child(odd) {
                background-color: #efefef;
            }

            &.active {
                background-color: #0099cc;
                color: #fff !important;
            }

            .dropdown-item-highlight {
                font-weight: bold;
            }
        }
    }
}

.ng-autocomplete-dropdown-icon {
    bottom: 0;
    color: #758694;
    cursor: pointer;
    display: block;
    font-size: 12px;
    top: 0;
    position: absolute;
    right: 0;
    text-align: center;
    width: 20px;
    z-index: 999;

    &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid #000;
        position: absolute;
        right: 0;
        z-index: 999;
        top: 13px;
    }

    &.open:after {
        transform: rotate(180deg);
    }
}

.typeahead__container {
    position: relative;

    .typeahead__field {
        position: relative;
        z-index: 1;
    }

    .typeahead__result {
        display: none;
        position: absolute;
        z-index: 2;
        top: 100%;
        width: 100%;
        margin-top: -2px;

        .typeahead__list {
            background: #ffffff;
            margin: 0;
            list-style: none;
            padding: 0;
            border: 1px solid #5ac4f1;
            -webkit-border-radius: 0 0 3px 3px;
            border-radius: 0 0 3px 3px;
            border-top: 1px solid #dfe4ed;

            .typeahead__item {
                a {
                    display: block;
                    padding: 6px 16px;
                    color: #514d6a;
                }

                &.active a {
                    background: #f2f4f8;
                }

                &:hover a {
                    background: #dfe4ed;
                }

                strong {
                    font-weight: 600;
                }
            }
        }
    }

    &.result {
        .typeahead__result {
            display: block;
        }

        input.form-control {
            border-right-color: transparent;
        }
    }
}
