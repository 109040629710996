@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "@ng-select/ng-select/themes/material.theme.css";
@import "animate.css/animate.min.css";
@import "assets/css/components/badges-labels";
@import "assets/css/components/badges-labels";
@import "assets/css/components/breadcrumbs";
@import "assets/css/components/carousel";
@import "assets/css/components/collapse";
@import "assets/css/components/loader";
@import "assets/css/components/modal";
@import "assets/css/components/notifications-alerts";
@import "assets/css/components/pagination";
@import "assets/css/components/progress-bars";
@import "assets/css/components/steps";
@import "assets/css/components/tables";
@import "assets/css/components/tabs";
@import "assets/css/components/mat-icon";
@import "assets/css/components/mat-form-field";
@import "assets/css/components/mat-menu";
@import "assets/css/components/partners-button";
@import "assets/css/components/toastr";
@import "assets/css/components/tooltips-popovers";
@import "assets/css/components/utilities";
@import "assets/css/components/widgets";
@import "assets/css/components/partners-merchant-payouts";
@import "assets/css/forms/autocomplete";
@import "assets/css/forms/basic-form-elements";
@import "assets/css/forms/buttons";
@import "assets/css/forms/checkboxes-radio";
@import "assets/css/forms/dropdowns";
@import "assets/css/forms/form-validation";
@import "assets/css/forms/selectboxes";
@import "assets/css/helpers/base.default";
@import "assets/css/helpers/base.responsive";
@import "assets/css/helpers/fonts";
@import "assets/css/helpers/typography";
@import "assets/css/jsoneditor";

ng-select {
    border-bottom: none;
}

.margin-ng-select-horizontal {
    margin-top: 26px;
}

.jsoneditor {
    border: thin solid #384042 !important;
}

.jsoneditor-menu {
    background-color: #384042 !important;
    border-bottom: 1px solid #384042 !important;

    & > li {
        background-color: #A8B1B8 !important;
        color: #1e1e1e;
    }
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cdk-overlay-container {
    z-index: 2000;
}


.mat-button.btn.btn-sonect {
    background: #96006B;
    border-color: #96006B;
    color: #fff;
}
