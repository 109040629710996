/*
 * BASIC TABLES
 */

.table {
    thead th {
        background: #384042 !important;
        border: 1px solid #384042 !important;
        border-top: none !important;
        color: white;
        font-weight: 600;
        outline: none !important;
        padding-bottom: 5px;
        vertical-align: bottom;

        &:focus {
            background: #f2f4f8;
        }
    }

    td {
        border-color: #eceeef;
        padding: 0.3rem;
        vertical-align: middle;

        &:focus {
            background-color: #eceeef;
        }
    }

    tbody tr:first-child > td {
        border-top: none;
    }

    &.table-hover tbody tr:hover {
        background: #eceeef;
    }

    tr {
        &.active {
            background: #f2f4f8;
        }

        &.mat-footer-row {
            height: 42px !important;
        }
    }
}

tr.mat-row {
    height: 42px !important;
}

.table {
    &.table-striped tbody tr:nth-of-type(odd) {
        background: #f2f4f8;
    }

    .row-action {
        color: #292b2c;
        font-weight: bold;
        margin-left: 5px;
        outline: none;
        text-decoration: none;

        &:hover {
            color: #5ac4f1;
        }
    }
}

/* Row actions */

/* Inner tables */

.inner-table {
    font-size: 10px;
    width: 100%;

    th, td {
        padding: 2px !important;
    }
}
