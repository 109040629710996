/*
 * PROGRESS BARS
 */

.progress {
    height: 18px;

    &:last-child {
        margin-bottom: 0;
    }
}

.progress-animation {
    -webkit-transition: all 0.1s !important;
    -o-transition: all 0.1s !important;
    transition: all 0.1s !important;
}

.progress-primary[value]::-webkit-progress-value, .progress[value]::-webkit-progress-value, .progress-primary[value]::-moz-progress-bar, .progress[value]::-moz-progress-bar {
    background-color: #5ac4f1;
}

.progress-default[value] {
    &::-webkit-progress-value, &::-moz-progress-bar {
        background-color: #e3dfcb;
    }
}

.progress-secondary[value] {
    &::-webkit-progress-value, &::-moz-progress-bar {
        background-color: #6a7a84;
    }
}

.progress-success[value] {
    &::-webkit-progress-value, &::-moz-progress-bar {
        background-color: #46be8a;
    }
}

.progress-danger[value] {
    &::-webkit-progress-value, &::-moz-progress-bar {
        background-color: #fb434a;
    }
}

.progress-warning[value] {
    &::-webkit-progress-value, &::-moz-progress-bar {
        background-color: #ffa300;
    }
}

.progress-info[value] {
    &::-webkit-progress-value, &::-moz-progress-bar {
        background-color: #0190da;
    }
}
