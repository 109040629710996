/*
 * DROPDOWNS
 */

.dropdown {
    display: inline-block;
}

.dropup {
    display: inline-block;

    .dropdown-menu {
        bottom: 115%;
    }
}

.dropdown-menu {
    border-color: #d2d9e5;
    top: 100%;
    padding: 8px 0;

    .dropdown-icon {
        margin-right: 5px;
    }

    .dropdown-divider {
        background-color: #d2d9e5;
    }

    .dropdown-header {
        padding: 7px 15px;
        color: #514d6a;
        font-size: 14px;
        font-weight: 600;
    }

    .dropdown-item {
        color: #827ca1;
        background: none;
        min-width: 185px;
        padding: 3px 15px;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;

        a {
            color: #827ca1;

            &:hover, &:active {
                background: #acb7bf !important;
                color: white !important;
                font-weight: bold !important;
            }
        }

        &.disabled {
            cursor: not-allowed;
            color: #d2d9e5 !important;

            a {
                color: #d2d9e5 !important;
                cursor: not-allowed;
            }
        }

        &.active {
            background: #f2f4f8 !important;
        }

        &:hover, &:focus {
            -webkit-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            background: #acb7bf !important;
            color: white !important;
            font-weight: bold !important;
        }

        &:active {
            -webkit-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            background: none;
            font-weight: bold !important;
        }
    }
}

.dropdown-animate {
    overflow: hidden;

    > .dropdown-item {
        -webkit-animation-name: dropdown-slide-left;
        -o-animation-name: dropdown-slide-left;
        animation-name: dropdown-slide-left;
        -webkit-animation-duration: .5s;
        -o-animation-duration: .5s;
        animation-duration: .5s;
        -webkit-animation-fill-mode: both;
        -o-animation-fill-mode: both;
        animation-fill-mode: both;
    }

    .dropdown-header {
        -webkit-animation-name: dropdown-slide-left;
        -o-animation-name: dropdown-slide-left;
        animation-name: dropdown-slide-left;
        -webkit-animation-duration: .5s;
        -o-animation-duration: .5s;
        animation-duration: .5s;
        -webkit-animation-fill-mode: both;
        -o-animation-fill-mode: both;
        animation-fill-mode: both;

        &:nth-child(1) {
            -webkit-animation-delay: .02s;
            -o-animation-delay: .02s;
            animation-delay: .02s;
        }

        &:nth-child(2) {
            -webkit-animation-delay: .04s;
            -o-animation-delay: .04s;
            animation-delay: .04s;
        }

        &:nth-child(3) {
            -webkit-animation-delay: .06s;
            -o-animation-delay: .06s;
            animation-delay: .06s;
        }

        &:nth-child(4) {
            -webkit-animation-delay: .08s;
            -o-animation-delay: .08s;
            animation-delay: .08s;
        }

        &:nth-child(5) {
            -webkit-animation-delay: .10s;
            -o-animation-delay: .10s;
            animation-delay: .10s;
        }

        &:nth-child(6) {
            -webkit-animation-delay: .12s;
            -o-animation-delay: .12s;
            animation-delay: .12s;
        }

        &:nth-child(7) {
            -webkit-animation-delay: .14s;
            -o-animation-delay: .14s;
            animation-delay: .14s;
        }

        &:nth-child(8) {
            -webkit-animation-delay: .16s;
            -o-animation-delay: .16s;
            animation-delay: .16s;
        }

        &:nth-child(9) {
            -webkit-animation-delay: .18s;
            -o-animation-delay: .18s;
            animation-delay: .18s;
        }

        &:nth-child(10) {
            -webkit-animation-delay: .20s;
            -o-animation-delay: .20s;
            animation-delay: .20s;
        }
    }

    > .dropdown-item:nth-child(1) {
        -webkit-animation-delay: .02s;
        -o-animation-delay: .02s;
        animation-delay: .02s;
    }

    > .dropdown-item:nth-child(2) {
        -webkit-animation-delay: .04s;
        -o-animation-delay: .04s;
        animation-delay: .04s;
    }

    > .dropdown-item:nth-child(3) {
        -webkit-animation-delay: .06s;
        -o-animation-delay: .06s;
        animation-delay: .06s;
    }

    > .dropdown-item:nth-child(4) {
        -webkit-animation-delay: .08s;
        -o-animation-delay: .08s;
        animation-delay: .08s;
    }

    > .dropdown-item:nth-child(5) {
        -webkit-animation-delay: .10s;
        -o-animation-delay: .10s;
        animation-delay: .10s;
    }

    > .dropdown-item:nth-child(6) {
        -webkit-animation-delay: .12s;
        -o-animation-delay: .12s;
        animation-delay: .12s;
    }

    > .dropdown-item:nth-child(7) {
        -webkit-animation-delay: .14s;
        -o-animation-delay: .14s;
        animation-delay: .14s;
    }

    > .dropdown-item:nth-child(8) {
        -webkit-animation-delay: .16s;
        -o-animation-delay: .16s;
        animation-delay: .16s;
    }

    > .dropdown-item:nth-child(9) {
        -webkit-animation-delay: .18s;
        -o-animation-delay: .18s;
        animation-delay: .18s;
    }

    > .dropdown-item:nth-child(10) {
        -webkit-animation-delay: .20s;
        -o-animation-delay: .20s;
        animation-delay: .20s;
    }
}

.open {
    > .dropdown-menu {
        animation-name: dropdown-slide-bottom;
        animation-duration: .15s;
        animation-fill-mode: both;
        animation-delay: .05s;
        display: block;
    }

    &.dropup > .dropdown-menu {
        -webkit-animation-name: dropdown-slide-top;
        -o-animation-name: dropdown-slide-top;
        animation-name: dropdown-slide-top;
    }
}

.dropdown {
    &.dropdown-avatar .dropdown-toggle {
        display: inline-block;
        position: relative;
        padding-right: 18px;

        &:after {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -1px;
            color: #bbb8cb;
            -webkit-transition: all 0.1s ease-in-out;
            -o-transition: all 0.1s ease-in-out;
            transition: all 0.1s ease-in-out;
        }

        &:hover:after {
            color: #827ca1;
        }
    }

    .dropdown-inline-button {
        display: inline-block;
        color: #827ca1;
        font-weight: 600;

        .dropdown-inline-button-icon {
            color: #bbb8cb;
            -webkit-transition: all 0.1s ease-in-out;
            -o-transition: all 0.1s ease-in-out;
            transition: all 0.1s ease-in-out;
            margin-right: 5px;
        }

        &:after {
            -webkit-transition: all 0.1s ease-in-out;
            -o-transition: all 0.1s ease-in-out;
            transition: all 0.1s ease-in-out;
            color: #bbb8cb;
        }

        &:hover, &:focus, &:active {
            color: #24222f;
        }

        &:hover .dropdown-inline-button-icon, &:focus .dropdown-inline-button-icon, &:active .dropdown-inline-button-icon, &:hover:after, &:focus:after, &:active:after {
            color: #827ca1;
        }
    }

    &.open .dropdown-inline-button {
        color: #24222f;

        .dropdown-inline-button-icon {
            color: #514d6a;
        }
    }
}

@-webkit-keyframes dropdown-slide-left {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-o-keyframes dropdown-slide-left {
    0% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes dropdown-slide-left {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes dropdown-slide-bottom {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-o-keyframes dropdown-slide-bottom {
    0% {
        opacity: 0;
        transform: translate3d(0, -5px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes dropdown-slide-bottom {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes dropdown-slide-top {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-o-keyframes dropdown-slide-top {
    0% {
        opacity: 0;
        transform: translate3d(0, 5px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes dropdown-slide-top {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }

    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
