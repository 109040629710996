/* GLOBAL RESPONSIVE STYLES */
@media (max-width: 991px) {
    .sidebar-large.panel {
        margin-bottom: 15px;

        &.panel-with-sidebar {
            &:before {
                display: none;
            }

            .panel-heading, .panel-body, .panel-footer {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }

            .panel-sidebar {
                float: none !important;
                width: auto;
                border-bottom: 1px solid #dfe4ed;
                margin-bottom: 0 !important;
            }
        }
    }
}

@media (min-width: 768px) {
    body {
        &.layout-boxed {
            padding-left: 240px;

            &.menu-fixed .left-menu {
                left: 0;
                right: 0;
            }

            &.single-page {
                padding-left: 0;
            }

            .top-menu {
                max-width: 1248px;
                margin-left: auto !important;
                margin-right: auto !important;
                -webkit-border-radius: 0 0 5px 5px;
                border-radius: 0 0 5px 5px;
                border-left: 1px solid #dfe4ed;
                border-right: 1px solid #dfe4ed;

                &:before {
                    display: none;
                }
            }

            .page-content {
                max-width: 1280px;
                margin-left: auto;
                margin-right: auto;
            }

            &.menu-top {
                padding-left: 0;
                height: 100%;

                .left-menu {
                    max-width: 1248px;
                    border-left: 1px solid #dfe4ed;
                    border-right: 1px solid #dfe4ed;
                    margin: 0 auto !important;

                    &:before {
                        width: 2000px;
                        height: 80px;
                        background: #eceeef;
                        content: "";
                        position: absolute;
                        z-index: 2;
                        left: -2001px;
                        top: 0;
                    }

                    &:after {
                        width: 2000px;
                        height: 80px;
                        background: #eceeef;
                        content: "";
                        position: absolute;
                        z-index: 2;
                        top: 0;
                        left: auto;
                        right: -2001px;
                    }
                }

                &.mode-superclean {
                    .left-menu {
                        &:before, &:after {
                            background: #ffffff;
                        }

                        max-width: 1280px;
                        border-right: none;
                        border-left: none;
                        -webkit-border-radius: 0;
                        border-radius: 0;
                    }

                    .top-menu {
                        max-width: 1280px;
                        border-right: none;
                        border-left: none;
                        -webkit-border-radius: 0;
                        border-radius: 0;
                    }
                }

                nav.top-submenu.top-submenu-with-background, .top-submenu.top-submenu-with-background {
                    margin: 0 0 16px 0;
                    border: 1px solid #dfe4ed;
                    -webkit-border-radius: 5px;
                    border-radius: 5px;
                }

                nav.top-submenu.top-submenu-with-background .profile-header, .top-submenu.top-submenu-with-background .profile-header {
                    -webkit-border-radius: 5px;
                    border-radius: 5px;
                }

                .boxed-container {
                    background: #ffffff;
                    max-width: 1300px;
                    margin: 0 auto;
                    padding-bottom: 10px;
                    -webkit-box-shadow: 0 15px 35px -15px rgba(36, 34, 47, 0.25);
                    box-shadow: 0 15px 35px -15px rgba(36, 34, 47, 0.25);
                    min-height: 100%;

                    .left-menu {
                        border-left: none !important;
                        border-right: none !important;

                        &:before {
                            border-right: 25px solid #fff;
                        }

                        &:after {
                            border-left: 25px solid #fff;
                        }

                        .logo-container {
                            padding-left: 20px;
                        }
                    }

                    .top-menu {
                        border: none !important;
                        -webkit-border-radius: 0 !important;
                        border-radius: 0 !important;

                        .menu {
                            padding-left: 10px;
                        }
                    }
                }

                &.single-page .boxed-container {
                    max-width: none;
                    height: 100%;
                    padding-bottom: 0;
                }

                &.mode-superclean .boxed-container {
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }

                &.menu-fixed .boxed-container .top-menu {
                    &:before {
                        position: absolute;
                        top: 0;
                        left: -2000px;
                        width: 2000px;
                        height: 64px;
                        content: "";
                        display: block;
                        border-right: 26px solid #fff;
                        background: #eceeef;
                    }

                    &:after {
                        position: absolute;
                        top: 0;
                        right: -2000px;
                        width: 2000px;
                        height: 64px;
                        content: "";
                        display: block;
                        border-left: 26px solid #fff;
                        background: #eceeef;
                    }
                }
            }
        }

        &.menu-iconbar:not(.menu-top) .left-menu {
            width: 160px;
            text-align: center;

            .jspPane, .jspScrollable .jspTrack {
                left: 0 !important;
            }

            .left-menu-block-item .btn-group-justified > .btn-group {
                display: block;
                width: 100%;

                &:first-child .btn {
                    -webkit-border-radius: 5px 5px 0 0;
                    border-radius: 5px 5px 0 0;
                    margin-bottom: 0;
                }

                &:last-child {
                    margin: 0;

                    .btn {
                        -webkit-border-radius: 0 0 5px 5px;
                        border-radius: 0 0 5px 5px;
                    }
                }
            }

            .logo-container {
                width: 160px;

                .logo {
                    max-width: none;

                    img {
                        margin: 0 auto;
                    }
                }
            }

            .left-menu-list {
                .left-menu-label {
                    position: relative;
                    margin: 5px auto 0;
                    float: none;
                }

                .left-menu-link {
                    text-align: center;
                    display: block;
                    padding: 15px 10px 15px;
                    font-size: 14px;
                    font-weight: 300;
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        height: 1px;
                        background: #dfe4ed;
                        position: absolute;
                        bottom: 0;
                        width: 140px;
                        left: 10px;
                    }

                    &:after {
                        top: 50%;
                        right: 10px;
                        margin-top: -4px;
                    }

                    .left-menu-link-icon {
                        display: block;
                        margin: 5px auto 10px;
                        font-size: 21px;
                    }
                }

                .left-menu-item {
                    padding-left: 15px;
                    padding-right: 10px;
                }

                .left-menu-list-separator {
                    margin: 10px;
                    display: none;
                }
            }

            + {
                .top-menu {
                    left: 160px;
                }

                * + .page-content {
                    margin-left: 160px;
                }
            }
        }

        &.menu-top {
            .left-menu {
                -webkit-transition: none !important;
                -o-transition: none !important;
                transition: none !important;
                width: 100%;
                height: 80px;
                top: 0;
                z-index: 120;
                border-bottom: 1px solid #dfe4ed;
                border-right: 0;

                * {
                    -webkit-transition: none !important;
                    -o-transition: none !important;
                    transition: none !important;
                }

                .menu-top-hidden {
                    display: none !important;
                }

                .left-menu-list-root {
                    margin: 0;
                    height: 80px;
                    width: 3000px;

                    &.inited {
                        overflow: visible;
                    }

                    .left-menu-label {
                        margin-top: 0;
                    }

                    .left-menu-block .left-menu-block-item:last-child {
                        margin-bottom: 0;
                    }

                    > {
                        li {
                            position: relative;
                            float: left;

                            &:before {
                                height: 4px !important;
                                top: 0;
                                width: 100%;
                            }
                        }

                        .left-menu-list-submenu {
                            .left-menu-list {
                                display: none !important;
                                position: absolute;
                                top: 80px;
                                left: 0;
                                min-width: 240px;
                                padding-top: 10px;
                                padding-bottom: 10px;
                            }

                            &:hover > .left-menu-link {
                                border-bottom: 0 !important;
                            }

                            .left-menu-list-submenu {
                                position: relative;

                                .left-menu-list {
                                    position: absolute !important;
                                    top: -10px;
                                    left: 100%;
                                }

                                .left-menu-link:after {
                                    top: 20px !important;
                                    right: 10px;
                                    left: auto !important;
                                    border-left-color: #d2d9e5 !important;
                                    border-top-color: transparent !important;
                                }
                            }

                            &.horizontal-left-direction .left-menu-list-submenu {
                                .left-menu-list {
                                    top: -10px;
                                    left: auto;
                                    right: 100%;
                                }

                                .left-menu-link:after {
                                    border-left-color: transparent !important;
                                    border-right-color: #d2d9e5 !important;
                                    border-top-color: transparent !important;
                                }
                            }
                        }

                        li:first-child > .left-menu-link {
                            border-left: 1px solid #dfe4ed;
                        }

                        .left-menu-link {
                            position: relative;
                            height: 80px;
                            line-height: 1;
                            text-align: center;
                            padding-top: 42px;
                            border-right: 1px solid #dfe4ed;
                            border-bottom: 1px solid #dfe4ed;

                            .left-menu-link-icon {
                                position: absolute;
                                top: 20px;
                                left: 50%;
                                margin-left: -10px;
                            }
                        }

                        a:hover {
                            background: #f2f4f8;
                        }
                    }

                    .left-menu-list-separator {
                        display: none !important;
                    }
                }

                .logo-container {
                    position: relative;
                    z-index: 1;
                    width: auto;
                    background: #ffffff;

                    .logo {
                        margin: 0 auto;
                    }
                }

                .left-menu-inner {
                    position: static;
                    overflow: visible;
                    -webkit-transition: -webkit-transform 1s !important;
                    -o-transition: -o-transform 1s !important;
                    transition: transform 1s, -webkit-transform 1s, -o-transform 1s !important;

                    .left-menu-list .left-menu-list-submenu {
                        .left-menu-link:after {
                            left: 50%;
                            margin-left: -4px;
                            top: auto;
                            bottom: 5px;
                            border-left-color: transparent;
                            border-top-color: #d2d9e5;
                            border-width: 5px 5px 5px 5px;
                        }

                        &:hover > .left-menu-list {
                            display: block !important;
                        }
                    }
                }
            }

            .top-menu {
                left: 0;
                top: 80px;
                height: 64px;

                .menu {
                    height: 64px;
                    padding: 13px 16px;
                }
            }

            .page-content {
                margin: 144px 0 0;
            }

            &.menu-static {
                .left-menu {
                    position: relative;

                    + .top-menu {
                        margin-left: 0;
                    }
                }

                .top-menu {
                    position: relative;
                    top: 0 !important;
                    margin-bottom: 0 !important;
                }
            }

            &.menu-top-compact {
                .left-menu {
                    height: 64px;

                    .logo-container {
                        height: 64px;
                        padding-top: 14px;
                    }

                    .left-menu-link-icon {
                        display: none;
                    }

                    .left-menu-list-root {
                        .left-menu-link {
                            height: 30px;
                            padding: 8px 10px 5px;
                            border: none !important;
                        }

                        > .left-menu-list-submenu {
                            > .left-menu-link {
                                padding-right: 23px;

                                &:after {
                                    left: auto;
                                    right: 8px;
                                    top: 14px;
                                    margin: 0;
                                }
                            }

                            .left-menu-list {
                                top: 30px;
                            }
                        }
                    }
                }

                &.menu-fixed {
                    .top-menu {
                        top: 64px;
                    }

                    .page-content {
                        margin-top: 128px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    nav {
        &.top-menu {
            left: 0 !important;

            .menu {
                padding: 21px 15px;
            }

            .menu-icon-container + .menu {
                margin-left: 65px;
            }
        }

        &.top-submenu {
            padding: 10px 10px 0 10px;
        }
    }

    .top-submenu {
        padding: 10px 10px 0 10px;
    }

    nav.top-submenu.top-submenu-with-background, .top-submenu.top-submenu-with-background {
        margin: -15px -15px 15px -15px;
    }

    nav.left-menu {
        -webkit-transform: translateX(-300px);
        -ms-transform: translateX(-300px);
        -o-transform: translateX(-300px);
        transform: translateX(-300px);
        transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out, -o-transform 0.2s ease-in-out;

        + .main-container {
            margin-left: 0;
        }
    }

    .menu-static nav.top-menu {
        margin-left: 0 !important;
        z-index: 40;
        position: relative;
    }

    .page-content {
        padding: 15px;
        margin-left: 0 !important;
    }

    .panel {
        margin-bottom: 15px;

        &.panel-with-sidebar {
            &:before {
                display: none;
            }

            .panel-heading, .panel-body, .panel-footer {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }

            .panel-sidebar {
                float: none !important;
                border-right: none !important;
                border-left: none !important;
                width: auto;
                border-bottom: 1px solid #dfe4ed;
                margin-bottom: 0 !important;
            }
        }
    }

    body.menu-sliding {
        nav.top-menu, .page-content {
            transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out, -o-transform 0.2s ease-in-out;
        }

        nav.left-menu.left-menu-showed + {
            * + .page-content, nav.top-menu {
                -webkit-transform: translateX(300px);
                -ms-transform: translateX(300px);
                -o-transform: translateX(300px);
                transform: translateX(300px);
            }

            * + .page-content + .main-backdrop {
                display: none !important;
            }
        }
    }
}

@media (max-width: 543px) {
    .top-menu .menu {
        margin-left: 0;
        padding-left: 0;

        .menu-info-block .left .header-buttons .dropdown-inline-button {
            padding-right: 0;
        }
    }

    .top-submenu.top-submenu-with-background {
        margin: -15px -.9375rem .9375rem -.9375rem;
    }

    .page-content {
        padding: .9375rem;
    }

    .panel {
        margin-bottom: .9375rem;

        .panel {
            margin-bottom: 20px;
        }

        .panel-sidebar {
            padding: 20px !important;
        }

        .panel-heading, .panel-body, .panel-footer {
            padding: 20px;
        }

        &.panel-with-borders > .panel-body {
            padding-top: 20px;
        }
    }

    .mode-superclean .top-submenu {
        padding-left: 20px;
        padding-right: 20px;

        &.top-submenu-with-background {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

/* NEW PADDINGS & MARGINS */

body {
    .page-content {
        padding: 16px;
    }

    .widget, .panel {
        margin-bottom: 16px;
    }
}

.widget-four .step-block {
    margin-bottom: 16px;
}

.row {
    margin-right: -8px;
    margin-left: -8px;
}

/* Row with equal height columns */

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    > [class*='col-'] {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: grid;

        /*flex*/
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-left: 8px;
    padding-right: 8px;
}

.panel {
    .row {
        margin-right: -16px;
        margin-left: -16px;
    }

    .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
        padding-left: 16px;
        padding-right: 16px;
    }
}
