/*
 * COMPONENTS STEPS
 */

.step-block {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #827ca1;
    display: block;
    margin-bottom: 5px;
    padding: 5px;

    .step-digit {
        float: left;
        margin: -5px 20px 0 0;
        font-size: 42px;
        width: 56px;
        line-height: 55px;
        text-align: center;

        i {
            font-size: 36px;
        }
    }

    .step-desc {
        .step-title {
            font-weight: 600;
            font-size: 16px;
        }

        p {
            margin-bottom: 0;
        }
    }

    &.step-primary {
        background: #5ac4f1 !important;
        border-bottom-color: #5ac4f1;
    }

    &.step-secondary {
        background: #6a7a84 !important;
        border-bottom-color: #6a7a84;
        color: #e6e4f0;
    }

    &.step-success {
        background: #46be8a !important;
        border-bottom-color: #46be8a;
        color: #ffffff;
    }

    &.step-info {
        background: #0190da !important;
        border-bottom-color: #0190da;
    }

    &.step-warning {
        background: #ffa300 !important;
        border-bottom-color: #ffa300;
        color: #ffffff;
    }

    &.step-danger {
        background: #fb434a !important;
        border-bottom-color: #fb434a;
        color: #ffffff;
    }

    &.step-disabled {
        cursor: not-allowed;
        opacity: .65;
    }

    &.step-squared {
        -webkit-border-radius: 0;
        border-radius: 0;
    }
}

@media (max-width: 991px) {
    .step-block {
        margin-bottom: 16px !important;
        margin-top: 0 !important;
    }
}

.panel-heading .step-block {
    padding: 0 0 10px 0;
    margin-bottom: -27px;
    margin-top: -10px;
    color: #827ca1 !important;
    background-color: #ffffff !important;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    -webkit-border-radius: 0;
    border-radius: 0;

    .step-digit {
        font-size: 28px;
        line-height: 38px;
        height: 24px;
        display: block;
        width: auto;
        min-width: 40px;
        margin: 0 10px;

        i {
            font-size: 24px;
        }
    }

    .step-desc {
        padding-top: 0;

        .step-title {
            font-weight: 600;
            font-size: 14px;
        }

        p {
            font-size: 12px;
            margin-bottom: 5px;
            color: #bbb8cb;
        }
    }
}

@media (max-width: 991px) {
    .panel-heading [class^="col-"]:last-child .step-block {
        margin-bottom: 0 !important;
    }
}

.steps-inline {
    display: inline-block;

    &:before, &:after {
        content: " ";
        display: table;
    }

    .step-block {
        float: left;
        height: 22px;
        border-right: 1px solid #e3dfcb;
        border-radius: 0;
        -webkit-border-radius: 0;
        background: #eceeef;
        line-height: 22px;
        color: #acb7bf;
        text-align: center;
        padding: 0 5px;

        &:first-child {
            -webkit-border-radius: 3px 0 0 3px;
            border-radius: 3px 0 0 3px;
            font-weight: bold;
            padding-left: 5px;
        }

        &:last-child {
            border-right: none;
            -webkit-border-radius: 0 3px 3px 0;
            border-radius: 0 3px 3px 0;
            padding-right: 5px;
        }

        &.active {
            background: #5ac4f1;
            color: #ffffff;
        }
    }
}
