.partner-details {
    .payouts-layout {
        bo-panel {
            .panel-heading {
                display: none;
            }

            .panel-body {
                padding: 0 16px;
            }

            .payouts-list {
                &-filter {
                    margin-bottom: 20px;
                }
            }
        }
    }
}
