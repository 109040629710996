.partner-load-menu {
    .mat-mdc-menu-item {
        color: #8678A2;

        span {
            font-family: "Lato", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }

        .mat-icon {
            color: #8678A2;
            margin-left: 5px;
            margin-right: 0;
            order: 1
        }

        input[type=file] {
            cursor: pointer;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
        }
    }
}
