/*
 * TOOLTIPS & POPOVERS
 */

.mat-tooltip {
    background-color: #384042 !important;
    font-size: 11px !important;
    min-width: 100px !important;
    text-align: center !important;
}

.popover {
    border-color: #d2d9e5;

    .popover-arrow {
        border-width: 8px;

        &:after {
            border-width: 7px;
        }
    }

    &.left .tooltip-arrow, &.bs-tether-element-attached-left .popover-arrow {
        border-right-color: #d2d9e5;
        left: -8px;
    }

    &.left .tooltip-arrow:after, &.bs-tether-element-attached-left .popover-arrow:after {
        bottom: -7px;
    }

    &.right .tooltip-arrow, &.bs-tether-element-attached-right .popover-arrow {
        border-left-color: #d2d9e5;
        right: -8px;
    }

    &.right .tooltip-arrow:after, &.bs-tether-element-attached-right .popover-arrow:after {
        bottom: -7px;
    }

    &.top .tooltip-arrow, &.bs-tether-element-attached-top .popover-arrow {
        border-bottom-color: #d2d9e5;
        top: -8px;
    }

    &.top .tooltip-arrow:after, &.bs-tether-element-attached-top .popover-arrow:after {
        margin-left: -7px;
    }

    &.bottom .tooltip-arrow, &.bs-tether-element-attached-bottom .popover-arrow {
        border-top-color: #d2d9e5;
        bottom: -8px;
    }

    &.bottom .tooltip-arrow:after, &.bs-tether-element-attached-bottom .popover-arrow:after {
        margin-left: -7px;
    }

    .popover-title {
        font-weight: 600;
        background: #eceeef;
        margin: -1px;
        padding: 8px 15px;
        -webkit-border-radius: 3px 3px 0 0;
        border-radius: 3px 3px 0 0;
    }
}

.tooltip {
    .tooltip-inner {
        background: #514d6a;
        padding: 4px 15px;
        color: #ffffff;
        font-size: 14px;
    }

    &.left .tooltip-arrow, &.bs-tether-element-attached-left .tooltip-arrow {
        border-right-color: #514d6a;
    }

    &.right .tooltip-arrow, &.bs-tether-element-attached-right .tooltip-arrow {
        border-left-color: #514d6a;
    }

    &.top .tooltip-arrow, &.bs-tether-element-attached-top .tooltip-arrow {
        border-bottom-color: #514d6a;
    }

    &.bottom .tooltip-arrow, &.bs-tether-element-attached-bottom .tooltip-arrow {
        border-top-color: #514d6a;
    }
}
