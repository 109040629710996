* {
    font-family: "Lato", "Open sans", sans-serif;
    outline: none !important;
}

/* FONT FAMILY */

.font-monospace {
    font-family: monospace !important;
}

/* FONT SIZE */

.font-size-0 {
    font-size: 0 !important;
}

.font-size-10 {
    font-size: 10px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-26 {
    font-size: 26px !important;
}

.font-size-30 {
    font-size: 30px !important;
}

.font-size-32 {
    font-size: 32px !important;
}

.font-size-36 {
    font-size: 36px !important;
}

.font-size-40 {
    font-size: 40px !important;
}

.font-size-50 {
    font-size: 50px !important;
}

.font-size-60 {
    font-size: 60px !important;
}

.font-size-70 {
    font-size: 70px !important;
}

.font-size-80 {
    font-size: 80px !important;
}

.font-size-smaller {
    font-size: smaller !important;
}

/* FONT STYLE */

.font-style-italic {
    font-style: italic !important;
}

.font-style-normal {
    font-style: normal !important;
}

/* FONT WEIGHT */

.font-weight-bold {
    font-weight: bold !important;
}

.font-weight-unset {
    font-weight: unset;
}

.font-weight-100 {
    font-weight: 100;
}

.font-weight-200 {
    font-weight: 200;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

/* Font Awesome */

.fa, .fab, .fad, .fal, .far, .fas {
    font-size: inherit;
}

/* ICOMOON PREMIUM ICON FONT */
@font-face {
    font-family: 'icomoon-ultimate';
    src: url("../../font/icomoon-ultimate.eot?i14t76");
    src: url("../../font/icomoon-ultimate.eot?i14t76#iefix") format("embedded-opentype"), url("../../font/icomoon-ultimate.ttf?i14t76") format("truetype"), url("../../font/icomoon-ultimate.woff?i14t76") format("woff"), url("../../font/icomoon-ultimate.svg?i14t76#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icmn-"], [class*=" icmn-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icmn-home:before {
    content: "\e900";
}

.icmn-home2:before {
    content: "\e901";
}

.icmn-home3:before {
    content: "\e902";
}

.icmn-home4:before {
    content: "\e903";
}

.icmn-home5:before {
    content: "\e904";
}

.icmn-home6:before {
    content: "\e905";
}

.icmn-home7:before {
    content: "\e906";
}

.icmn-home8:before {
    content: "\e907";
}

.icmn-home9:before {
    content: "\e908";
}

.icmn-office:before {
    content: "\e909";
}

.icmn-city:before {
    content: "\e90a";
}

.icmn-newspaper:before {
    content: "\e90b";
}

.icmn-magazine:before {
    content: "\e90c";
}

.icmn-design:before {
    content: "\e90d";
}

.icmn-pencil:before {
    content: "\e90e";
}

.icmn-pencil2:before {
    content: "\e90f";
}

.icmn-pencil3:before {
    content: "\e910";
}

.icmn-pencil4:before {
    content: "\e911";
}

.icmn-pencil5:before {
    content: "\e912";
}

.icmn-pencil6:before {
    content: "\e913";
}

.icmn-pencil7:before {
    content: "\e914";
}

.icmn-eraser:before {
    content: "\e915";
}

.icmn-eraser2:before {
    content: "\e916";
}

.icmn-eraser3:before {
    content: "\e917";
}

.icmn-quill:before {
    content: "\e918";
}

.icmn-quill2:before {
    content: "\e919";
}

.icmn-quill3:before {
    content: "\e91a";
}

.icmn-quill4:before {
    content: "\e91b";
}

.icmn-pen:before {
    content: "\e91c";
}

.icmn-pen-plus:before {
    content: "\e91d";
}

.icmn-pen-minus:before {
    content: "\e91e";
}

.icmn-pen2:before {
    content: "\e91f";
}

.icmn-pen3:before {
    content: "\e920";
}

.icmn-pen4:before {
    content: "\e921";
}

.icmn-pen5:before {
    content: "\e922";
}

.icmn-marker:before {
    content: "\e923";
}

.icmn-marker2:before {
    content: "\e924";
}

.icmn-blog:before {
    content: "\e925";
}

.icmn-blog2:before {
    content: "\e926";
}

.icmn-pen6:before {
    content: "\e927";
}

.icmn-brush:before {
    content: "\e928";
}

.icmn-spray:before {
    content: "\e929";
}

.icmn-palette:before {
    content: "\e92a";
}

.icmn-palette2:before {
    content: "\e92b";
}

.icmn-color-sampler:before {
    content: "\e92c";
}

.icmn-toggle:before {
    content: "\e92d";
}

.icmn-bucket:before {
    content: "\e92e";
}

.icmn-bucket2:before {
    content: "\e92f";
}

.icmn-gradient:before {
    content: "\e930";
}

.icmn-eyedropper:before {
    content: "\e931";
}

.icmn-eyedropper2:before {
    content: "\e932";
}

.icmn-eyedropper3:before {
    content: "\e933";
}

.icmn-droplet:before {
    content: "\e934";
}

.icmn-droplet2:before {
    content: "\e935";
}

.icmn-droplet-contrast:before {
    content: "\e936";
}

.icmn-color-clear:before {
    content: "\e937";
}

.icmn-paint-format:before {
    content: "\e938";
}

.icmn-stamp:before {
    content: "\e939";
}

.icmn-images:before {
    content: "\e93a";
}

.icmn-image:before {
    content: "\e93b";
}

.icmn-image2:before {
    content: "\e93c";
}

.icmn-image-compare:before {
    content: "\e93d";
}

.icmn-images2:before {
    content: "\e93e";
}

.icmn-image3:before {
    content: "\e93f";
}

.icmn-images3:before {
    content: "\e940";
}

.icmn-image4:before {
    content: "\e941";
}

.icmn-image5:before {
    content: "\e942";
}

.icmn-image6:before {
    content: "\e943";
}

.icmn-camera:before {
    content: "\e944";
}

.icmn-camera2:before {
    content: "\e945";
}

.icmn-camera3:before {
    content: "\e946";
}

.icmn-shutter:before {
    content: "\e947";
}

.icmn-headphones:before {
    content: "\e948";
}

.icmn-headset:before {
    content: "\e949";
}

.icmn-music:before {
    content: "\e94a";
}

.icmn-music2:before {
    content: "\e94b";
}

.icmn-music3:before {
    content: "\e94c";
}

.icmn-music4:before {
    content: "\e94d";
}

.icmn-music5:before {
    content: "\e94e";
}

.icmn-music6:before {
    content: "\e94f";
}

.icmn-album:before {
    content: "\e950";
}

.icmn-cassette:before {
    content: "\e951";
}

.icmn-tape:before {
    content: "\e952";
}

.icmn-piano:before {
    content: "\e953";
}

.icmn-guitar:before {
    content: "\e954";
}

.icmn-trumpet:before {
    content: "\e955";
}

.icmn-speakers:before {
    content: "\e956";
}

.icmn-play:before {
    content: "\e957";
}

.icmn-play2:before {
    content: "\e958";
}

.icmn-clapboard-play:before {
    content: "\e959";
}

.icmn-clapboard:before {
    content: "\e95a";
}

.icmn-media:before {
    content: "\e95b";
}

.icmn-presentation:before {
    content: "\e95c";
}

.icmn-movie:before {
    content: "\e95d";
}

.icmn-film:before {
    content: "\e95e";
}

.icmn-film2:before {
    content: "\e95f";
}

.icmn-film3:before {
    content: "\e960";
}

.icmn-film4:before {
    content: "\e961";
}

.icmn-video-camera:before {
    content: "\e962";
}

.icmn-video-camera2:before {
    content: "\e963";
}

.icmn-video-camera-slash:before {
    content: "\e964";
}

.icmn-video-camera3:before {
    content: "\e965";
}

.icmn-video-camera4:before {
    content: "\e966";
}

.icmn-video-camera-slash2:before {
    content: "\e967";
}

.icmn-video-camera5:before {
    content: "\e968";
}

.icmn-recording:before {
    content: "\e969";
}

.icmn-dice:before {
    content: "\e96a";
}

.icmn-gamepad:before {
    content: "\e96b";
}

.icmn-gamepad2:before {
    content: "\e96c";
}

.icmn-gamepad3:before {
    content: "\e96d";
}

.icmn-pacman:before {
    content: "\e96e";
}

.icmn-spades:before {
    content: "\e96f";
}

.icmn-clubs:before {
    content: "\e970";
}

.icmn-diamonds:before {
    content: "\e971";
}

.icmn-chess-king:before {
    content: "\e972";
}

.icmn-chess-queen:before {
    content: "\e973";
}

.icmn-chess-rook:before {
    content: "\e974";
}

.icmn-chess-bishop:before {
    content: "\e975";
}

.icmn-chess-knight:before {
    content: "\e976";
}

.icmn-chess-pawn:before {
    content: "\e977";
}

.icmn-chess:before {
    content: "\e978";
}

.icmn-bullhorn:before {
    content: "\e979";
}

.icmn-megaphone:before {
    content: "\e97a";
}

.icmn-new:before {
    content: "\e97b";
}

.icmn-connection:before {
    content: "\e97c";
}

.icmn-connection2:before {
    content: "\e97d";
}

.icmn-connection3:before {
    content: "\e97e";
}

.icmn-connection4:before {
    content: "\e97f";
}

.icmn-podcast:before {
    content: "\e980";
}

.icmn-station:before {
    content: "\e981";
}

.icmn-station2:before {
    content: "\e982";
}

.icmn-antenna:before {
    content: "\e983";
}

.icmn-satellite-dish:before {
    content: "\e984";
}

.icmn-satellite-dish2:before {
    content: "\e985";
}

.icmn-feed:before {
    content: "\e986";
}

.icmn-podcast2:before {
    content: "\e987";
}

.icmn-antenna2:before {
    content: "\e988";
}

.icmn-mic:before {
    content: "\e989";
}

.icmn-mic-off:before {
    content: "\e98a";
}

.icmn-mic2:before {
    content: "\e98b";
}

.icmn-mic-off2:before {
    content: "\e98c";
}

.icmn-mic3:before {
    content: "\e98d";
}

.icmn-mic4:before {
    content: "\e98e";
}

.icmn-mic5:before {
    content: "\e98f";
}

.icmn-book:before {
    content: "\e990";
}

.icmn-book2:before {
    content: "\e991";
}

.icmn-book-play:before {
    content: "\e992";
}

.icmn-book3:before {
    content: "\e993";
}

.icmn-bookmark:before {
    content: "\e994";
}

.icmn-books:before {
    content: "\e995";
}

.icmn-archive:before {
    content: "\e996";
}

.icmn-reading:before {
    content: "\e997";
}

.icmn-library:before {
    content: "\e998";
}

.icmn-library2:before {
    content: "\e999";
}

.icmn-graduation:before {
    content: "\e99a";
}

.icmn-file-text:before {
    content: "\e99b";
}

.icmn-profile:before {
    content: "\e99c";
}

.icmn-file-empty:before {
    content: "\e99d";
}

.icmn-file-empty2:before {
    content: "\e99e";
}

.icmn-files-empty:before {
    content: "\e99f";
}

.icmn-files-empty2:before {
    content: "\e9a0";
}

.icmn-file-plus:before {
    content: "\e9a1";
}

.icmn-file-plus2:before {
    content: "\e9a2";
}

.icmn-file-minus:before {
    content: "\e9a3";
}

.icmn-file-minus2:before {
    content: "\e9a4";
}

.icmn-file-download:before {
    content: "\e9a5";
}

.icmn-file-download2:before {
    content: "\e9a6";
}

.icmn-file-upload:before {
    content: "\e9a7";
}

.icmn-file-upload2:before {
    content: "\e9a8";
}

.icmn-file-check:before {
    content: "\e9a9";
}

.icmn-file-check2:before {
    content: "\e9aa";
}

.icmn-file-eye:before {
    content: "\e9ab";
}

.icmn-file-eye2:before {
    content: "\e9ac";
}

.icmn-file-text2:before {
    content: "\e9ad";
}

.icmn-file-text3:before {
    content: "\e9ae";
}

.icmn-file-picture:before {
    content: "\e9af";
}

.icmn-file-picture2:before {
    content: "\e9b0";
}

.icmn-file-music:before {
    content: "\e9b1";
}

.icmn-file-music2:before {
    content: "\e9b2";
}

.icmn-file-play:before {
    content: "\e9b3";
}

.icmn-file-play2:before {
    content: "\e9b4";
}

.icmn-file-video:before {
    content: "\e9b5";
}

.icmn-file-video2:before {
    content: "\e9b6";
}

.icmn-copy:before {
    content: "\e9b7";
}

.icmn-copy2:before {
    content: "\e9b8";
}

.icmn-file-zip:before {
    content: "\e9b9";
}

.icmn-file-zip2:before {
    content: "\e9ba";
}

.icmn-file-xml:before {
    content: "\e9bb";
}

.icmn-file-xml2:before {
    content: "\e9bc";
}

.icmn-file-css:before {
    content: "\e9bd";
}

.icmn-file-css2:before {
    content: "\e9be";
}

.icmn-file-presentation:before {
    content: "\e9bf";
}

.icmn-file-presentation2:before {
    content: "\e9c0";
}

.icmn-file-stats:before {
    content: "\e9c1";
}

.icmn-file-stats2:before {
    content: "\e9c2";
}

.icmn-file-locked:before {
    content: "\e9c3";
}

.icmn-file-locked2:before {
    content: "\e9c4";
}

.icmn-file-spreadsheet:before {
    content: "\e9c5";
}

.icmn-file-spreadsheet2:before {
    content: "\e9c6";
}

.icmn-copy3:before {
    content: "\e9c7";
}

.icmn-copy4:before {
    content: "\e9c8";
}

.icmn-paste:before {
    content: "\e9c9";
}

.icmn-paste2:before {
    content: "\e9ca";
}

.icmn-paste3:before {
    content: "\e9cb";
}

.icmn-paste4:before {
    content: "\e9cc";
}

.icmn-stack:before {
    content: "\e9cd";
}

.icmn-stack2:before {
    content: "\e9ce";
}

.icmn-stack3:before {
    content: "\e9cf";
}

.icmn-folder:before {
    content: "\e9d0";
}

.icmn-folder-search:before {
    content: "\e9d1";
}

.icmn-folder-download:before {
    content: "\e9d2";
}

.icmn-folder-upload:before {
    content: "\e9d3";
}

.icmn-folder-plus:before {
    content: "\e9d4";
}

.icmn-folder-plus2:before {
    content: "\e9d5";
}

.icmn-folder-minus:before {
    content: "\e9d6";
}

.icmn-folder-minus2:before {
    content: "\e9d7";
}

.icmn-folder-check:before {
    content: "\e9d8";
}

.icmn-folder-heart:before {
    content: "\e9d9";
}

.icmn-folder-remove:before {
    content: "\e9da";
}

.icmn-folder2:before {
    content: "\e9db";
}

.icmn-folder-open:before {
    content: "\e9dc";
}

.icmn-folder3:before {
    content: "\e9dd";
}

.icmn-folder4:before {
    content: "\e9de";
}

.icmn-folder-plus3:before {
    content: "\e9df";
}

.icmn-folder-minus3:before {
    content: "\e9e0";
}

.icmn-folder-plus4:before {
    content: "\e9e1";
}

.icmn-folder-minus4:before {
    content: "\e9e2";
}

.icmn-folder-download2:before {
    content: "\e9e3";
}

.icmn-folder-upload2:before {
    content: "\e9e4";
}

.icmn-folder-download3:before {
    content: "\e9e5";
}

.icmn-folder-upload3:before {
    content: "\e9e6";
}

.icmn-folder5:before {
    content: "\e9e7";
}

.icmn-folder-open2:before {
    content: "\e9e8";
}

.icmn-folder6:before {
    content: "\e9e9";
}

.icmn-folder-open3:before {
    content: "\e9ea";
}

.icmn-certificate:before {
    content: "\e9eb";
}

.icmn-cc:before {
    content: "\e9ec";
}

.icmn-price-tag:before {
    content: "\e9ed";
}

.icmn-price-tag2:before {
    content: "\e9ee";
}

.icmn-price-tags:before {
    content: "\e9ef";
}

.icmn-price-tag3:before {
    content: "\e9f0";
}

.icmn-price-tags2:before {
    content: "\e9f1";
}

.icmn-price-tag4:before {
    content: "\e9f2";
}

.icmn-price-tags3:before {
    content: "\e9f3";
}

.icmn-price-tag5:before {
    content: "\e9f4";
}

.icmn-price-tags4:before {
    content: "\e9f5";
}

.icmn-barcode:before {
    content: "\e9f6";
}

.icmn-barcode2:before {
    content: "\e9f7";
}

.icmn-qrcode:before {
    content: "\e9f8";
}

.icmn-ticket:before {
    content: "\e9f9";
}

.icmn-ticket2:before {
    content: "\e9fa";
}

.icmn-theater:before {
    content: "\e9fb";
}

.icmn-store:before {
    content: "\e9fc";
}

.icmn-store2:before {
    content: "\e9fd";
}

.icmn-cart:before {
    content: "\e9fe";
}

.icmn-cart2:before {
    content: "\e9ff";
}

.icmn-cart3:before {
    content: "\ea00";
}

.icmn-cart4:before {
    content: "\ea01";
}

.icmn-cart5:before {
    content: "\ea02";
}

.icmn-cart-add:before {
    content: "\ea03";
}

.icmn-cart-add2:before {
    content: "\ea04";
}

.icmn-cart-remove:before {
    content: "\ea05";
}

.icmn-basket:before {
    content: "\ea06";
}

.icmn-bag:before {
    content: "\ea07";
}

.icmn-bag2:before {
    content: "\ea08";
}

.icmn-percent:before {
    content: "\ea09";
}

.icmn-coins:before {
    content: "\ea0a";
}

.icmn-coin-dollar:before {
    content: "\ea0b";
}

.icmn-coin-euro:before {
    content: "\ea0c";
}

.icmn-coin-pound:before {
    content: "\ea0d";
}

.icmn-coin-yen:before {
    content: "\ea0e";
}

.icmn-piggy-bank:before {
    content: "\ea0f";
}

.icmn-wallet:before {
    content: "\ea10";
}

.icmn-cash:before {
    content: "\ea11";
}

.icmn-cash2:before {
    content: "\ea12";
}

.icmn-cash3:before {
    content: "\ea13";
}

.icmn-cash4:before {
    content: "\ea14";
}

.icmn-credit-card:before {
    content: "\ea15";
}

.icmn-credit-card2:before {
    content: "\ea16";
}

.icmn-calculator:before {
    content: "\ea17";
}

.icmn-calculator2:before {
    content: "\ea18";
}

.icmn-calculator3:before {
    content: "\ea19";
}

.icmn-chip:before {
    content: "\ea1a";
}

.icmn-lifebuoy:before {
    content: "\ea1b";
}

.icmn-phone:before {
    content: "\ea1c";
}

.icmn-phone2:before {
    content: "\ea1d";
}

.icmn-phone-slash:before {
    content: "\ea1e";
}

.icmn-phone-wave:before {
    content: "\ea1f";
}

.icmn-phone-plus:before {
    content: "\ea20";
}

.icmn-phone-minus:before {
    content: "\ea21";
}

.icmn-phone-plus2:before {
    content: "\ea22";
}

.icmn-phone-minus2:before {
    content: "\ea23";
}

.icmn-phone-incoming:before {
    content: "\ea24";
}

.icmn-phone-outgoing:before {
    content: "\ea25";
}

.icmn-phone3:before {
    content: "\ea26";
}

.icmn-phone-slash2:before {
    content: "\ea27";
}

.icmn-phone-wave2:before {
    content: "\ea28";
}

.icmn-phone-hang-up:before {
    content: "\ea29";
}

.icmn-phone-hang-up2:before {
    content: "\ea2a";
}

.icmn-address-book:before {
    content: "\ea2b";
}

.icmn-address-book2:before {
    content: "\ea2c";
}

.icmn-address-book3:before {
    content: "\ea2d";
}

.icmn-notebook:before {
    content: "\ea2e";
}

.icmn-at-sign:before {
    content: "\ea2f";
}

.icmn-envelop:before {
    content: "\ea30";
}

.icmn-envelop2:before {
    content: "\ea31";
}

.icmn-envelop3:before {
    content: "\ea32";
}

.icmn-envelop4:before {
    content: "\ea33";
}

.icmn-envelop5:before {
    content: "\ea34";
}

.icmn-mailbox:before {
    content: "\ea35";
}

.icmn-pushpin:before {
    content: "\ea36";
}

.icmn-pushpin2:before {
    content: "\ea37";
}

.icmn-location:before {
    content: "\ea38";
}

.icmn-location2:before {
    content: "\ea39";
}

.icmn-location3:before {
    content: "\ea3a";
}

.icmn-location4:before {
    content: "\ea3b";
}

.icmn-location5:before {
    content: "\ea3c";
}

.icmn-location6:before {
    content: "\ea3d";
}

.icmn-location7:before {
    content: "\ea3e";
}

.icmn-compass:before {
    content: "\ea3f";
}

.icmn-compass2:before {
    content: "\ea40";
}

.icmn-compass3:before {
    content: "\ea41";
}

.icmn-compass4:before {
    content: "\ea42";
}

.icmn-compass5:before {
    content: "\ea43";
}

.icmn-compass6:before {
    content: "\ea44";
}

.icmn-map:before {
    content: "\ea45";
}

.icmn-map2:before {
    content: "\ea46";
}

.icmn-map3:before {
    content: "\ea47";
}

.icmn-map4:before {
    content: "\ea48";
}

.icmn-map5:before {
    content: "\ea49";
}

.icmn-direction:before {
    content: "\ea4a";
}

.icmn-reset:before {
    content: "\ea4b";
}

.icmn-history:before {
    content: "\ea4c";
}

.icmn-clock:before {
    content: "\ea4d";
}

.icmn-clock2:before {
    content: "\ea4e";
}

.icmn-clock3:before {
    content: "\ea4f";
}

.icmn-clock4:before {
    content: "\ea50";
}

.icmn-watch:before {
    content: "\ea51";
}

.icmn-watch2:before {
    content: "\ea52";
}

.icmn-alarm:before {
    content: "\ea53";
}

.icmn-alarm-add:before {
    content: "\ea54";
}

.icmn-alarm-check:before {
    content: "\ea55";
}

.icmn-alarm-cancel:before {
    content: "\ea56";
}

.icmn-bell:before {
    content: "\ea57";
}

.icmn-bell2:before {
    content: "\ea58";
}

.icmn-bell3:before {
    content: "\ea59";
}

.icmn-bell-plus:before {
    content: "\ea5a";
}

.icmn-bell-minus:before {
    content: "\ea5b";
}

.icmn-bell-check:before {
    content: "\ea5c";
}

.icmn-bell-cross:before {
    content: "\ea5d";
}

.icmn-stopwatch:before {
    content: "\ea5e";
}

.icmn-calendar:before {
    content: "\ea5f";
}

.icmn-calendar2:before {
    content: "\ea60";
}

.icmn-calendar3:before {
    content: "\ea61";
}

.icmn-calendar4:before {
    content: "\ea62";
}

.icmn-calendar5:before {
    content: "\ea63";
}

.icmn-calendar-empty:before {
    content: "\ea64";
}

.icmn-calendar-day:before {
    content: "\ea65";
}

.icmn-calendar-week:before {
    content: "\ea66";
}

.icmn-printer:before {
    content: "\ea67";
}

.icmn-printer2:before {
    content: "\ea68";
}

.icmn-printer3:before {
    content: "\ea69";
}

.icmn-printer4:before {
    content: "\ea6a";
}

.icmn-shredder:before {
    content: "\ea6b";
}

.icmn-mouse:before {
    content: "\ea6c";
}

.icmn-mouse2:before {
    content: "\ea6d";
}

.icmn-mouse-left:before {
    content: "\ea6e";
}

.icmn-mouse-right:before {
    content: "\ea6f";
}

.icmn-keyboard:before {
    content: "\ea70";
}

.icmn-keyboard2:before {
    content: "\ea71";
}

.icmn-typewriter:before {
    content: "\ea72";
}

.icmn-display:before {
    content: "\ea73";
}

.icmn-display2:before {
    content: "\ea74";
}

.icmn-display3:before {
    content: "\ea75";
}

.icmn-display4:before {
    content: "\ea76";
}

.icmn-laptop:before {
    content: "\ea77";
}

.icmn-mobile:before {
    content: "\ea78";
}

.icmn-mobile2:before {
    content: "\ea79";
}

.icmn-tablet:before {
    content: "\ea7a";
}

.icmn-mobile3:before {
    content: "\ea7b";
}

.icmn-tv:before {
    content: "\ea7c";
}

.icmn-radio:before {
    content: "\ea7d";
}

.icmn-cabinet:before {
    content: "\ea7e";
}

.icmn-drawer:before {
    content: "\ea7f";
}

.icmn-drawer2:before {
    content: "\ea80";
}

.icmn-drawer-out:before {
    content: "\ea81";
}

.icmn-drawer-in:before {
    content: "\ea82";
}

.icmn-drawer3:before {
    content: "\ea83";
}

.icmn-box:before {
    content: "\ea84";
}

.icmn-box-add:before {
    content: "\ea85";
}

.icmn-box-remove:before {
    content: "\ea86";
}

.icmn-download:before {
    content: "\ea87";
}

.icmn-upload:before {
    content: "\ea88";
}

.icmn-floppy-disk:before {
    content: "\ea89";
}

.icmn-floppy-disks:before {
    content: "\ea8a";
}

.icmn-floppy-disks2:before {
    content: "\ea8b";
}

.icmn-floppy-disk2:before {
    content: "\ea8c";
}

.icmn-floppy-disk3:before {
    content: "\ea8d";
}

.icmn-disk:before {
    content: "\ea8e";
}

.icmn-disk2:before {
    content: "\ea8f";
}

.icmn-usb-stick:before {
    content: "\ea90";
}

.icmn-drive:before {
    content: "\ea91";
}

.icmn-server:before {
    content: "\ea92";
}

.icmn-database:before {
    content: "\ea93";
}

.icmn-database2:before {
    content: "\ea94";
}

.icmn-database3:before {
    content: "\ea95";
}

.icmn-database4:before {
    content: "\ea96";
}

.icmn-database-menu:before {
    content: "\ea97";
}

.icmn-database-add:before {
    content: "\ea98";
}

.icmn-database-remove:before {
    content: "\ea99";
}

.icmn-database-insert:before {
    content: "\ea9a";
}

.icmn-database-export:before {
    content: "\ea9b";
}

.icmn-database-upload:before {
    content: "\ea9c";
}

.icmn-database-refresh:before {
    content: "\ea9d";
}

.icmn-database-diff:before {
    content: "\ea9e";
}

.icmn-database-edit:before {
    content: "\ea9f";
}

.icmn-database-edit2:before {
    content: "\eaa0";
}

.icmn-database-check:before {
    content: "\eaa1";
}

.icmn-database-arrow:before {
    content: "\eaa2";
}

.icmn-database-time:before {
    content: "\eaa3";
}

.icmn-database-time2:before {
    content: "\eaa4";
}

.icmn-undo:before {
    content: "\eaa5";
}

.icmn-redo:before {
    content: "\eaa6";
}

.icmn-rotate-ccw:before {
    content: "\eaa7";
}

.icmn-rotate-cw:before {
    content: "\eaa8";
}

.icmn-rotate-ccw2:before {
    content: "\eaa9";
}

.icmn-rotate-cw2:before {
    content: "\eaaa";
}

.icmn-rotate-ccw3:before {
    content: "\eaab";
}

.icmn-rotate-cw3:before {
    content: "\eaac";
}

.icmn-flip-vertical:before {
    content: "\eaad";
}

.icmn-flip-vertical2:before {
    content: "\eaae";
}

.icmn-flip-horizontal:before {
    content: "\eaaf";
}

.icmn-flip-horizontal2:before {
    content: "\eab0";
}

.icmn-flip-vertical3:before {
    content: "\eab1";
}

.icmn-flip-vertical4:before {
    content: "\eab2";
}

.icmn-angle:before {
    content: "\eab3";
}

.icmn-shear:before {
    content: "\eab4";
}

.icmn-unite:before {
    content: "\eab5";
}

.icmn-subtract:before {
    content: "\eab6";
}

.icmn-interset:before {
    content: "\eab7";
}

.icmn-exclude:before {
    content: "\eab8";
}

.icmn-align-left:before {
    content: "\eab9";
}

.icmn-align-center-horizontal:before {
    content: "\eaba";
}

.icmn-align-right:before {
    content: "\eabb";
}

.icmn-align-top:before {
    content: "\eabc";
}

.icmn-align-center-vertical:before {
    content: "\eabd";
}

.icmn-align-bottom:before {
    content: "\eabe";
}

.icmn-undo2:before {
    content: "\eabf";
}

.icmn-redo2:before {
    content: "\eac0";
}

.icmn-forward:before {
    content: "\eac1";
}

.icmn-reply:before {
    content: "\eac2";
}

.icmn-reply-all:before {
    content: "\eac3";
}

.icmn-bubble:before {
    content: "\eac4";
}

.icmn-bubbles:before {
    content: "\eac5";
}

.icmn-bubbles2:before {
    content: "\eac6";
}

.icmn-bubble2:before {
    content: "\eac7";
}

.icmn-bubbles3:before {
    content: "\eac8";
}

.icmn-bubbles4:before {
    content: "\eac9";
}

.icmn-bubble-notification:before {
    content: "\eaca";
}

.icmn-bubbles5:before {
    content: "\eacb";
}

.icmn-bubbles6:before {
    content: "\eacc";
}

.icmn-bubble3:before {
    content: "\eacd";
}

.icmn-bubble-dots:before {
    content: "\eace";
}

.icmn-bubble-lines:before {
    content: "\eacf";
}

.icmn-bubble4:before {
    content: "\ead0";
}

.icmn-bubble-dots2:before {
    content: "\ead1";
}

.icmn-bubble-lines2:before {
    content: "\ead2";
}

.icmn-bubble5:before {
    content: "\ead3";
}

.icmn-bubble6:before {
    content: "\ead4";
}

.icmn-bubbles7:before {
    content: "\ead5";
}

.icmn-bubble7:before {
    content: "\ead6";
}

.icmn-bubbles8:before {
    content: "\ead7";
}

.icmn-bubble8:before {
    content: "\ead8";
}

.icmn-bubble-dots3:before {
    content: "\ead9";
}

.icmn-bubble-lines3:before {
    content: "\eada";
}

.icmn-bubble9:before {
    content: "\eadb";
}

.icmn-bubble-dots4:before {
    content: "\eadc";
}

.icmn-bubble-lines4:before {
    content: "\eadd";
}

.icmn-bubbles9:before {
    content: "\eade";
}

.icmn-bubbles10:before {
    content: "\eadf";
}

.icmn-bubble-blocked:before {
    content: "\eae0";
}

.icmn-bubble-quote:before {
    content: "\eae1";
}

.icmn-bubble-user:before {
    content: "\eae2";
}

.icmn-bubble-smiley:before {
    content: "\eae3";
}

.icmn-bubble-check:before {
    content: "\eae4";
}

.icmn-bubble-video:before {
    content: "\eae5";
}

.icmn-bubble-link:before {
    content: "\eae6";
}

.icmn-bubble-lock:before {
    content: "\eae7";
}

.icmn-bubble-star:before {
    content: "\eae8";
}

.icmn-bubble-heart:before {
    content: "\eae9";
}

.icmn-bubble-paperclip:before {
    content: "\eaea";
}

.icmn-bubble-cancel:before {
    content: "\eaeb";
}

.icmn-bubble-plus:before {
    content: "\eaec";
}

.icmn-bubble-minus:before {
    content: "\eaed";
}

.icmn-bubble-notification2:before {
    content: "\eaee";
}

.icmn-bubble-left:before {
    content: "\eaef";
}

.icmn-bubble-right:before {
    content: "\eaf0";
}

.icmn-bubble-first:before {
    content: "\eaf1";
}

.icmn-bubble-last:before {
    content: "\eaf2";
}

.icmn-bubble-reply:before {
    content: "\eaf3";
}

.icmn-bubble-forward:before {
    content: "\eaf4";
}

.icmn-bubble-reply2:before {
    content: "\eaf5";
}

.icmn-bubble-forward2:before {
    content: "\eaf6";
}

.icmn-user:before {
    content: "\eaf7";
}

.icmn-users:before {
    content: "\eaf8";
}

.icmn-user-plus:before {
    content: "\eaf9";
}

.icmn-user-minus:before {
    content: "\eafa";
}

.icmn-user-cancel:before {
    content: "\eafb";
}

.icmn-user-block:before {
    content: "\eafc";
}

.icmn-user-lock:before {
    content: "\eafd";
}

.icmn-user-check:before {
    content: "\eafe";
}

.icmn-users2:before {
    content: "\eaff";
}

.icmn-user2:before {
    content: "\eb00";
}

.icmn-users3:before {
    content: "\eb01";
}

.icmn-user-plus2:before {
    content: "\eb02";
}

.icmn-user-minus2:before {
    content: "\eb03";
}

.icmn-user-cancel2:before {
    content: "\eb04";
}

.icmn-user-block2:before {
    content: "\eb05";
}

.icmn-user-lock2:before {
    content: "\eb06";
}

.icmn-user-check2:before {
    content: "\eb07";
}

.icmn-user3:before {
    content: "\eb08";
}

.icmn-user4:before {
    content: "\eb09";
}

.icmn-user5:before {
    content: "\eb0a";
}

.icmn-user6:before {
    content: "\eb0b";
}

.icmn-users4:before {
    content: "\eb0c";
}

.icmn-user-tie:before {
    content: "\eb0d";
}

.icmn-user7:before {
    content: "\eb0e";
}

.icmn-users5:before {
    content: "\eb0f";
}

.icmn-collaboration:before {
    content: "\eb10";
}

.icmn-vcard:before {
    content: "\eb11";
}

.icmn-hat:before {
    content: "\eb12";
}

.icmn-bowtie:before {
    content: "\eb13";
}

.icmn-tie:before {
    content: "\eb14";
}

.icmn-hanger:before {
    content: "\eb15";
}

.icmn-tshirt:before {
    content: "\eb16";
}

.icmn-pants:before {
    content: "\eb17";
}

.icmn-shorts:before {
    content: "\eb18";
}

.icmn-sock:before {
    content: "\eb19";
}

.icmn-laundry:before {
    content: "\eb1a";
}

.icmn-quotes-left:before {
    content: "\eb1b";
}

.icmn-quotes-right:before {
    content: "\eb1c";
}

.icmn-quotes-left2:before {
    content: "\eb1d";
}

.icmn-quotes-right2:before {
    content: "\eb1e";
}

.icmn-hour-glass:before {
    content: "\eb1f";
}

.icmn-hour-glass2:before {
    content: "\eb20";
}

.icmn-hour-glass3:before {
    content: "\eb21";
}

.icmn-spinner:before {
    content: "\eb22";
}

.icmn-spinner2:before {
    content: "\eb23";
}

.icmn-spinner3:before {
    content: "\eb24";
}

.icmn-spinner4:before {
    content: "\eb25";
}

.icmn-spinner5:before {
    content: "\eb26";
}

.icmn-spinner6:before {
    content: "\eb27";
}

.icmn-spinner7:before {
    content: "\eb28";
}

.icmn-spinner8:before {
    content: "\eb29";
}

.icmn-spinner9:before {
    content: "\eb2a";
}

.icmn-spinner10:before {
    content: "\eb2b";
}

.icmn-spinner11:before {
    content: "\eb2c";
}

.icmn-microscope:before {
    content: "\eb2d";
}

.icmn-binoculars:before {
    content: "\eb2e";
}

.icmn-binoculars2:before {
    content: "\eb2f";
}

.icmn-search:before {
    content: "\eb30";
}

.icmn-zoom-in:before {
    content: "\eb31";
}

.icmn-zoom-out:before {
    content: "\eb32";
}

.icmn-search2:before {
    content: "\eb33";
}

.icmn-zoom-in2:before {
    content: "\eb34";
}

.icmn-zoom-out2:before {
    content: "\eb35";
}

.icmn-search3:before {
    content: "\eb36";
}

.icmn-search4:before {
    content: "\eb37";
}

.icmn-zoom-in3:before {
    content: "\eb38";
}

.icmn-zoom-out3:before {
    content: "\eb39";
}

.icmn-search5:before {
    content: "\eb3a";
}

.icmn-search6:before {
    content: "\eb3b";
}

.icmn-enlarge:before {
    content: "\eb3c";
}

.icmn-shrink:before {
    content: "\eb3d";
}

.icmn-enlarge2:before {
    content: "\eb3e";
}

.icmn-shrink2:before {
    content: "\eb3f";
}

.icmn-enlarge3:before {
    content: "\eb40";
}

.icmn-shrink3:before {
    content: "\eb41";
}

.icmn-enlarge4:before {
    content: "\eb42";
}

.icmn-shrink4:before {
    content: "\eb43";
}

.icmn-enlarge5:before {
    content: "\eb44";
}

.icmn-shrink5:before {
    content: "\eb45";
}

.icmn-enlarge6:before {
    content: "\eb46";
}

.icmn-shrink6:before {
    content: "\eb47";
}

.icmn-enlarge7:before {
    content: "\eb48";
}

.icmn-shrink7:before {
    content: "\eb49";
}

.icmn-key:before {
    content: "\eb4a";
}

.icmn-key2:before {
    content: "\eb4b";
}

.icmn-key3:before {
    content: "\eb4c";
}

.icmn-key4:before {
    content: "\eb4d";
}

.icmn-key5:before {
    content: "\eb4e";
}

.icmn-keyhole:before {
    content: "\eb4f";
}

.icmn-lock:before {
    content: "\eb50";
}

.icmn-lock2:before {
    content: "\eb51";
}

.icmn-lock3:before {
    content: "\eb52";
}

.icmn-lock4:before {
    content: "\eb53";
}

.icmn-unlocked:before {
    content: "\eb54";
}

.icmn-lock5:before {
    content: "\eb55";
}

.icmn-unlocked2:before {
    content: "\eb56";
}

.icmn-safe:before {
    content: "\eb57";
}

.icmn-wrench:before {
    content: "\eb58";
}

.icmn-wrench2:before {
    content: "\eb59";
}

.icmn-wrench3:before {
    content: "\eb5a";
}

.icmn-equalizer:before {
    content: "\eb5b";
}

.icmn-equalizer2:before {
    content: "\eb5c";
}

.icmn-equalizer3:before {
    content: "\eb5d";
}

.icmn-equalizer4:before {
    content: "\eb5e";
}

.icmn-cog:before {
    content: "\eb5f";
}

.icmn-cogs:before {
    content: "\eb60";
}

.icmn-cog2:before {
    content: "\eb61";
}

.icmn-cog3:before {
    content: "\eb62";
}

.icmn-cog4:before {
    content: "\eb63";
}

.icmn-cog5:before {
    content: "\eb64";
}

.icmn-cog6:before {
    content: "\eb65";
}

.icmn-cog7:before {
    content: "\eb66";
}

.icmn-factory:before {
    content: "\eb67";
}

.icmn-hammer:before {
    content: "\eb68";
}

.icmn-hammer-wrench:before {
    content: "\eb69";
}

.icmn-knife:before {
    content: "\eb6a";
}

.icmn-screwdriver:before {
    content: "\eb6b";
}

.icmn-screwdriver2:before {
    content: "\eb6c";
}

.icmn-magic-wand:before {
    content: "\eb6d";
}

.icmn-magic-wand2:before {
    content: "\eb6e";
}

.icmn-pulse:before {
    content: "\eb6f";
}

.icmn-pulse2:before {
    content: "\eb70";
}

.icmn-aid-kit:before {
    content: "\eb71";
}

.icmn-aid-kit2:before {
    content: "\eb72";
}

.icmn-stethoscope:before {
    content: "\eb73";
}

.icmn-ambulance:before {
    content: "\eb74";
}

.icmn-alarm2:before {
    content: "\eb75";
}

.icmn-pandage:before {
    content: "\eb76";
}

.icmn-pandage2:before {
    content: "\eb77";
}

.icmn-bug:before {
    content: "\eb78";
}

.icmn-bug2:before {
    content: "\eb79";
}

.icmn-syringe:before {
    content: "\eb7a";
}

.icmn-syringe2:before {
    content: "\eb7b";
}

.icmn-pill:before {
    content: "\eb7c";
}

.icmn-construction:before {
    content: "\eb7d";
}

.icmn-traffic-cone:before {
    content: "\eb7e";
}

.icmn-traffic-lights:before {
    content: "\eb7f";
}

.icmn-pie-chart:before {
    content: "\eb80";
}

.icmn-pie-chart2:before {
    content: "\eb81";
}

.icmn-pie-chart3:before {
    content: "\eb82";
}

.icmn-pie-chart4:before {
    content: "\eb83";
}

.icmn-pie-chart5:before {
    content: "\eb84";
}

.icmn-pie-chart6:before {
    content: "\eb85";
}

.icmn-pie-chart7:before {
    content: "\eb86";
}

.icmn-stats-dots:before {
    content: "\eb87";
}

.icmn-stats-bars:before {
    content: "\eb88";
}

.icmn-pie-chart8:before {
    content: "\eb89";
}

.icmn-stats-bars2:before {
    content: "\eb8a";
}

.icmn-stats-bars3:before {
    content: "\eb8b";
}

.icmn-stats-bars4:before {
    content: "\eb8c";
}

.icmn-stats-bars5:before {
    content: "\eb8d";
}

.icmn-stats-bars6:before {
    content: "\eb8e";
}

.icmn-chart:before {
    content: "\eb8f";
}

.icmn-stats-growth:before {
    content: "\eb90";
}

.icmn-stats-decline:before {
    content: "\eb91";
}

.icmn-stats-growth2:before {
    content: "\eb92";
}

.icmn-stats-decline2:before {
    content: "\eb93";
}

.icmn-stairs-up:before {
    content: "\eb94";
}

.icmn-stairs-down:before {
    content: "\eb95";
}

.icmn-stairs:before {
    content: "\eb96";
}

.icmn-stairs2:before {
    content: "\eb97";
}

.icmn-ladder:before {
    content: "\eb98";
}

.icmn-rating:before {
    content: "\eb99";
}

.icmn-rating2:before {
    content: "\eb9a";
}

.icmn-rating3:before {
    content: "\eb9b";
}

.icmn-cake:before {
    content: "\eb9c";
}

.icmn-podium:before {
    content: "\eb9d";
}

.icmn-stars:before {
    content: "\eb9e";
}

.icmn-medal-star:before {
    content: "\eb9f";
}

.icmn-medal:before {
    content: "\eba0";
}

.icmn-medal2:before {
    content: "\eba1";
}

.icmn-medal-first:before {
    content: "\eba2";
}

.icmn-medal-second:before {
    content: "\eba3";
}

.icmn-medal-third:before {
    content: "\eba4";
}

.icmn-crown:before {
    content: "\eba5";
}

.icmn-trophy:before {
    content: "\eba6";
}

.icmn-trophy2:before {
    content: "\eba7";
}

.icmn-trophy3:before {
    content: "\eba8";
}

.icmn-diamond:before {
    content: "\eba9";
}

.icmn-diamond2:before {
    content: "\ebaa";
}

.icmn-trophy4:before {
    content: "\ebab";
}

.icmn-gift:before {
    content: "\ebac";
}

.icmn-gift2:before {
    content: "\ebad";
}

.icmn-balloon:before {
    content: "\ebae";
}

.icmn-pipe:before {
    content: "\ebaf";
}

.icmn-mustache:before {
    content: "\ebb0";
}

.icmn-mustache2:before {
    content: "\ebb1";
}

.icmn-glass:before {
    content: "\ebb2";
}

.icmn-glass2:before {
    content: "\ebb3";
}

.icmn-glass3:before {
    content: "\ebb4";
}

.icmn-glass4:before {
    content: "\ebb5";
}

.icmn-bottle:before {
    content: "\ebb6";
}

.icmn-bottle2:before {
    content: "\ebb7";
}

.icmn-bottle3:before {
    content: "\ebb8";
}

.icmn-bottle4:before {
    content: "\ebb9";
}

.icmn-mug:before {
    content: "\ebba";
}

.icmn-fork-knife:before {
    content: "\ebbb";
}

.icmn-spoon-knife:before {
    content: "\ebbc";
}

.icmn-cook:before {
    content: "\ebbd";
}

.icmn-plate:before {
    content: "\ebbe";
}

.icmn-steak:before {
    content: "\ebbf";
}

.icmn-fish:before {
    content: "\ebc0";
}

.icmn-chicken:before {
    content: "\ebc1";
}

.icmn-hamburger:before {
    content: "\ebc2";
}

.icmn-pizza:before {
    content: "\ebc3";
}

.icmn-cheese:before {
    content: "\ebc4";
}

.icmn-bread:before {
    content: "\ebc5";
}

.icmn-icecream:before {
    content: "\ebc6";
}

.icmn-milk:before {
    content: "\ebc7";
}

.icmn-cup:before {
    content: "\ebc8";
}

.icmn-cup2:before {
    content: "\ebc9";
}

.icmn-coffee-bean:before {
    content: "\ebca";
}

.icmn-coffee:before {
    content: "\ebcb";
}

.icmn-teapot:before {
    content: "\ebcc";
}

.icmn-leaf:before {
    content: "\ebcd";
}

.icmn-leaf2:before {
    content: "\ebce";
}

.icmn-apple:before {
    content: "\ebcf";
}

.icmn-grapes:before {
    content: "\ebd0";
}

.icmn-cherry:before {
    content: "\ebd1";
}

.icmn-tree:before {
    content: "\ebd2";
}

.icmn-tree2:before {
    content: "\ebd3";
}

.icmn-tree3:before {
    content: "\ebd4";
}

.icmn-tree4:before {
    content: "\ebd5";
}

.icmn-cactus:before {
    content: "\ebd6";
}

.icmn-mountains:before {
    content: "\ebd7";
}

.icmn-paw:before {
    content: "\ebd8";
}

.icmn-footprint:before {
    content: "\ebd9";
}

.icmn-tent:before {
    content: "\ebda";
}

.icmn-flower:before {
    content: "\ebdb";
}

.icmn-flower2:before {
    content: "\ebdc";
}

.icmn-rocket:before {
    content: "\ebdd";
}

.icmn-meter:before {
    content: "\ebde";
}

.icmn-meter2:before {
    content: "\ebdf";
}

.icmn-meter-slow:before {
    content: "\ebe0";
}

.icmn-meter-medium:before {
    content: "\ebe1";
}

.icmn-meter-fast:before {
    content: "\ebe2";
}

.icmn-meter3:before {
    content: "\ebe3";
}

.icmn-hammer2:before {
    content: "\ebe4";
}

.icmn-balance:before {
    content: "\ebe5";
}

.icmn-scale:before {
    content: "\ebe6";
}

.icmn-bomb:before {
    content: "\ebe7";
}

.icmn-fire:before {
    content: "\ebe8";
}

.icmn-fire2:before {
    content: "\ebe9";
}

.icmn-lab:before {
    content: "\ebea";
}

.icmn-atom:before {
    content: "\ebeb";
}

.icmn-atom2:before {
    content: "\ebec";
}

.icmn-magnet:before {
    content: "\ebed";
}

.icmn-magnet2:before {
    content: "\ebee";
}

.icmn-dumbbell:before {
    content: "\ebef";
}

.icmn-skull:before {
    content: "\ebf0";
}

.icmn-skull2:before {
    content: "\ebf1";
}

.icmn-lamp:before {
    content: "\ebf2";
}

.icmn-lamp2:before {
    content: "\ebf3";
}

.icmn-lamp3:before {
    content: "\ebf4";
}

.icmn-spotlight:before {
    content: "\ebf5";
}

.icmn-lamp4:before {
    content: "\ebf6";
}

.icmn-lamp5:before {
    content: "\ebf7";
}

.icmn-lamp6:before {
    content: "\ebf8";
}

.icmn-lamp7:before {
    content: "\ebf9";
}

.icmn-lamp8:before {
    content: "\ebfa";
}

.icmn-broom:before {
    content: "\ebfb";
}

.icmn-toiletpaper:before {
    content: "\ebfc";
}

.icmn-bin:before {
    content: "\ebfd";
}

.icmn-bin2:before {
    content: "\ebfe";
}

.icmn-bin3:before {
    content: "\ebff";
}

.icmn-bin4:before {
    content: "\ec00";
}

.icmn-bin5:before {
    content: "\ec01";
}

.icmn-briefcase:before {
    content: "\ec02";
}

.icmn-briefcase2:before {
    content: "\ec03";
}

.icmn-briefcase3:before {
    content: "\ec04";
}

.icmn-airplane:before {
    content: "\ec05";
}

.icmn-airplane2:before {
    content: "\ec06";
}

.icmn-airplane3:before {
    content: "\ec07";
}

.icmn-airplane4:before {
    content: "\ec08";
}

.icmn-paperplane:before {
    content: "\ec09";
}

.icmn-car:before {
    content: "\ec0a";
}

.icmn-steering-wheel:before {
    content: "\ec0b";
}

.icmn-car2:before {
    content: "\ec0c";
}

.icmn-gas:before {
    content: "\ec0d";
}

.icmn-bus:before {
    content: "\ec0e";
}

.icmn-truck:before {
    content: "\ec0f";
}

.icmn-bike:before {
    content: "\ec10";
}

.icmn-road:before {
    content: "\ec11";
}

.icmn-train:before {
    content: "\ec12";
}

.icmn-train2:before {
    content: "\ec13";
}

.icmn-ship:before {
    content: "\ec14";
}

.icmn-boat:before {
    content: "\ec15";
}

.icmn-chopper:before {
    content: "\ec16";
}

.icmn-chopper2:before {
    content: "\ec17";
}

.icmn-cube:before {
    content: "\ec18";
}

.icmn-cube2:before {
    content: "\ec19";
}

.icmn-cube3:before {
    content: "\ec1a";
}

.icmn-cube4:before {
    content: "\ec1b";
}

.icmn-pyramid:before {
    content: "\ec1c";
}

.icmn-pyramid2:before {
    content: "\ec1d";
}

.icmn-package:before {
    content: "\ec1e";
}

.icmn-puzzle:before {
    content: "\ec1f";
}

.icmn-puzzle2:before {
    content: "\ec20";
}

.icmn-puzzle3:before {
    content: "\ec21";
}

.icmn-puzzle4:before {
    content: "\ec22";
}

.icmn-glasses-3d:before {
    content: "\ec23";
}

.icmn-glasses-3d2:before {
    content: "\ec24";
}

.icmn-glasses:before {
    content: "\ec25";
}

.icmn-glasses2:before {
    content: "\ec26";
}

.icmn-brain:before {
    content: "\ec27";
}

.icmn-accessibility:before {
    content: "\ec28";
}

.icmn-accessibility2:before {
    content: "\ec29";
}

.icmn-strategy:before {
    content: "\ec2a";
}

.icmn-target:before {
    content: "\ec2b";
}

.icmn-target2:before {
    content: "\ec2c";
}

.icmn-target3:before {
    content: "\ec2d";
}

.icmn-ammo:before {
    content: "\ec2e";
}

.icmn-gun:before {
    content: "\ec2f";
}

.icmn-gun-forbidden:before {
    content: "\ec30";
}

.icmn-shield:before {
    content: "\ec31";
}

.icmn-shield-check:before {
    content: "\ec32";
}

.icmn-shield-notice:before {
    content: "\ec33";
}

.icmn-shield2:before {
    content: "\ec34";
}

.icmn-shield3:before {
    content: "\ec35";
}

.icmn-shield4:before {
    content: "\ec36";
}

.icmn-soccer:before {
    content: "\ec37";
}

.icmn-football:before {
    content: "\ec38";
}

.icmn-archery:before {
    content: "\ec39";
}

.icmn-bow:before {
    content: "\ec3a";
}

.icmn-sword:before {
    content: "\ec3b";
}

.icmn-tennis:before {
    content: "\ec3c";
}

.icmn-pingpong:before {
    content: "\ec3d";
}

.icmn-baseball:before {
    content: "\ec3e";
}

.icmn-basketball:before {
    content: "\ec3f";
}

.icmn-golf:before {
    content: "\ec40";
}

.icmn-golf2:before {
    content: "\ec41";
}

.icmn-hockey:before {
    content: "\ec42";
}

.icmn-racing:before {
    content: "\ec43";
}

.icmn-finish:before {
    content: "\ec44";
}

.icmn-eight-ball:before {
    content: "\ec45";
}

.icmn-bowling:before {
    content: "\ec46";
}

.icmn-bowling2:before {
    content: "\ec47";
}

.icmn-power:before {
    content: "\ec48";
}

.icmn-power2:before {
    content: "\ec49";
}

.icmn-power3:before {
    content: "\ec4a";
}

.icmn-switch:before {
    content: "\ec4b";
}

.icmn-switch2:before {
    content: "\ec4c";
}

.icmn-power-cord:before {
    content: "\ec4d";
}

.icmn-power-cord2:before {
    content: "\ec4e";
}

.icmn-outlet:before {
    content: "\ec4f";
}

.icmn-clipboard:before {
    content: "\ec50";
}

.icmn-clipboard2:before {
    content: "\ec51";
}

.icmn-clipboard3:before {
    content: "\ec52";
}

.icmn-clipboard4:before {
    content: "\ec53";
}

.icmn-clipboard5:before {
    content: "\ec54";
}

.icmn-clipboard6:before {
    content: "\ec55";
}

.icmn-playlist:before {
    content: "\ec56";
}

.icmn-playlist-add:before {
    content: "\ec57";
}

.icmn-list-numbered:before {
    content: "\ec58";
}

.icmn-list:before {
    content: "\ec59";
}

.icmn-list2:before {
    content: "\ec5a";
}

.icmn-more:before {
    content: "\ec5b";
}

.icmn-more2:before {
    content: "\ec5c";
}

.icmn-grid:before {
    content: "\ec5d";
}

.icmn-grid2:before {
    content: "\ec5e";
}

.icmn-grid3:before {
    content: "\ec5f";
}

.icmn-grid4:before {
    content: "\ec60";
}

.icmn-grid5:before {
    content: "\ec61";
}

.icmn-grid6:before {
    content: "\ec62";
}

.icmn-grid7:before {
    content: "\ec63";
}

.icmn-tree5:before {
    content: "\ec64";
}

.icmn-tree6:before {
    content: "\ec65";
}

.icmn-tree7:before {
    content: "\ec66";
}

.icmn-lan:before {
    content: "\ec67";
}

.icmn-lan2:before {
    content: "\ec68";
}

.icmn-lan3:before {
    content: "\ec69";
}

.icmn-menu:before {
    content: "\ec6a";
}

.icmn-circle-small:before {
    content: "\ec6b";
}

.icmn-menu2:before {
    content: "\ec6c";
}

.icmn-menu3:before {
    content: "\ec6d";
}

.icmn-menu4:before {
    content: "\ec6e";
}

.icmn-menu5:before {
    content: "\ec6f";
}

.icmn-menu6:before {
    content: "\ec70";
}

.icmn-menu7:before {
    content: "\ec71";
}

.icmn-menu8:before {
    content: "\ec72";
}

.icmn-menu9:before {
    content: "\ec73";
}

.icmn-menu10:before {
    content: "\ec74";
}

.icmn-cloud:before {
    content: "\ec75";
}

.icmn-cloud-download:before {
    content: "\ec76";
}

.icmn-cloud-upload:before {
    content: "\ec77";
}

.icmn-cloud-check:before {
    content: "\ec78";
}

.icmn-cloud2:before {
    content: "\ec79";
}

.icmn-cloud-download2:before {
    content: "\ec7a";
}

.icmn-cloud-upload2:before {
    content: "\ec7b";
}

.icmn-cloud-check2:before {
    content: "\ec7c";
}

.icmn-download2:before {
    content: "\ec7d";
}

.icmn-download3:before {
    content: "\ec7e";
}

.icmn-upload2:before {
    content: "\ec7f";
}

.icmn-upload3:before {
    content: "\ec80";
}

.icmn-import:before {
    content: "\ec81";
}

.icmn-import2:before {
    content: "\ec82";
}

.icmn-download4:before {
    content: "\ec83";
}

.icmn-upload4:before {
    content: "\ec84";
}

.icmn-download5:before {
    content: "\ec85";
}

.icmn-upload5:before {
    content: "\ec86";
}

.icmn-download6:before {
    content: "\ec87";
}

.icmn-upload6:before {
    content: "\ec88";
}

.icmn-download7:before {
    content: "\ec89";
}

.icmn-upload7:before {
    content: "\ec8a";
}

.icmn-download8:before {
    content: "\ec8b";
}

.icmn-upload8:before {
    content: "\ec8c";
}

.icmn-download9:before {
    content: "\ec8d";
}

.icmn-upload9:before {
    content: "\ec8e";
}

.icmn-download10:before {
    content: "\ec8f";
}

.icmn-upload10:before {
    content: "\ec90";
}

.icmn-sphere:before {
    content: "\ec91";
}

.icmn-sphere2:before {
    content: "\ec92";
}

.icmn-sphere3:before {
    content: "\ec93";
}

.icmn-planet:before {
    content: "\ec94";
}

.icmn-planet2:before {
    content: "\ec95";
}

.icmn-earth:before {
    content: "\ec96";
}

.icmn-earth2:before {
    content: "\ec97";
}

.icmn-earth3:before {
    content: "\ec98";
}

.icmn-link:before {
    content: "\ec99";
}

.icmn-unlink:before {
    content: "\ec9a";
}

.icmn-link2:before {
    content: "\ec9b";
}

.icmn-unlink2:before {
    content: "\ec9c";
}

.icmn-link3:before {
    content: "\ec9d";
}

.icmn-unlink3:before {
    content: "\ec9e";
}

.icmn-link4:before {
    content: "\ec9f";
}

.icmn-unlink4:before {
    content: "\eca0";
}

.icmn-link5:before {
    content: "\eca1";
}

.icmn-unlink5:before {
    content: "\eca2";
}

.icmn-anchor:before {
    content: "\eca3";
}

.icmn-flag:before {
    content: "\eca4";
}

.icmn-flag2:before {
    content: "\eca5";
}

.icmn-flag3:before {
    content: "\eca6";
}

.icmn-flag4:before {
    content: "\eca7";
}

.icmn-flag5:before {
    content: "\eca8";
}

.icmn-flag6:before {
    content: "\eca9";
}

.icmn-flag7:before {
    content: "\ecaa";
}

.icmn-flag8:before {
    content: "\ecab";
}

.icmn-attachment:before {
    content: "\ecac";
}

.icmn-attachment2:before {
    content: "\ecad";
}

.icmn-eye:before {
    content: "\ecae";
}

.icmn-eye-plus:before {
    content: "\ecaf";
}

.icmn-eye-minus:before {
    content: "\ecb0";
}

.icmn-eye-blocked:before {
    content: "\ecb1";
}

.icmn-eye2:before {
    content: "\ecb2";
}

.icmn-eye-blocked2:before {
    content: "\ecb3";
}

.icmn-eye3:before {
    content: "\ecb4";
}

.icmn-eye-blocked3:before {
    content: "\ecb5";
}

.icmn-eye4:before {
    content: "\ecb6";
}

.icmn-bookmark2:before {
    content: "\ecb7";
}

.icmn-bookmark3:before {
    content: "\ecb8";
}

.icmn-bookmarks:before {
    content: "\ecb9";
}

.icmn-bookmark4:before {
    content: "\ecba";
}

.icmn-spotlight2:before {
    content: "\ecbb";
}

.icmn-starburst:before {
    content: "\ecbc";
}

.icmn-snowflake:before {
    content: "\ecbd";
}

.icmn-temperature:before {
    content: "\ecbe";
}

.icmn-temperature2:before {
    content: "\ecbf";
}

.icmn-weather-lightning:before {
    content: "\ecc0";
}

.icmn-weather-lightning2:before {
    content: "\ecc1";
}

.icmn-weather-rain:before {
    content: "\ecc2";
}

.icmn-weather-rain2:before {
    content: "\ecc3";
}

.icmn-weather-snow:before {
    content: "\ecc4";
}

.icmn-weather-snow2:before {
    content: "\ecc5";
}

.icmn-weather-cloud-wind:before {
    content: "\ecc6";
}

.icmn-weather-cloud-wind2:before {
    content: "\ecc7";
}

.icmn-weather-cloud-sun:before {
    content: "\ecc8";
}

.icmn-weather-cloud-sun2:before {
    content: "\ecc9";
}

.icmn-weather-cloudy:before {
    content: "\ecca";
}

.icmn-weather-cloudy2:before {
    content: "\eccb";
}

.icmn-weather-sun-wind:before {
    content: "\eccc";
}

.icmn-weather-sun-wind2:before {
    content: "\eccd";
}

.icmn-sun:before {
    content: "\ecce";
}

.icmn-sun2:before {
    content: "\eccf";
}

.icmn-moon:before {
    content: "\ecd0";
}

.icmn-day-night:before {
    content: "\ecd1";
}

.icmn-day-night2:before {
    content: "\ecd2";
}

.icmn-weather-windy:before {
    content: "\ecd3";
}

.icmn-fan:before {
    content: "\ecd4";
}

.icmn-umbrella:before {
    content: "\ecd5";
}

.icmn-sun3:before {
    content: "\ecd6";
}

.icmn-contrast:before {
    content: "\ecd7";
}

.icmn-brightness-contrast:before {
    content: "\ecd8";
}

.icmn-brightness-high:before {
    content: "\ecd9";
}

.icmn-brightness-medium:before {
    content: "\ecda";
}

.icmn-brightness-low:before {
    content: "\ecdb";
}

.icmn-bed:before {
    content: "\ecdc";
}

.icmn-bed2:before {
    content: "\ecdd";
}

.icmn-furniture:before {
    content: "\ecde";
}

.icmn-chair:before {
    content: "\ecdf";
}

.icmn-star-empty:before {
    content: "\ece0";
}

.icmn-star-empty2:before {
    content: "\ece1";
}

.icmn-star-full:before {
    content: "\ece2";
}

.icmn-star-empty3:before {
    content: "\ece3";
}

.icmn-star-half:before {
    content: "\ece4";
}

.icmn-star-full2:before {
    content: "\ece5";
}

.icmn-star:before {
    content: "\ece6";
}

.icmn-heart:before {
    content: "\ece7";
}

.icmn-heart2:before {
    content: "\ece8";
}

.icmn-heart3:before {
    content: "\ece9";
}

.icmn-heart4:before {
    content: "\ecea";
}

.icmn-heart-broken:before {
    content: "\eceb";
}

.icmn-heart5:before {
    content: "\ecec";
}

.icmn-heart6:before {
    content: "\eced";
}

.icmn-heart-broken2:before {
    content: "\ecee";
}

.icmn-heart7:before {
    content: "\ecef";
}

.icmn-heart8:before {
    content: "\ecf0";
}

.icmn-heart-broken3:before {
    content: "\ecf1";
}

.icmn-lips:before {
    content: "\ecf2";
}

.icmn-lips2:before {
    content: "\ecf3";
}

.icmn-thumbs-up:before {
    content: "\ecf4";
}

.icmn-thumbs-up2:before {
    content: "\ecf5";
}

.icmn-thumbs-down:before {
    content: "\ecf6";
}

.icmn-thumbs-down2:before {
    content: "\ecf7";
}

.icmn-thumbs-up3:before {
    content: "\ecf8";
}

.icmn-thumbs-down3:before {
    content: "\ecf9";
}

.icmn-height:before {
    content: "\ecfa";
}

.icmn-man:before {
    content: "\ecfb";
}

.icmn-woman:before {
    content: "\ecfc";
}

.icmn-man-woman:before {
    content: "\ecfd";
}

.icmn-male:before {
    content: "\ecfe";
}

.icmn-female:before {
    content: "\ecff";
}

.icmn-peace:before {
    content: "\ed00";
}

.icmn-yin-yang:before {
    content: "\ed01";
}

.icmn-happy:before {
    content: "\ed02";
}

.icmn-happy2:before {
    content: "\ed03";
}

.icmn-smile:before {
    content: "\ed04";
}

.icmn-smile2:before {
    content: "\ed05";
}

.icmn-tongue:before {
    content: "\ed06";
}

.icmn-tongue2:before {
    content: "\ed07";
}

.icmn-sad:before {
    content: "\ed08";
}

.icmn-sad2:before {
    content: "\ed09";
}

.icmn-wink:before {
    content: "\ed0a";
}

.icmn-wink2:before {
    content: "\ed0b";
}

.icmn-grin:before {
    content: "\ed0c";
}

.icmn-grin2:before {
    content: "\ed0d";
}

.icmn-cool:before {
    content: "\ed0e";
}

.icmn-cool2:before {
    content: "\ed0f";
}

.icmn-angry:before {
    content: "\ed10";
}

.icmn-angry2:before {
    content: "\ed11";
}

.icmn-evil:before {
    content: "\ed12";
}

.icmn-evil2:before {
    content: "\ed13";
}

.icmn-shocked:before {
    content: "\ed14";
}

.icmn-shocked2:before {
    content: "\ed15";
}

.icmn-baffled:before {
    content: "\ed16";
}

.icmn-baffled2:before {
    content: "\ed17";
}

.icmn-confused:before {
    content: "\ed18";
}

.icmn-confused2:before {
    content: "\ed19";
}

.icmn-neutral:before {
    content: "\ed1a";
}

.icmn-neutral2:before {
    content: "\ed1b";
}

.icmn-hipster:before {
    content: "\ed1c";
}

.icmn-hipster2:before {
    content: "\ed1d";
}

.icmn-wondering:before {
    content: "\ed1e";
}

.icmn-wondering2:before {
    content: "\ed1f";
}

.icmn-sleepy:before {
    content: "\ed20";
}

.icmn-sleepy2:before {
    content: "\ed21";
}

.icmn-frustrated:before {
    content: "\ed22";
}

.icmn-frustrated2:before {
    content: "\ed23";
}

.icmn-crying:before {
    content: "\ed24";
}

.icmn-crying2:before {
    content: "\ed25";
}

.icmn-cursor:before {
    content: "\ed26";
}

.icmn-cursor2:before {
    content: "\ed27";
}

.icmn-lasso:before {
    content: "\ed28";
}

.icmn-lasso2:before {
    content: "\ed29";
}

.icmn-select:before {
    content: "\ed2a";
}

.icmn-select2:before {
    content: "\ed2b";
}

.icmn-point-up:before {
    content: "\ed2c";
}

.icmn-point-right:before {
    content: "\ed2d";
}

.icmn-point-down:before {
    content: "\ed2e";
}

.icmn-point-left:before {
    content: "\ed2f";
}

.icmn-pointer:before {
    content: "\ed30";
}

.icmn-reminder:before {
    content: "\ed31";
}

.icmn-drag-left-right:before {
    content: "\ed32";
}

.icmn-drag-left:before {
    content: "\ed33";
}

.icmn-drag-right:before {
    content: "\ed34";
}

.icmn-touch:before {
    content: "\ed35";
}

.icmn-multitouch:before {
    content: "\ed36";
}

.icmn-touch-zoom:before {
    content: "\ed37";
}

.icmn-touch-pinch:before {
    content: "\ed38";
}

.icmn-hand:before {
    content: "\ed39";
}

.icmn-grab:before {
    content: "\ed3a";
}

.icmn-stack-empty:before {
    content: "\ed3b";
}

.icmn-stack-plus:before {
    content: "\ed3c";
}

.icmn-stack-minus:before {
    content: "\ed3d";
}

.icmn-stack-star:before {
    content: "\ed3e";
}

.icmn-stack-picture:before {
    content: "\ed3f";
}

.icmn-stack-down:before {
    content: "\ed40";
}

.icmn-stack-up:before {
    content: "\ed41";
}

.icmn-stack-cancel:before {
    content: "\ed42";
}

.icmn-stack-check:before {
    content: "\ed43";
}

.icmn-stack-text:before {
    content: "\ed44";
}

.icmn-stack-clubs:before {
    content: "\ed45";
}

.icmn-stack-spades:before {
    content: "\ed46";
}

.icmn-stack-hearts:before {
    content: "\ed47";
}

.icmn-stack-diamonds:before {
    content: "\ed48";
}

.icmn-stack-user:before {
    content: "\ed49";
}

.icmn-stack4:before {
    content: "\ed4a";
}

.icmn-stack-music:before {
    content: "\ed4b";
}

.icmn-stack-play:before {
    content: "\ed4c";
}

.icmn-move:before {
    content: "\ed4d";
}

.icmn-dots:before {
    content: "\ed4e";
}

.icmn-warning:before {
    content: "\ed4f";
}

.icmn-warning2:before {
    content: "\ed50";
}

.icmn-notification:before {
    content: "\ed51";
}

.icmn-notification2:before {
    content: "\ed52";
}

.icmn-question:before {
    content: "\ed53";
}

.icmn-question2:before {
    content: "\ed54";
}

.icmn-question3:before {
    content: "\ed55";
}

.icmn-question4:before {
    content: "\ed56";
}

.icmn-question5:before {
    content: "\ed57";
}

.icmn-question6:before {
    content: "\ed58";
}

.icmn-plus:before {
    content: "\ed59";
}

.icmn-minus:before {
    content: "\ed5a";
}

.icmn-plus2:before {
    content: "\ed5b";
}

.icmn-minus2:before {
    content: "\ed5c";
}

.icmn-plus3:before {
    content: "\ed5d";
}

.icmn-minus3:before {
    content: "\ed5e";
}

.icmn-plus-circle:before {
    content: "\ed5f";
}

.icmn-minus-circle:before {
    content: "\ed60";
}

.icmn-plus-circle2:before {
    content: "\ed61";
}

.icmn-minus-circle2:before {
    content: "\ed62";
}

.icmn-info:before {
    content: "\ed63";
}

.icmn-info2:before {
    content: "\ed64";
}

.icmn-cancel-circle:before {
    content: "\ed65";
}

.icmn-cancel-circle2:before {
    content: "\ed66";
}

.icmn-blocked:before {
    content: "\ed67";
}

.icmn-cancel-square:before {
    content: "\ed68";
}

.icmn-cancel-square2:before {
    content: "\ed69";
}

.icmn-cancel:before {
    content: "\ed6a";
}

.icmn-spam:before {
    content: "\ed6b";
}

.icmn-cross:before {
    content: "\ed6c";
}

.icmn-cross2:before {
    content: "\ed6d";
}

.icmn-cross3:before {
    content: "\ed6e";
}

.icmn-checkmark:before {
    content: "\ed6f";
}

.icmn-checkmark2:before {
    content: "\ed70";
}

.icmn-checkmark3:before {
    content: "\ed71";
}

.icmn-checkmark4:before {
    content: "\ed72";
}

.icmn-checkmark5:before {
    content: "\ed73";
}

.icmn-spell-check:before {
    content: "\ed74";
}

.icmn-spell-check2:before {
    content: "\ed75";
}

.icmn-enter:before {
    content: "\ed76";
}

.icmn-exit:before {
    content: "\ed77";
}

.icmn-enter2:before {
    content: "\ed78";
}

.icmn-exit2:before {
    content: "\ed79";
}

.icmn-enter3:before {
    content: "\ed7a";
}

.icmn-exit3:before {
    content: "\ed7b";
}

.icmn-wall:before {
    content: "\ed7c";
}

.icmn-fence:before {
    content: "\ed7d";
}

.icmn-play3:before {
    content: "\ed7e";
}

.icmn-pause:before {
    content: "\ed7f";
}

.icmn-stop:before {
    content: "\ed80";
}

.icmn-previous:before {
    content: "\ed81";
}

.icmn-next:before {
    content: "\ed82";
}

.icmn-backward:before {
    content: "\ed83";
}

.icmn-forward2:before {
    content: "\ed84";
}

.icmn-play4:before {
    content: "\ed85";
}

.icmn-pause2:before {
    content: "\ed86";
}

.icmn-stop2:before {
    content: "\ed87";
}

.icmn-backward2:before {
    content: "\ed88";
}

.icmn-forward3:before {
    content: "\ed89";
}

.icmn-first:before {
    content: "\ed8a";
}

.icmn-last:before {
    content: "\ed8b";
}

.icmn-previous2:before {
    content: "\ed8c";
}

.icmn-next2:before {
    content: "\ed8d";
}

.icmn-eject:before {
    content: "\ed8e";
}

.icmn-volume-high:before {
    content: "\ed8f";
}

.icmn-volume-medium:before {
    content: "\ed90";
}

.icmn-volume-low:before {
    content: "\ed91";
}

.icmn-volume-mute:before {
    content: "\ed92";
}

.icmn-speaker-left:before {
    content: "\ed93";
}

.icmn-speaker-right:before {
    content: "\ed94";
}

.icmn-volume-mute2:before {
    content: "\ed95";
}

.icmn-volume-increase:before {
    content: "\ed96";
}

.icmn-volume-decrease:before {
    content: "\ed97";
}

.icmn-volume-high2:before {
    content: "\ed98";
}

.icmn-volume-medium2:before {
    content: "\ed99";
}

.icmn-volume-medium3:before {
    content: "\ed9a";
}

.icmn-volume-low2:before {
    content: "\ed9b";
}

.icmn-volume-mute3:before {
    content: "\ed9c";
}

.icmn-volume-increase2:before {
    content: "\ed9d";
}

.icmn-volume-decrease2:before {
    content: "\ed9e";
}

.icmn-volume-decrease3:before {
    content: "\ed9f";
}

.icmn-volume-5:before {
    content: "\eda0";
}

.icmn-volume-4:before {
    content: "\eda1";
}

.icmn-volume-3:before {
    content: "\eda2";
}

.icmn-volume-2:before {
    content: "\eda3";
}

.icmn-volume-1:before {
    content: "\eda4";
}

.icmn-volume-0:before {
    content: "\eda5";
}

.icmn-volume-mute4:before {
    content: "\eda6";
}

.icmn-volume-mute5:before {
    content: "\eda7";
}

.icmn-loop:before {
    content: "\eda8";
}

.icmn-loop2:before {
    content: "\eda9";
}

.icmn-loop3:before {
    content: "\edaa";
}

.icmn-infinite-square:before {
    content: "\edab";
}

.icmn-infinite:before {
    content: "\edac";
}

.icmn-infinite2:before {
    content: "\edad";
}

.icmn-loop4:before {
    content: "\edae";
}

.icmn-shuffle:before {
    content: "\edaf";
}

.icmn-shuffle2:before {
    content: "\edb0";
}

.icmn-wave:before {
    content: "\edb1";
}

.icmn-wave2:before {
    content: "\edb2";
}

.icmn-split:before {
    content: "\edb3";
}

.icmn-merge:before {
    content: "\edb4";
}

.icmn-first2:before {
    content: "\edb5";
}

.icmn-last2:before {
    content: "\edb6";
}

.icmn-arrow-up:before {
    content: "\edb7";
}

.icmn-arrow-up2:before {
    content: "\edb8";
}

.icmn-arrow-up3:before {
    content: "\edb9";
}

.icmn-arrow-up4:before {
    content: "\edba";
}

.icmn-arrow-right:before {
    content: "\edbb";
}

.icmn-arrow-right2:before {
    content: "\edbc";
}

.icmn-arrow-right3:before {
    content: "\edbd";
}

.icmn-arrow-right4:before {
    content: "\edbe";
}

.icmn-arrow-down:before {
    content: "\edbf";
}

.icmn-arrow-down2:before {
    content: "\edc0";
}

.icmn-arrow-down3:before {
    content: "\edc1";
}

.icmn-arrow-down4:before {
    content: "\edc2";
}

.icmn-arrow-left:before {
    content: "\edc3";
}

.icmn-arrow-left2:before {
    content: "\edc4";
}

.icmn-arrow-left3:before {
    content: "\edc5";
}

.icmn-arrow-left4:before {
    content: "\edc6";
}

.icmn-arrow-up5:before {
    content: "\edc7";
}

.icmn-arrow-right5:before {
    content: "\edc8";
}

.icmn-arrow-down5:before {
    content: "\edc9";
}

.icmn-arrow-left5:before {
    content: "\edca";
}

.icmn-arrow-up-left:before {
    content: "\edcb";
}

.icmn-arrow-up6:before {
    content: "\edcc";
}

.icmn-arrow-up-right:before {
    content: "\edcd";
}

.icmn-arrow-right6:before {
    content: "\edce";
}

.icmn-arrow-down-right:before {
    content: "\edcf";
}

.icmn-arrow-down6:before {
    content: "\edd0";
}

.icmn-arrow-down-left:before {
    content: "\edd1";
}

.icmn-arrow-left6:before {
    content: "\edd2";
}

.icmn-arrow-up-left2:before {
    content: "\edd3";
}

.icmn-arrow-up7:before {
    content: "\edd4";
}

.icmn-arrow-up-right2:before {
    content: "\edd5";
}

.icmn-arrow-right7:before {
    content: "\edd6";
}

.icmn-arrow-down-right2:before {
    content: "\edd7";
}

.icmn-arrow-down7:before {
    content: "\edd8";
}

.icmn-arrow-down-left2:before {
    content: "\edd9";
}

.icmn-arrow-left7:before {
    content: "\edda";
}

.icmn-arrow-up-left3:before {
    content: "\eddb";
}

.icmn-arrow-up8:before {
    content: "\eddc";
}

.icmn-arrow-up-right3:before {
    content: "\eddd";
}

.icmn-arrow-right8:before {
    content: "\edde";
}

.icmn-arrow-down-right3:before {
    content: "\eddf";
}

.icmn-arrow-down8:before {
    content: "\ede0";
}

.icmn-arrow-down-left3:before {
    content: "\ede1";
}

.icmn-arrow-left8:before {
    content: "\ede2";
}

.icmn-circle-up:before {
    content: "\ede3";
}

.icmn-circle-right:before {
    content: "\ede4";
}

.icmn-circle-down:before {
    content: "\ede5";
}

.icmn-circle-left:before {
    content: "\ede6";
}

.icmn-circle-up2:before {
    content: "\ede7";
}

.icmn-circle-right2:before {
    content: "\ede8";
}

.icmn-circle-down2:before {
    content: "\ede9";
}

.icmn-circle-left2:before {
    content: "\edea";
}

.icmn-circle-up-left:before {
    content: "\edeb";
}

.icmn-circle-up3:before {
    content: "\edec";
}

.icmn-circle-up-right:before {
    content: "\eded";
}

.icmn-circle-right3:before {
    content: "\edee";
}

.icmn-circle-down-right:before {
    content: "\edef";
}

.icmn-circle-down3:before {
    content: "\edf0";
}

.icmn-circle-down-left:before {
    content: "\edf1";
}

.icmn-circle-left3:before {
    content: "\edf2";
}

.icmn-circle-up-left2:before {
    content: "\edf3";
}

.icmn-circle-up4:before {
    content: "\edf4";
}

.icmn-circle-up-right2:before {
    content: "\edf5";
}

.icmn-circle-right4:before {
    content: "\edf6";
}

.icmn-circle-down-right2:before {
    content: "\edf7";
}

.icmn-circle-down4:before {
    content: "\edf8";
}

.icmn-circle-down-left2:before {
    content: "\edf9";
}

.icmn-circle-left4:before {
    content: "\edfa";
}

.icmn-arrow-resize:before {
    content: "\edfb";
}

.icmn-arrow-resize2:before {
    content: "\edfc";
}

.icmn-arrow-resize3:before {
    content: "\edfd";
}

.icmn-arrow-resize4:before {
    content: "\edfe";
}

.icmn-arrow-resize5:before {
    content: "\edff";
}

.icmn-arrow-resize6:before {
    content: "\ee00";
}

.icmn-arrow-resize7:before {
    content: "\ee01";
}

.icmn-arrow-resize8:before {
    content: "\ee02";
}

.icmn-square-up-left:before {
    content: "\ee03";
}

.icmn-square-up:before {
    content: "\ee04";
}

.icmn-square-up-right:before {
    content: "\ee05";
}

.icmn-square-right:before {
    content: "\ee06";
}

.icmn-square-down-right:before {
    content: "\ee07";
}

.icmn-square-down:before {
    content: "\ee08";
}

.icmn-square-down-left:before {
    content: "\ee09";
}

.icmn-square-left:before {
    content: "\ee0a";
}

.icmn-arrow-up9:before {
    content: "\ee0b";
}

.icmn-arrow-right9:before {
    content: "\ee0c";
}

.icmn-arrow-down9:before {
    content: "\ee0d";
}

.icmn-arrow-left9:before {
    content: "\ee0e";
}

.icmn-arrow-up10:before {
    content: "\ee0f";
}

.icmn-arrow-right10:before {
    content: "\ee10";
}

.icmn-arrow-down10:before {
    content: "\ee11";
}

.icmn-arrow-left10:before {
    content: "\ee12";
}

.icmn-arrow-up-left4:before {
    content: "\ee13";
}

.icmn-arrow-up11:before {
    content: "\ee14";
}

.icmn-arrow-up-right4:before {
    content: "\ee15";
}

.icmn-arrow-right11:before {
    content: "\ee16";
}

.icmn-arrow-down-right4:before {
    content: "\ee17";
}

.icmn-arrow-down11:before {
    content: "\ee18";
}

.icmn-arrow-down-left4:before {
    content: "\ee19";
}

.icmn-arrow-left11:before {
    content: "\ee1a";
}

.icmn-arrow-up-left5:before {
    content: "\ee1b";
}

.icmn-arrow-up12:before {
    content: "\ee1c";
}

.icmn-arrow-up-right5:before {
    content: "\ee1d";
}

.icmn-arrow-right12:before {
    content: "\ee1e";
}

.icmn-arrow-down-right5:before {
    content: "\ee1f";
}

.icmn-arrow-down12:before {
    content: "\ee20";
}

.icmn-arrow-down-left5:before {
    content: "\ee21";
}

.icmn-arrow-left12:before {
    content: "\ee22";
}

.icmn-arrow-up13:before {
    content: "\ee23";
}

.icmn-arrow-right13:before {
    content: "\ee24";
}

.icmn-arrow-down13:before {
    content: "\ee25";
}

.icmn-arrow-left13:before {
    content: "\ee26";
}

.icmn-arrow-up14:before {
    content: "\ee27";
}

.icmn-arrow-right14:before {
    content: "\ee28";
}

.icmn-arrow-down14:before {
    content: "\ee29";
}

.icmn-arrow-left14:before {
    content: "\ee2a";
}

.icmn-circle-up5:before {
    content: "\ee2b";
}

.icmn-circle-right5:before {
    content: "\ee2c";
}

.icmn-circle-down5:before {
    content: "\ee2d";
}

.icmn-circle-left5:before {
    content: "\ee2e";
}

.icmn-circle-up6:before {
    content: "\ee2f";
}

.icmn-circle-right6:before {
    content: "\ee30";
}

.icmn-circle-down6:before {
    content: "\ee31";
}

.icmn-circle-left6:before {
    content: "\ee32";
}

.icmn-arrow-up15:before {
    content: "\ee33";
}

.icmn-arrow-right15:before {
    content: "\ee34";
}

.icmn-arrow-down15:before {
    content: "\ee35";
}

.icmn-arrow-left15:before {
    content: "\ee36";
}

.icmn-arrow-up16:before {
    content: "\ee37";
}

.icmn-arrow-right16:before {
    content: "\ee38";
}

.icmn-arrow-down16:before {
    content: "\ee39";
}

.icmn-arrow-left16:before {
    content: "\ee3a";
}

.icmn-menu-open:before {
    content: "\ee3b";
}

.icmn-menu-open2:before {
    content: "\ee3c";
}

.icmn-menu-close:before {
    content: "\ee3d";
}

.icmn-menu-close2:before {
    content: "\ee3e";
}

.icmn-enter4:before {
    content: "\ee3f";
}

.icmn-enter5:before {
    content: "\ee40";
}

.icmn-esc:before {
    content: "\ee41";
}

.icmn-enter6:before {
    content: "\ee42";
}

.icmn-backspace:before {
    content: "\ee43";
}

.icmn-backspace2:before {
    content: "\ee44";
}

.icmn-tab:before {
    content: "\ee45";
}

.icmn-transmission:before {
    content: "\ee46";
}

.icmn-transmission2:before {
    content: "\ee47";
}

.icmn-sort:before {
    content: "\ee48";
}

.icmn-sort2:before {
    content: "\ee49";
}

.icmn-move-up:before {
    content: "\ee4a";
}

.icmn-move-down:before {
    content: "\ee4b";
}

.icmn-sort-alpha-asc:before {
    content: "\ee4c";
}

.icmn-sort-alpha-desc:before {
    content: "\ee4d";
}

.icmn-sort-numeric-asc:before {
    content: "\ee4e";
}

.icmn-sort-numberic-desc:before {
    content: "\ee4f";
}

.icmn-sort-amount-asc:before {
    content: "\ee50";
}

.icmn-sort-amount-desc:before {
    content: "\ee51";
}

.icmn-sort-time-asc:before {
    content: "\ee52";
}

.icmn-sort-time-desc:before {
    content: "\ee53";
}

.icmn-battery-6:before {
    content: "\ee54";
}

.icmn-battery-5:before {
    content: "\ee55";
}

.icmn-battery-4:before {
    content: "\ee56";
}

.icmn-battery-3:before {
    content: "\ee57";
}

.icmn-battery-2:before {
    content: "\ee58";
}

.icmn-battery-1:before {
    content: "\ee59";
}

.icmn-battery-0:before {
    content: "\ee5a";
}

.icmn-battery-charging:before {
    content: "\ee5b";
}

.icmn-key-keyboard:before {
    content: "\ee5c";
}

.icmn-key-A:before {
    content: "\ee5d";
}

.icmn-key-up:before {
    content: "\ee5e";
}

.icmn-key-right:before {
    content: "\ee5f";
}

.icmn-key-down:before {
    content: "\ee60";
}

.icmn-key-left:before {
    content: "\ee61";
}

.icmn-command:before {
    content: "\ee62";
}

.icmn-shift:before {
    content: "\ee63";
}

.icmn-ctrl:before {
    content: "\ee64";
}

.icmn-opt:before {
    content: "\ee65";
}

.icmn-checkbox-checked:before {
    content: "\ee66";
}

.icmn-checkbox-unchecked:before {
    content: "\ee67";
}

.icmn-checkbox-partial:before {
    content: "\ee68";
}

.icmn-square:before {
    content: "\ee69";
}

.icmn-triangle:before {
    content: "\ee6a";
}

.icmn-triangle2:before {
    content: "\ee6b";
}

.icmn-diamond3:before {
    content: "\ee6c";
}

.icmn-diamond4:before {
    content: "\ee6d";
}

.icmn-checkbox-checked2:before {
    content: "\ee6e";
}

.icmn-checkbox-unchecked2:before {
    content: "\ee6f";
}

.icmn-checkbox-partial2:before {
    content: "\ee70";
}

.icmn-radio-checked:before {
    content: "\ee71";
}

.icmn-radio-checked2:before {
    content: "\ee72";
}

.icmn-radio-unchecked:before {
    content: "\ee73";
}

.icmn-toggle-on:before {
    content: "\ee74";
}

.icmn-toggle-off:before {
    content: "\ee75";
}

.icmn-checkmark-circle:before {
    content: "\ee76";
}

.icmn-circle:before {
    content: "\ee77";
}

.icmn-circle2:before {
    content: "\ee78";
}

.icmn-circles:before {
    content: "\ee79";
}

.icmn-circles2:before {
    content: "\ee7a";
}

.icmn-crop:before {
    content: "\ee7b";
}

.icmn-crop2:before {
    content: "\ee7c";
}

.icmn-make-group:before {
    content: "\ee7d";
}

.icmn-ungroup:before {
    content: "\ee7e";
}

.icmn-vector:before {
    content: "\ee7f";
}

.icmn-vector2:before {
    content: "\ee80";
}

.icmn-rulers:before {
    content: "\ee81";
}

.icmn-ruler:before {
    content: "\ee82";
}

.icmn-pencil-ruler:before {
    content: "\ee83";
}

.icmn-scissors:before {
    content: "\ee84";
}

.icmn-scissors2:before {
    content: "\ee85";
}

.icmn-scissors3:before {
    content: "\ee86";
}

.icmn-haircut:before {
    content: "\ee87";
}

.icmn-comb:before {
    content: "\ee88";
}

.icmn-filter:before {
    content: "\ee89";
}

.icmn-filter2:before {
    content: "\ee8a";
}

.icmn-filter3:before {
    content: "\ee8b";
}

.icmn-filter4:before {
    content: "\ee8c";
}

.icmn-font:before {
    content: "\ee8d";
}

.icmn-ampersand:before {
    content: "\ee8e";
}

.icmn-ligature:before {
    content: "\ee8f";
}

.icmn-ligature2:before {
    content: "\ee90";
}

.icmn-font-size:before {
    content: "\ee91";
}

.icmn-typography:before {
    content: "\ee92";
}

.icmn-text-height:before {
    content: "\ee93";
}

.icmn-text-width:before {
    content: "\ee94";
}

.icmn-height2:before {
    content: "\ee95";
}

.icmn-width:before {
    content: "\ee96";
}

.icmn-bold:before {
    content: "\ee97";
}

.icmn-underline:before {
    content: "\ee98";
}

.icmn-italic:before {
    content: "\ee99";
}

.icmn-strikethrough:before {
    content: "\ee9a";
}

.icmn-strikethrough2:before {
    content: "\ee9b";
}

.icmn-font-size2:before {
    content: "\ee9c";
}

.icmn-bold2:before {
    content: "\ee9d";
}

.icmn-underline2:before {
    content: "\ee9e";
}

.icmn-italic2:before {
    content: "\ee9f";
}

.icmn-strikethrough3:before {
    content: "\eea0";
}

.icmn-omega:before {
    content: "\eea1";
}

.icmn-sigma:before {
    content: "\eea2";
}

.icmn-nbsp:before {
    content: "\eea3";
}

.icmn-page-break:before {
    content: "\eea4";
}

.icmn-page-break2:before {
    content: "\eea5";
}

.icmn-superscript:before {
    content: "\eea6";
}

.icmn-subscript:before {
    content: "\eea7";
}

.icmn-superscript2:before {
    content: "\eea8";
}

.icmn-subscript2:before {
    content: "\eea9";
}

.icmn-text-color:before {
    content: "\eeaa";
}

.icmn-highlight:before {
    content: "\eeab";
}

.icmn-pagebreak:before {
    content: "\eeac";
}

.icmn-clear-formatting:before {
    content: "\eead";
}

.icmn-table:before {
    content: "\eeae";
}

.icmn-table2:before {
    content: "\eeaf";
}

.icmn-insert-template:before {
    content: "\eeb0";
}

.icmn-pilcrow:before {
    content: "\eeb1";
}

.icmn-ltr:before {
    content: "\eeb2";
}

.icmn-rtl:before {
    content: "\eeb3";
}

.icmn-ltr2:before {
    content: "\eeb4";
}

.icmn-rtl2:before {
    content: "\eeb5";
}

.icmn-section:before {
    content: "\eeb6";
}

.icmn-paragraph-left:before {
    content: "\eeb7";
}

.icmn-paragraph-center:before {
    content: "\eeb8";
}

.icmn-paragraph-right:before {
    content: "\eeb9";
}

.icmn-paragraph-justify:before {
    content: "\eeba";
}

.icmn-paragraph-left2:before {
    content: "\eebb";
}

.icmn-paragraph-center2:before {
    content: "\eebc";
}

.icmn-paragraph-right2:before {
    content: "\eebd";
}

.icmn-paragraph-justify2:before {
    content: "\eebe";
}

.icmn-indent-increase:before {
    content: "\eebf";
}

.icmn-indent-decrease:before {
    content: "\eec0";
}

.icmn-paragraph-left3:before {
    content: "\eec1";
}

.icmn-paragraph-center3:before {
    content: "\eec2";
}

.icmn-paragraph-right3:before {
    content: "\eec3";
}

.icmn-paragraph-justify3:before {
    content: "\eec4";
}

.icmn-indent-increase2:before {
    content: "\eec5";
}

.icmn-indent-decrease2:before {
    content: "\eec6";
}

.icmn-share:before {
    content: "\eec7";
}

.icmn-share2:before {
    content: "\eec8";
}

.icmn-new-tab:before {
    content: "\eec9";
}

.icmn-new-tab2:before {
    content: "\eeca";
}

.icmn-popout:before {
    content: "\eecb";
}

.icmn-embed:before {
    content: "\eecc";
}

.icmn-embed2:before {
    content: "\eecd";
}

.icmn-markup:before {
    content: "\eece";
}

.icmn-regexp:before {
    content: "\eecf";
}

.icmn-regexp2:before {
    content: "\eed0";
}

.icmn-code:before {
    content: "\eed1";
}

.icmn-circle-css:before {
    content: "\eed2";
}

.icmn-circle-code:before {
    content: "\eed3";
}

.icmn-terminal:before {
    content: "\eed4";
}

.icmn-unicode:before {
    content: "\eed5";
}

.icmn-seven-segment-0:before {
    content: "\eed6";
}

.icmn-seven-segment-1:before {
    content: "\eed7";
}

.icmn-seven-segment-2:before {
    content: "\eed8";
}

.icmn-seven-segment-3:before {
    content: "\eed9";
}

.icmn-seven-segment-4:before {
    content: "\eeda";
}

.icmn-seven-segment-5:before {
    content: "\eedb";
}

.icmn-seven-segment-6:before {
    content: "\eedc";
}

.icmn-seven-segment-7:before {
    content: "\eedd";
}

.icmn-seven-segment-8:before {
    content: "\eede";
}

.icmn-seven-segment-9:before {
    content: "\eedf";
}

.icmn-share3:before {
    content: "\eee0";
}

.icmn-share4:before {
    content: "\eee1";
}

.icmn-mail:before {
    content: "\eee2";
}

.icmn-mail2:before {
    content: "\eee3";
}

.icmn-mail3:before {
    content: "\eee4";
}

.icmn-mail4:before {
    content: "\eee5";
}

.icmn-amazon:before {
    content: "\eee6";
}

.icmn-google:before {
    content: "\eee7";
}

.icmn-google2:before {
    content: "\eee8";
}

.icmn-google3:before {
    content: "\eee9";
}

.icmn-google-plus:before {
    content: "\eeea";
}

.icmn-google-plus2:before {
    content: "\eeeb";
}

.icmn-google-plus3:before {
    content: "\eeec";
}

.icmn-hangouts:before {
    content: "\eeed";
}

.icmn-google-drive:before {
    content: "\eeee";
}

.icmn-facebook:before {
    content: "\eeef";
}

.icmn-facebook2:before {
    content: "\eef0";
}

.icmn-instagram:before {
    content: "\eef1";
}

.icmn-whatsapp:before {
    content: "\eef2";
}

.icmn-spotify:before {
    content: "\eef3";
}

.icmn-telegram:before {
    content: "\eef4";
}

.icmn-twitter:before {
    content: "\eef5";
}

.icmn-vine:before {
    content: "\eef6";
}

.icmn-vk:before {
    content: "\eef7";
}

.icmn-renren:before {
    content: "\eef8";
}

.icmn-sina-weibo:before {
    content: "\eef9";
}

.icmn-rss:before {
    content: "\eefa";
}

.icmn-rss2:before {
    content: "\eefb";
}

.icmn-youtube:before {
    content: "\eefc";
}

.icmn-youtube2:before {
    content: "\eefd";
}

.icmn-twitch:before {
    content: "\eefe";
}

.icmn-vimeo:before {
    content: "\eeff";
}

.icmn-vimeo2:before {
    content: "\ef00";
}

.icmn-lanyrd:before {
    content: "\ef01";
}

.icmn-flickr:before {
    content: "\ef02";
}

.icmn-flickr2:before {
    content: "\ef03";
}

.icmn-flickr3:before {
    content: "\ef04";
}

.icmn-flickr4:before {
    content: "\ef05";
}

.icmn-dribbble:before {
    content: "\ef06";
}

.icmn-behance:before {
    content: "\ef07";
}

.icmn-behance2:before {
    content: "\ef08";
}

.icmn-deviantart:before {
    content: "\ef09";
}

.icmn-500px:before {
    content: "\ef0a";
}

.icmn-steam:before {
    content: "\ef0b";
}

.icmn-steam2:before {
    content: "\ef0c";
}

.icmn-dropbox:before {
    content: "\ef0d";
}

.icmn-onedrive:before {
    content: "\ef0e";
}

.icmn-github:before {
    content: "\ef0f";
}

.icmn-npm:before {
    content: "\ef10";
}

.icmn-basecamp:before {
    content: "\ef11";
}

.icmn-trello:before {
    content: "\ef12";
}

.icmn-wordpress:before {
    content: "\ef13";
}

.icmn-joomla:before {
    content: "\ef14";
}

.icmn-ello:before {
    content: "\ef15";
}

.icmn-blogger:before {
    content: "\ef16";
}

.icmn-blogger2:before {
    content: "\ef17";
}

.icmn-tumblr:before {
    content: "\ef18";
}

.icmn-tumblr2:before {
    content: "\ef19";
}

.icmn-yahoo:before {
    content: "\ef1a";
}

.icmn-yahoo2:before {
    content: "\ef1b";
}

.icmn-tux:before {
    content: "\ef1c";
}

.icmn-appleinc:before {
    content: "\ef1d";
}

.icmn-finder:before {
    content: "\ef1e";
}

.icmn-android:before {
    content: "\ef1f";
}

.icmn-windows:before {
    content: "\ef20";
}

.icmn-windows8:before {
    content: "\ef21";
}

.icmn-soundcloud:before {
    content: "\ef22";
}

.icmn-soundcloud2:before {
    content: "\ef23";
}

.icmn-skype:before {
    content: "\ef24";
}

.icmn-reddit:before {
    content: "\ef25";
}

.icmn-hackernews:before {
    content: "\ef26";
}

.icmn-wikipedia:before {
    content: "\ef27";
}

.icmn-linkedin:before {
    content: "\ef28";
}

.icmn-linkedin2:before {
    content: "\ef29";
}

.icmn-lastfm:before {
    content: "\ef2a";
}

.icmn-lastfm2:before {
    content: "\ef2b";
}

.icmn-delicious:before {
    content: "\ef2c";
}

.icmn-stumbleupon:before {
    content: "\ef2d";
}

.icmn-stumbleupon2:before {
    content: "\ef2e";
}

.icmn-stackoverflow:before {
    content: "\ef2f";
}

.icmn-pinterest:before {
    content: "\ef30";
}

.icmn-pinterest2:before {
    content: "\ef31";
}

.icmn-xing:before {
    content: "\ef32";
}

.icmn-xing2:before {
    content: "\ef33";
}

.icmn-flattr:before {
    content: "\ef34";
}

.icmn-foursquare:before {
    content: "\ef35";
}

.icmn-yelp:before {
    content: "\ef36";
}

.icmn-paypal:before {
    content: "\ef37";
}

.icmn-chrome:before {
    content: "\ef38";
}

.icmn-firefox:before {
    content: "\ef39";
}

.icmn-IE:before {
    content: "\ef3a";
}

.icmn-edge:before {
    content: "\ef3b";
}

.icmn-safari:before {
    content: "\ef3c";
}

.icmn-opera:before {
    content: "\ef3d";
}

.icmn-file-pdf:before {
    content: "\ef3e";
}

.icmn-file-openoffice:before {
    content: "\ef3f";
}

.icmn-file-word:before {
    content: "\ef40";
}

.icmn-file-excel:before {
    content: "\ef41";
}

.icmn-libreoffice:before {
    content: "\ef42";
}

.icmn-html-five:before {
    content: "\ef43";
}

.icmn-html-five2:before {
    content: "\ef44";
}

.icmn-css3:before {
    content: "\ef45";
}

.icmn-git:before {
    content: "\ef46";
}

.icmn-codepen:before {
    content: "\ef47";
}

.icmn-svg:before {
    content: "\ef48";
}

.icmn-IcoMoon:before {
    content: "\ef49";
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.sr-only-focusable {
    &:active, &:focus {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        clip: auto;
    }
}
