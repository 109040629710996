/*
 * EXAMPLE BLOCK
 */
.example-block {
    padding: 15px 15px;
    border: 1px solid #eceeef;
}

.example-block:before {
    content: 'EXAMPLE';
    display: block;
    margin-bottom: 15px;
    color: #827ca1;
}

/* BACKGROUNDS */
.background-transparent {
    background-color: transparent !important;
}

.background-black {
    background-color: #514d6a !important;
}

.background-white {
    background-color: #ffffff !important;
}

.background-default {
    background-color: #acb7bf !important;
}

.background-primary {
    background-color: #5ac4f1 !important;
}

.background-secondary {
    background-color: #6a7a84 !important;
}

.background-success {
    background-color: #46be8a !important;
}

.background-info {
    background-color: #0190da !important;
}

.background-warning {
    background-color: #ffa300 !important;
}

.background-danger {
    background-color: #fb434a !important;
}

.background-light-green {
    background-color: #8dbc3b !important;
}

.background-dark-green {
    background-color: #3d7700 !important;
}

.background-light-grey {
    background-color: #eceeef !important;
}

/* BORDERS */
.border-none {
    border: 0 !important;
}

.border-1px {
    border: 1px solid #acb7bf !important;
}

.border-top-1px {
    border-top: 1px solid #acb7bf !important;
}

.border-bottom-1px {
    border-top: 1px solid #acb7bf !important;
}

.border-left-1px {
    border-left: 1px solid #acb7bf !important;
}

.border-right-1px {
    border-right: 1px solid #acb7bf !important;
}

.border-dashed {
    border-style: dashed !important;
}

.border-dotted {
    border-style: dotted !important;
}

.border-solid {
    border-style: solid !important;
}

.border-color-default {
    border-color: #acb7bf !important;
}

.border-color-light-grey {
    border-color: #eceeef !important;
}

.border-color-white {
    border-color: #ffffff !important;
}

/* COLORS */
.color-black {
    color: #514d6a !important;
}

.color-white {
    color: #ffffff !important;
}

.color-default {
    color: #acb7bf !important;
}

.color-disabled {
    color: #b0b0b0 !important;
}

.color-primary {
    color: #5ac4f1 !important;
}

.color-secondary {
    color: #6a7a84 !important;
}

.color-success {
    color: #39e500 !important;
}

.color-info {
    color: #0190da !important;
}

.color-warning {
    color: #ffa300 !important;
}

.color-danger {
    color: #fb434a !important;
}

.color-apple-logo {
    color: #A8B1B8 !important;
}

.color-android-logo {
    color: #A4C639 !important;
}

.color-transparent {
    color: transparent !important;
}

/* LINKS */
.link-underlined {
    border-bottom: 1px solid rgba(187, 184, 203, 0.5);
}

.link-underlined:hover {
    border-bottom-color: rgba(0, 136, 255, 0.4);
}

.link-blue {
    color: #0088ff;
}

.link-blue:hover {
    color: #0072d6;
}

.link-blue.link-underlined {
    border-bottom: 1px solid rgba(0, 136, 255, 0.3);
}

.link-blue.link-underlined:hover {
    border-bottom-color: rgba(0, 136, 255, 0.5);
}

/* HEIGHT */
.height-50 {
    height: 50px !important;
}

.height-100 {
    height: 100px !important;
}

.height-120 {
    height: 120px !important;
}

.height-150 {
    height: 150px !important;
}

.height-200 {
    height: 200px !important;
}

.height-250 {
    height: 250px !important;
}

.height-300 {
    height: 300px !important;
}

.height-350 {
    height: 350px !important;
}

.height-400 {
    height: 400px !important;
}

.height-450 {
    height: 450px !important;
}

.height-500 {
    height: 500px !important;
}

.height-700 {
    height: 700px !important;
}

/* MIN HEIGHT */
.min-height-50 {
    min-height: 50px !important;
}

.min-height-100 {
    min-height: 100px !important;
}

.min-height-150 {
    min-height: 150px !important;
}

.min-height-200 {
    min-height: 200px !important;
}

.min-height-250 {
    min-height: 250px !important;
}

.min-height-300 {
    min-height: 300px !important;
}

.min-height-350 {
    min-height: 350px !important;
}

.min-height-400 {
    min-height: 400px !important;
}

.min-height-450 {
    min-height: 450px !important;
}

.min-height-500 {
    min-height: 500px !important;
}

/* MAX HEIGHT */
.max-height-50 {
    max-height: 50px !important;
}

.max-height-100 {
    max-height: 100px !important;
}

.max-height-150 {
    max-height: 150px !important;
}

.max-height-200 {
    max-height: 200px !important;
}

.max-height-250 {
    max-height: 250px !important;
}

.max-height-300 {
    max-height: 300px !important;
}

.max-height-350 {
    max-height: 350px !important;
}

.max-height-400 {
    max-height: 400px !important;
}

.max-height-450 {
    max-height: 450px !important;
}

.max-height-500 {
    max-height: 500px !important;
}

/* WIDTH */
.width-full {
    width: 100%;
}

.width-auto {
    width: auto;
}

.width-10 {
    width: 10px !important;
}

.width-50 {
    width: 50px !important;
}

.width-100 {
    width: 100px !important;
}

.width-150 {
    width: 150px !important;
}

.width-200 {
    width: 200px !important;
}

.width-250 {
    width: 250px !important;
}

.width-300 {
    width: 300px !important;
}

.width-350 {
    width: 350px !important;
}

.width-400 {
    width: 400px !important;
}

.width-450 {
    width: 450px !important;
}

.width-500 {
    width: 500px !important;
}

/* MAX WIDTH */
.max-width-50 {
    max-width: 50px !important;
}

.max-width-100 {
    max-width: 100px !important;
}

.max-width-150 {
    max-width: 150px !important;
}

.max-width-200 {
    max-width: 200px !important;
}

.max-width-250 {
    max-width: 250px !important;
}

.max-width-300 {
    max-width: 300px !important;
}

.max-width-350 {
    max-width: 350px !important;
}

.max-width-400 {
    max-width: 400px !important;
}

.max-width-450 {
    max-width: 450px !important;
}

.max-width-500 {
    max-width: 500px !important;
}

/* OPACITY */
.opacity-0 {
    opacity: .0;
}

.opacity-01 {
    opacity: .1;
}

.opacity-02 {
    opacity: .2;
}

.opacity-03 {
    opacity: .3;
}

.opacity-04 {
    opacity: .4;
}

.opacity-05 {
    opacity: .5;
}

.opacity-06 {
    opacity: .6;
}

.opacity-07 {
    opacity: .7;
}

.opacity-08 {
    opacity: .8;
}

.opacity-09 {
    opacity: .9;
}

.opacity-10 {
    opacity: 1;
}

/* TEXT ALIGN */
.text-top {
    vertical-align: top !important;
}

.text-middle {
    vertical-align: middle !important;
}

.text-bottom {
    vertical-align: bottom !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-height {
    height: 22px;
}

/* DISPLAY */
.display-block {
    display: block !important;
}

.display-flex {
    display: flex !important;
}

.align-center {
    align-items: center !important;
}

.display-flex-column {
    display: flex;
    flex-direction: column;
}

.display-flex-row {
    display: flex;
    column-gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;

    &.align-center {
        align-items: center;
    }

    &.space-between {
        justify-content: space-between;
    }

    .float-left {
        margin-right: auto;
    }

    .float-right {
        margin-left: auto;
    }
}

.display-inline {
    display: inline !important;
}

.display-inline-block {
    display: inline-block !important;
}

.display-none {
    display: none !important;
}

/* VERTICAL ALIGN */
.vertical-align {
    font-size: 0;
}

.vertical-align:before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: '';
}

.vertical-align-bottom, .vertical-align-middle {
    display: inline-block;
    max-width: 100%;
    font-size: 14px;
}

.vertical-align-middle {
    vertical-align: middle;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

/* PADDING */
.padding-0 {
    padding: 0 !important;
}

.padding-3 {
    padding: 3px !important;
}

.padding-5 {
    padding: 5px !important;
}

.padding-10 {
    padding: 10px !important;
}

.padding-15 {
    padding: 15px !important;
}

.padding-20 {
    padding: 20px !important;
}

.padding-25 {
    padding: 25px !important;
}

.padding-30 {
    padding: 30px !important;
}

.padding-35 {
    padding: 35px !important;
}

.padding-40 {
    padding: 40px !important;
}

.padding-45 {
    padding: 45px !important;
}

.padding-50 {
    padding: 50px !important;
}

.padding-right-0 {
    padding-right: 0 !important;
}

.padding-right-3 {
    padding-right: 3px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-right-10 {
    padding-right: 10px !important;
}

.padding-right-15 {
    padding-right: 15px !important;
}

.padding-right-20 {
    padding-right: 20px !important;
}

.padding-right-25 {
    padding-right: 25px !important;
}

.padding-right-30 {
    padding-right: 30px !important;
}

.padding-right-35 {
    padding-right: 35px !important;
}

.padding-right-40 {
    padding-right: 40px !important;
}

.padding-right-45 {
    padding-right: 45px !important;
}

.padding-right-50 {
    padding-right: 50px !important;
}

.padding-left-0 {
    padding-left: 0 !important;
}

.padding-left-3 {
    padding-left: 3px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.padding-left-15 {
    padding-left: 15px !important;
}

.padding-left-20 {
    padding-left: 20px !important;
}

.padding-left-25 {
    padding-left: 25px !important;
}

.padding-left-30 {
    padding-left: 30px !important;
}

.padding-left-35 {
    padding-left: 35px !important;
}

.padding-left-40 {
    padding-left: 40px !important;
}

.padding-left-45 {
    padding-left: 45px !important;
}

.padding-left-50 {
    padding-left: 50px !important;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.padding-top-3 {
    padding-top: 3px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-top-10 {
    padding-top: 10px !important;
}

.padding-top-15 {
    padding-top: 15px !important;
}

.padding-top-20 {
    padding-top: 20px !important;
}

.padding-top-25 {
    padding-top: 25px !important;
}

.padding-top-30 {
    padding-top: 30px !important;
}

.padding-top-35 {
    padding-top: 35px !important;
}

.padding-top-40 {
    padding-top: 40px !important;
}

.padding-top-45 {
    padding-top: 45px !important;
}

.padding-top-50 {
    padding-top: 50px !important;
}

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.padding-bottom-3 {
    padding-bottom: 3px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.padding-bottom-10 {
    padding-bottom: 10px !important;
}

.padding-bottom-15 {
    padding-bottom: 15px !important;
}

.padding-bottom-20 {
    padding-bottom: 20px !important;
}

.padding-bottom-25 {
    padding-bottom: 25px !important;
}

.padding-bottom-30 {
    padding-bottom: 30px !important;
}

.padding-bottom-35 {
    padding-bottom: 35px !important;
}

.padding-bottom-40 {
    padding-bottom: 40px !important;
}

.padding-bottom-45 {
    padding-bottom: 45px !important;
}

.padding-bottom-50 {
    padding-bottom: 50px !important;
}

.padding-horizontal-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.padding-horizontal-3 {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.padding-horizontal-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.padding-horizontal-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.padding-horizontal-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.padding-horizontal-16 {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.padding-horizontal-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.padding-horizontal-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.padding-horizontal-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.padding-horizontal-35 {
    padding-left: 35px !important;
    padding-right: 35px !important;
}

.padding-horizontal-40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.padding-horizontal-45 {
    padding-left: 45px !important;
    padding-right: 45px !important;
}

.padding-horizontal-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.padding-vertical-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.padding-vertical-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.padding-vertical-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.padding-vertical-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.padding-vertical-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.padding-vertical-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.padding-vertical-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.padding-vertical-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
}

.padding-vertical-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.padding-vertical-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
}

.padding-vertical-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.padding-vertical-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
}

.padding-vertical-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

/* MARGIN */
.margin-inline {
    margin: 0 10px 10px 0 !important;
}

.margin-auto {
    margin-left: auto;
    margin-right: auto;
}

.margin-0 {
    margin: 0 !important;
}

.margin-3 {
    margin: 3px !important;
}

.margin-5 {
    margin: 5px !important;
}

.margin-10 {
    margin: 10px !important;
}

.margin-15 {
    margin: 15px !important;
}

.margin-20 {
    margin: 20px !important;
}

.margin-25 {
    margin: 25px !important;
}

.margin-30 {
    margin: 30px !important;
}

.margin-35 {
    margin: 35px !important;
}

.margin-40 {
    margin: 40px !important;
}

.margin-45 {
    margin: 45px !important;
}

.margin-50 {
    margin: 50px !important;
}

.margin-right-auto {
    margin-right: auto !important;
}

.margin-right-0 {
    margin-right: 0 !important;
}

.margin-right-3 {
    margin-right: 3px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-right-10 {
    margin-right: 10px !important;
}

.margin-right-15 {
    margin-right: 15px !important;
}

.margin-right-20 {
    margin-right: 20px !important;
}

.margin-right-25 {
    margin-right: 25px !important;
}

.margin-right-30 {
    margin-right: 30px !important;
}

.margin-right-35 {
    margin-right: 35px !important;
}

.margin-right-40 {
    margin-right: 40px !important;
}

.margin-right-45 {
    margin-right: 45px !important;
}

.margin-right-50 {
    margin-right: 50px !important;
}

.margin-left-auto {
    margin-left: auto !important;
}

.margin-left-0 {
    margin-left: 0 !important;
}

.margin-left-3 {
    margin-left: 3px !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.margin-left-20 {
    margin-left: 20px !important;
}

.margin-left-25 {
    margin-left: 25px !important;
}

.margin-left-30 {
    margin-left: 30px !important;
}

.margin-left-35 {
    margin-left: 35px !important;
}

.margin-left-40 {
    margin-left: 40px !important;
}

.margin-left-45 {
    margin-left: 45px !important;
}

.margin-left-50 {
    margin-left: 50px !important;
}

.margin-top-0 {
    margin-top: 0 !important;
}

.margin-top-3 {
    margin-top: 3px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-35 {
    margin-top: 35px !important;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.margin-top-45 {
    margin-top: 45px !important;
}

.margin-top-50 {
    margin-top: 50px !important;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.margin-bottom-3 {
    margin-bottom: 3px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.margin-bottom-35 {
    margin-bottom: 35px !important;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}

.margin-bottom-45 {
    margin-bottom: 45px !important;
}

.margin-bottom-50 {
    margin-bottom: 50px !important;
}

.margin-horizontal-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.margin-horizontal-3 {
    margin-left: 3px !important;
    margin-right: 3px !important;
}

.margin-horizontal-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.margin-horizontal-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.margin-horizontal-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.margin-horizontal-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.margin-horizontal-25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
}

.margin-horizontal-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.margin-horizontal-35 {
    margin-left: 35px !important;
    margin-right: 35px !important;
}

.margin-horizontal-40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
}

.margin-horizontal-45 {
    margin-left: 45px !important;
    margin-right: 45px !important;
}

.margin-horizontal-50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
}

.margin-vertical-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.margin-vertical-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

.margin-vertical-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.margin-vertical-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.margin-vertical-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.margin-vertical-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.margin-vertical-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
}

.margin-vertical-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.margin-vertical-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
}

.margin-vertical-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.margin-vertical-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
}

.margin-vertical-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

/* WELL */
.well {
    min-height: 20px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f2f4f8;
    border: 1px solid #f9fafb;
}

.well.well-sm {
    padding: 12px;
}

.well.well-lg {
    padding: 28px;
}

.dl-horizontal {
    margin: 0;
}

.dl-horizontal dt {
    float: left;
    width: 160px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dl-horizontal dd {
    margin-left: 180px;
}

/* LISTS */
.list-icons {
    padding-left: 10px;
    margin-left: 0;
    list-style: none;
}

.list-icons .list-icon {
    width: 1em;
    margin: 0 6px 0 0;
}

.list-inline {
    margin-left: -5px;
}

.list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

/* DONUTS */
.donut {
    display: inline-block;
    width: 16px;
    height: 16px;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    border: 4px solid #acb7bf;
    position: relative;
    top: 2px;
    margin-right: 4px;
}

.donut.donut-default {
    border-color: #acb7bf;
}

.donut.donut-primary {
    border-color: #5ac4f1;
}

.donut.donut-secondary {
    border-color: #6a7a84;
}

.donut.donut-success {
    border-color: #46be8a;
}

.donut.donut-warning {
    border-color: #ffa300;
}

.donut.donut-danger {
    border-color: #fb434a;
}

.donut.donut-info {
    border-color: #0190da;
}

.donut.donut-yellow {
    border-color: yellow;
}

/* BLOCK-QUOTES */
blockquote {
    background: #f2f4f8;
    padding: 30px;
    border-left: 3px solid #dfe4ed;
}

blockquote.blockquote-right {
    text-align: right;
    border-left: none;
    border-right: 3px solid #dfe4ed;
}

blockquote .blockquote-footer {
    color: #d2d9e5;
}

/* SPINNER */
.util-spin {
    -webkit-animation: fa-spin 1s infinite linear;
    -o-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.util-spin-delayed {
    -webkit-animation: fa-spin-delayed 4s infinite ease-in-out;
    -o-animation: fa-spin-delayed 4s infinite ease-in-out;
    animation: fa-spin-delayed 4s infinite ease-in-out;
}

.util-spin-delayed-pseudo:before,
.util-spin-delayed-pseudo:after {
    display: inline-block;
    -webkit-animation: fa-spin-delayed 3s infinite ease-in-out;
    -o-animation: fa-spin-delayed 3s infinite ease-in-out;
    animation: fa-spin-delayed 3s infinite ease-in-out;
}

@-webkit-keyframes fa-spin-delayed {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
