/*
 * BASIC INPUTS
 */

.form-actions {
    border-top: 1px solid #7e8e97;
    padding-top: 20px;
    margin: 20px 0;
}

input {
    &[type=text], &[type=password], &[type=email] {
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
        border-radius: 0;
    }
}

textarea {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    border-radius: 0;
}

input {
    &[type=text]:focus, &[type=password]:focus, &[type=email]:focus {
        outline: none;
    }
}

textarea:focus {
    outline: none;
}

.form-control {
    background-color: inherit !important;
    border-color: #7e8e97 !important;
    border-bottom: 1px solid;
    border-left: none;
    border-radius: 0 !important;
    border-right: none;
    border-top: none;
    display: inline;
    font-family: inherit;
    padding: 5px;

    &.form-control-rounded {
        -webkit-border-radius: 500px;
        border-radius: 500px;
    }

    &:focus {
        border-bottom: 1px solid;
        border-color: #5ac4f1 !important;
        outline: none;
    }

    &.input-sm {
        padding: 4px 8px;
    }

    &:disabled, &[readonly] {
        border-bottom: none;
    }
}

.input-group-addon {
    background-color: #f2f4f8;
    border-radius: 0 !important;
    border-color: inherit !important;
    border-left: none;
    border-top: none;
    border-right: none;
}

a.input-group-addon {
    cursor: pointer;
}

span.input-group-addon {
    outline: none !important;
}

.form-control-label {
    font-weight: bold;
    margin-bottom: 0;
    padding: 7px 5px 7px 0;
}

.form-control-static {
    min-height: 39px;
}

.form-group {
    &.has-danger .form-control {
        border-color: #fb434a;
    }

    &.has-success .form-control {
        border-color: #46be8a;
    }

    &.has-warning .form-control {
        border-color: #ffa300;
    }

    &.has-focused .form-control {
        border-color: #5ac4f1;
    }
}

.form-input-icon {
    position: relative;

    > i {
        color: #ccc;
        position: absolute;
        z-index: 1 !important;
        margin: 9px 2px 4px 10px;
        width: 16px;
        font-size: 16px;
        text-align: center;
        left: 0;
    }

    .form-control {
        padding-left: 34px;
    }

    &.form-input-icon-right {
        > i {
            left: auto;
            right: 3px;
            margin: 11px 10px 4px 2px;
        }

        .form-control {
            padding-left: 16px;
            padding-right: 34px;
        }
    }
}

/* Material Design overrides
.mat-form-field {
    margin-bottom: 15px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.mat-form-field-infix {
    border-top: 0 !important;
    padding: 5px 0 !important;
}
*/

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #7e8e97 !important;
}

.mat-form-field-appearance-standard .mat-form-field-flex {
    padding: 0 !important;
}
