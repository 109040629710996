/*
 * MODAL
 */

.modal-backdrop {
    background: #24222f;

    &.in {
        opacity: .5;
    }
}

.modal .modal-dialog {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
    background: #fff;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    margin: 90px auto;
}

/* Details page that are displayed on modal window */

.details-modal .modal-dialog {
    min-width: 70vw;
}

.modal {
    z-index: 1056;

    .modal-content {
        border: none;
        padding: 24px;
    }

    .modal-header, .modal-footer {
        border: none;
        padding: 0;
    }

    .modal-header {
        .modal-title {
            margin: 0 0 20px;
            width: 100%;

            > input[type=text] {
                background-color: inherit !important;
                border-top: none;
                border-right: none;
                border-bottom: 1px solid;
                border-left: none;
                width: 100%;

                &:focus {
                    border-bottom-color: #5ac4f1 !important;
                }

                &[readonly] {
                    border: none !important;
                }
            }
        }

        .close {
            display: inline-block;
            font-size: 12px;
            height: 30px;
            margin: 0;
            outline: none !important;
            text-align: center;
            width: 30px;
        }
    }

    .modal-body {
        padding: 0 0 24px 0;
    }
}

.modal-footer {
    border-top: none !important;
    content: "";
    clear: both;
    display: table;
    width: 100%;

    > :not(:last-child) {
        margin-right: 15px !important;
    }
}

/* Other Sizes */

.modal {
    &.modal-size-small {
        padding-left: 10px;
        padding-right: 10px;

        .modal-dialog {
            max-width: 300px;
            width: auto;
        }
    }

    &.modal-size-large {
        padding-left: 10px;
        padding-right: 10px;

        .modal-dialog {
            max-width: 980px;
            width: auto;
        }
    }

    .modal-header.information, .modal-body.information, .modal-footer.information {
        border-color: #5ac4f1 !important;
        color: #5ac4f1 !important;
    }

    .modal-header.information .btn, .modal-body.information .btn, .modal-footer.information .btn {
        background: #5ac4f1 !important;
        border-color: #5ac4f1 !important;
        color: white !important;
    }

    .modal-header.success, .modal-body.success, .modal-footer.success {
        border-color: #46be8a !important;
        color: #46be8a !important;
    }

    .modal-header.success .btn, .modal-body.success .btn, .modal-footer.success .btn {
        background: #46be8a !important;
        border-color: #46be8a !important;
        color: white !important;
    }
}

@media (max-width: 543px) {
    .modal.modal-size-small .modal-dialog {
        max-width: none;
    }
}

/* Modal styles */
/* Information */

/* Warning */

.modal-header.warning {
    border-color: #ffa300 !important;
    color: #ffa300 !important;
}

.modal {
    .modal-body.warning, .modal-footer.warning {
        border-color: #ffa300 !important;
        color: #ffa300 !important;
    }
}

.modal-header.warning .btn {
    background: #ffa300 !important;
    border-color: #ffa300 !important;
    color: white !important;
}

.modal {
    .modal-body.warning .btn, .modal-footer.warning .btn {
        background: #ffa300 !important;
        border-color: #ffa300 !important;
        color: white !important;
    }
}

/* Error */

.modal-header.error {
    border-color: #ff0069 !important;
    color: #ff0069 !important;
}

.modal {
    .modal-body.error, .modal-footer.error {
        border-color: #ff0069 !important;
        color: #ff0069 !important;
    }
}

.modal-header.error .btn {
    background: #ff0069 !important;
    border-color: #ff0069 !important;
    color: white !important;
}

.modal {
    .modal-body.error .btn, .modal-footer.error .btn {
        background: #ff0069 !important;
        border-color: #ff0069 !important;
        color: white !important;
    }

    .modal-content {
        .panel {
            -webkit-box-shadow: none;
            box-shadow: none;
            color: inherit;
            height: 100%;

            &.outline {
                border: 1px solid #eceeef;
            }

            .panel-heading {
                padding: 15px;
            }

            .panel-body, .panel-footer {
                padding: 0 15px 15px 15px;
            }

            .panel-heading {
                h1, h2, h3, h4, h5, h6 {
                    font-weight: bold;
                }
            }
        }

        .widget {
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .widget-body {
            height: 100%;
        }
    }
}

/* Panels in modals */

/* Widgets in modals */

/* MATERIAL DESIGN */

.mat-dialog-title {
    margin: 0 !important;
}

.mat-mdc-dialog-content, .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 24px !important;
}
