/*
 * PAGINATION
 */

.pagination {
    .page-link {
        border-color: #dfe4ed;
        color: #827ca1;
        outline: none;
        margin-bottom: 10px;

        &:hover, &:focus {
            background: #e3dfcb;
            color: #ffffff;
            border-color: #e3dfcb;
        }
    }

    .page-item {
        &.disabled .page-link {
            background: #eceeef;
        }

        &.active .page-link {
            background: #5ac4f1;
            border-color: #5ac4f1;
        }
    }
}

.pager li > a {
    border-color: #dfe4ed;
    color: #827ca1;
    outline: none;
    -webkit-border-radius: 3px;
    border-radius: 3px;

    &:hover, &:focus {
        background: #e3dfcb;
        color: #ffffff;
        border-color: #e3dfcb;
    }
}
