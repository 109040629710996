/*
 * SELECTBOXES
 */

select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;

    &.form-control[multiple] {
        background: #fff;
    }
}
