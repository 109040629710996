/* GLOBAL STYLES */

.sonect-grey-bg {
    background: #668282 !important;
}

.sonect-grey-color {
    color: #668282 !important;
}

.sonect-beige-bg {
    background: #e3dfcb !important;
}

.sonect-beige-color {
    color: #e3dfcb !important;
}

.sonect-blue-bg {
    background: #5ac4f1 !important;
}

.sonect-blue-color {
    color: #5ac4f1 !important;
}

.sonect-berry-bg {
    background: #96006b !important;
}

.sonect-berry-color {
    color: #96006b !important;
}

.sonect-flamingo-bg {
    background: #ff0069 !important;
}

.sonect-flamingo-color {
    color: #ff0069 !important;
}

.sonect-orange-bg {
    background: #ffa300 !important;
}

.sonect-orange-color {
    color: #ffa300 !important;
}

.sonect-lime-bg {
    background: #bfe500 !important;
}

.sonect-lime-color {
    color: #bfe500 !important;
}

.sonect-transparent-bg {
    background: transparent !important;
}

.sonect-transparent-color {
    color: transparent !important;
}

html {
    font-size: 14px;
    height: 100%;
}

body {
    background: #eceeef !important;
    color: #1e1e1e;
    overflow-x: hidden;
    overflow-y: hidden;
}

a {
    -webkit-transition: all 0.05s ease-in-out;
    -o-transition: all 0.05s ease-in-out;
    transition: all 0.05s ease-in-out;

    &:hover, &:focus {
        color: #ff0069 !important;
        text-decoration: none;
        -webkit-transition: all 0.05s ease-in-out;
        -o-transition: all 0.05s ease-in-out;
        transition: all 0.05s ease-in-out;
    }
}

.clear-fix::after {
    content: "";
    clear: both;
    display: table;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.modal-open {
    .top-menu {
        padding-right: 15px;
    }

    &:not([style]) .top-menu {
        padding-right: 0 !important;
    }
}

.custom-scroll {
    overflow: auto;
}

.messaging-img {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin: 0 5px 10px 0;
    max-width: 200px;
}

.media-object {
    margin: 0 0 20px 0;
    max-width: 500px;

    &:last-child {
        margin-bottom: 13px;
    }
}

.donut-list li {
    margin-bottom: 9px;
}

.heading-buttons {
    margin-top: -6px;
}

/* PAGE CONTENT */

.page-content {
    min-height: 75vh;
    padding: 25px;
    -webkit-transition: opacity 0s ease-in-out;
    -o-transition: opacity 0s ease-in-out;
    transition: opacity 0s ease-in-out;
    opacity: 1;

    .page-title {
        align-items: center;
        display: flex;
        flex-direction: row;
        font-size: 24px;
        font-weight: 600;

        span {
            white-space: nowrap;
        }

        .page-title-actions {
            align-items: center;
            column-gap: 10px;
            display: flex;
            flex-direction: row;
            width: 100%;

            .display-inline-block {
                button, .btn {
                    top: -3px;
                }
            }

            .float-right {
                align-items: center;
                column-gap: 10px;
                display: flex;
                flex-direction: row;
                float: none !important;
                margin-left: auto !important;
            }
        }

        .page-title-alerts {
            margin-left: auto;
            font-size: 16px;
            animation: blinker 1.5s linear infinite;

            @keyframes blinker {
                50% {
                    opacity: 0.5;
                }
            }
        }
    }
}

body.cui-page-loading-state .page-content {
    opacity: .5;
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
}

.table colgroup [class^="col-"] {
    float: none;
}

pre {
    background: #f2f4f8;
    color: #514d6a;
    padding: 20px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

/* MAIN BACKDROP */

.main-backdrop {
    position: fixed;
    z-index: 50;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #24222f;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    &.main-backdrop-showed {
        opacity: .3;
        visibility: visible;
        -webkit-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
}

/* HIDDEN TOP AND LEFT MENU */

.hidden-left-menu {
    display: none;

    + {
        .top-menu, * + .page-content {
            margin-left: 0 !important;
        }
    }
}

.hidden-top-menu {
    display: none;

    + .page-content {
        margin-top: 0 !important;
    }
}

/* TOP MENU */

.top-menu {
    background: #ffffff;
    border-bottom: 1px solid #6a7a84;
    height: 80px !important;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 110;

    &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: -1px;
        width: 1px;
        height: 100%;
        background: #ffffff;
    }

    + .page-content {
        margin-top: 80px;
    }

    .menu-icon-container {
        font-size: 27px;
        float: left;
        margin: 25px 0 0 20px;
    }

    .menu {
        height: 80px;
        margin-left: 184px;
        padding: 21px 16px;
        width: 100%;

        .app-name {
            font-size: 28px;
        }

        .app-environment {
            border-radius: 3px;
            font-size: 20px;
            font-weight: bold;
            margin-left: 10px;
            padding: 0 15px;
            position: fixed;
        }

        .menu-user-block {
            float: right;
            font-weight: bold;
            font-size: 12px;

            + .menu-user-block {
                margin: 0 10px 0 20px;
            }

            &.menu-notifications .menu-notification-icon {
                display: block;
                width: 23px;
                height: 37px;
                font-size: 18px;
                line-height: 38px;
            }
        }

        .menu-info-block {
            margin-right: 80px;

            .left {
                float: left;

                .header-buttons {
                    margin-top: 9px;
                }
            }

            .right {
                float: right;
            }
        }

        .search-block {
            -webkit-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            width: 200px;

            .form-control {
                min-width: 200px;
            }

            &:hover {
                width: 300px;
                -webkit-transition: all 0.2s ease-in-out;
                -o-transition: all 0.2s ease-in-out;
                transition: all 0.2s ease-in-out;

                .form-control {
                    width: 100%;
                }
            }
        }
    }

    .dropdown-inline-button {
        padding: 0 15px;
    }
}

.search-block {
    position: relative;

    .form-input-icon > i {
        margin-top: 9px;
    }

    .search-block-submit {
        position: absolute;
        top: -1px;
        right: 0;
        height: 38px;
        width: 38px;
        background: none;
        border: none;
        z-index: 4;
        cursor: pointer;

        &:focus, &:active {
            outline: none !important;
        }
    }
}

/* LEFT MENU */

.left-menu {
    background: #384042 !important;
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    width: 240px;
    outline: none;

    + {
        * + .page-content {
            margin-left: 240px;
        }

        .top-menu {
            left: 240px;
        }
    }

    .jspScrollable .jspTrack {
        left: -5px !important;
    }

    .label {
        font-size: 80%;
    }

    .logo-container {
        background: #384042;
        border-bottom: 1px solid #384042;
        float: left;
        height: 80px;
        left: 0;
        padding: 12px 25px 0;
        position: absolute;
        top: 0;
        width: 240px;

        .logo {
            display: block;
            height: 50px;
            margin-left: -3px;

            img {
                display: block;
                max-width: 185px;
                max-height: 50px;

                &.logo-inverse {
                    display: none;
                }
            }
        }
    }

    .left-menu-inner {
        background: #384042 url(https://res.cloudinary.com/sonect/image/upload/v1581851658/assets/background-bottom-transparent.png) no-repeat bottom;
        background-size: contain;
        position: absolute;
        top: 90px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        outline: none !important;
    }

    .left-menu-list {
        .left-menu-label {
            float: right;
            margin: 3px 0 0 0;
        }

        .left-menu-link.active {
            color: #ff0069;
            background: #f2f4f8;
            font-weight: bold;
            -webkit-transition: 0s;
            -o-transition: 0s;
            transition: 0s;

            > .left-menu-link-icon {
                color: #ff0069 !important;
            }

            .mdc-list-item__content span {
                color: #ff0069;
            }
        }

        .left-menu-list-opened {
            background: #f2f4f8;

            > .left-menu-link {
                color: #24222f !important;

                .left-menu-link-icon {
                    color: #24222f !important;
                }
            }
        }

        .left-menu-link {
            color: #ffffff;
            font-size: 16px;
            outline: none;
            padding: 0 14px;
            -webkit-transition: all 0.1s ease-in-out;
            -o-transition: all 0.1s ease-in-out;
            transition: all 0.1s ease-in-out;
            text-decoration: none;

            .mdc-list-item__content span {
                color: #FFFFFF;
            }

            &:hover {
                background: #f2f4f8;
                color: #24222f;
                cursor: pointer;
                -webkit-transition: all 0.1s ease-in-out;
                -o-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out;

                .mdc-list-item__content span {
                    color: #ff0069;
                }

                .left-menu-link-icon {
                    color: #827ca1;
                }
            }

            .left-menu-link-icon {
                color: #5ac4f1;
                padding-top: 2px;
                -webkit-transition: all 0.1s ease-in-out;
                -o-transition: all 0.1s ease-in-out;
                transition: all 0.1s ease-in-out;
            }
        }

        .left-menu-item {
            display: block;
            padding: 10px 25px;
            color: #827ca1;

            .label {
                margin-right: 5px;
            }
        }

        .left-menu-list-submenu {
            > .left-menu-link {
                position: relative;

                &:after {
                    -webkit-transition: all 0.1s ease-in-out;
                    -o-transition: all 0.1s ease-in-out;
                    transition: all 0.1s ease-in-out;
                    display: block;
                    content: '';
                    width: 0;
                    height: 0;
                    border-width: 5px 0 5px 5px;
                    border-color: transparent transparent transparent #d2d9e5;
                    border-style: solid;
                    position: absolute;
                    top: 17px;
                    right: 20px;
                }
            }

            .left-menu-list {
                -webkit-transition: background 0s;
                -o-transition: background 0s;
                transition: background 0s;
                display: none;
                background: #f2f4f8;

                .left-menu-link {
                    font-weight: 400 !important;
                }
            }

            &.left-menu-list-opened > .left-menu-link:after {
                -webkit-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }

        .left-menu-list-separator {
            -webkit-transition: background 0s;
            -o-transition: background 0s;
            transition: background 0s;
            background: #acb7bf;

            /*#eceeef*/
            height: 1px;
            margin: 8px 20px;
            padding: 0;

            &:before {
                display: none;
            }
        }

        .left-menu-list-disabled {
            opacity: .6;

            .left-menu-link {
                color: #827ca1 !important;
                background: none !important;
                cursor: default;
            }

            &:before {
                display: none !important;
            }
        }
    }

    &.left-menu-showed {
        -webkit-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
    }

    .left-menu-block .left-menu-block-item {
        margin-bottom: 10px;
    }

    [class^='left-menu-list-color'], [class*=' left-menu-list-color'] {
        position: relative;
    }

    [class^='left-menu-list-color']:before, [class*=' left-menu-list-color']:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background: none;
    }
}

/* COLORFUL MENU */

.no-colorful-menu:before {
    display: none !important;
}

/* PANELS */

.page-content-inner > .panel:last-child {
    margin-bottom: 0;
}

.panel {
    background: #ffffff;
    border: 1px solid #dfe4ed;
    padding: 0;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 16px;

    .panel:last-child {
        //margin-bottom: 0;
    }

    .panel-heading {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 25px 25px 0 25px;

        a {
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            text-decoration: none;
        }

        h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child,
        .h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child{
            margin-bottom: 0 !important;
        }

        .panel-title {
            flex: 1 0 auto; // [flex-grow] [flex-shrink] [flex-basis]
            margin-bottom: 0 !important;
        }

        + .panel-body {
        }

        .dropdown-inline-button {
            padding: 0;
        }
    }

    .panel-body {
        padding: 25px;

        + .panel-footer {
            padding-top: 0;
        }
    }

    .panel-footer {
        padding: 25px;
    }

    &.panel-with-borders > {
        .panel-heading {
            border-bottom: 1px solid #dfe4ed;
        }

        .panel-body {
            padding-top: 25px;
        }

        .panel-footer {
            border-top: 1px solid #dfe4ed;
            margin-top: -1px;
            padding-top: 25px;
        }
    }

    &.panel-with-sidebar {
        position: relative;

        &:before {
            content: '';
            display: block;
            width: 1px;
            background: #dfe4ed;
            position: absolute;
            left: 240px;
            top: 0;
            height: 100%;
            z-index: 0;
        }

        &:after {
            display: table;
            clear: both;
            content: "";
        }

        .panel-sidebar {
            float: left;
            width: 240px;
            padding: 25px;
            margin-bottom: -25px;
        }

        .panel-heading, .panel-body, .panel-footer {
            margin-left: 240px;
        }

        &.panel-with-right-sidebar {
            &:before {
                left: auto;
                right: 240px;
            }

            .panel-sidebar {
                float: right;
                border-right: 0;
            }

            .panel-heading, .panel-body, .panel-footer {
                margin-left: 0;
                margin-right: 240px;
            }
        }
    }
}

.sidebar-large.panel {
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 1px;
        background: #dfe4ed;
        position: absolute;
        left: 300px;
        top: 0;
        height: 100%;
    }

    &.panel-with-sidebar {
        .panel-sidebar {
            float: left;
            width: 300px;
            padding: 25px;
            margin-bottom: -25px;
        }

        .panel-heading, .panel-body, .panel-footer {
            margin-left: 300px;
        }

        &.panel-with-right-sidebar {
            &:before {
                left: auto;
                right: 300px;
            }

            .panel-sidebar {
                float: right;
            }

            .panel-heading, .panel-body, .panel-footer {
                margin-left: 0;
                margin-right: 300px;
            }
        }
    }
}

/* AVATAR */

.avatar {
    width: 37px;
    height: 37px;
    background-color: #f2f4f8;
    border-color: #dfe4ed;
    display: inline-block;
    overflow: hidden;
    -webkit-border-radius: 500px;
    border-radius: 500px;

    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

/* ANIMATE MENU BUTTON */

.animate-menu-button {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 30px;
    height: 30px;
    font-size: 0;
    text-indent: -9999px;
    outline: none;

    div {
        margin: -1px 4px 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 3px;
        background: #bbb8cb;
        -webkit-transition: background .2s, -webkit-transform .2s;
        -o-transition: background .2s, -o-transform .2s;
        transition: transform .2s, background .2s, -webkit-transform .2s, -o-transform .2s;

        &:before, &:after {
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 3px;
            background: #bbb8cb;
            content: "";
        }

        &:before {
            top: -7px;
            -webkit-transform-origin: top right;
            -ms-transform-origin: top right;
            -o-transform-origin: top right;
            transform-origin: top right;
            -webkit-transition: width .2s, top .2s, -webkit-transform .2s;
            -o-transition: width .2s, top .2s, -o-transform .2s;
            transition: transform .2s, width .2s, top .2s, -webkit-transform .2s, -o-transform .2s;
        }

        &:after {
            bottom: -7px;
            -webkit-transform-origin: bottom right;
            -ms-transform-origin: bottom right;
            -o-transform-origin: bottom right;
            transform-origin: bottom right;
            -webkit-transition: width .2s, bottom .2s, -webkit-transform .2s;
            -o-transition: width .2s, bottom .2s, -o-transform .2s;
            transition: transform .2s, width .2s, bottom .2s, -webkit-transform .2s, -o-transform .2s;
        }
    }

    &.active div {
        -webkit-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        transform: rotate(-180deg);
        background: transparent;

        &:before, &:after {
            width: 97%;
        }

        &:before {
            top: 0;
            -webkit-transform: translateX(-2px) translateY(8px) rotate(45deg);
            -ms-transform: translateX(-2px) translateY(8px) rotate(45deg);
            -o-transform: translateX(-2px) translateY(8px) rotate(45deg);
            transform: translateX(-2px) translateY(8px) rotate(45deg);
        }

        &:after {
            bottom: 0;
            -webkit-transform: translateX(-2px) translateY(-8px) rotate(-45deg);
            -ms-transform: translateX(-2px) translateY(-8px) rotate(-45deg);
            -o-transform: translateX(-2px) translateY(-8px) rotate(-45deg);
            transform: translateX(-2px) translateY(-8px) rotate(-45deg);
        }
    }
}

/* BREADCRUMB */

.breadcrumb {
    -webkit-border-radius: 0;
    border-radius: 0;
    background: none;

    > li {
        &:before {
            display: none;
        }

        &:after {
            padding-right: .5rem;
            padding-left: .5rem;
            color: #bbb8cb;
            content: "/";
        }

        &:last-child:after {
            display: none;
        }
    }
}

/* TOP SUBMENU */

nav.top-submenu, .top-submenu {
    margin-bottom: 16px;
    padding: 16px 10px;
}

nav.top-submenu {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: 11px;
    }
}

.top-submenu {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: 11px;
    }
}

nav.top-submenu .breadcrumb, .top-submenu .breadcrumb {
    padding: 0;
    margin: 0;
}

nav.top-submenu.top-submenu-with-background, .top-submenu.top-submenu-with-background {
    background: #ffffff;
    padding: 26px;
    margin: -16px -16px 16px -16px;
    border-bottom: 1px solid #dfe4ed;
}

/* SUPERCLEAN MODE */

.mode-superclean {
    background: #ffffff;

    .page-content {
        background: #eceeef;
        overflow-x: hidden;
    }

    .panel {
        border: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
    }

    .top-submenu {
        padding: 0 25px;

        &.top-submenu-with-background {
            padding: 25px;
            margin: 0 0 25px 0;
        }
    }

    .dashboard-container {
        padding: 16px 0 1px;
        margin-bottom: 0;
    }

    .widget {
        -webkit-border-radius: 0;
        border-radius: 0;

        .widget-header {
            -webkit-border-radius: 0;
            border-radius: 0;
        }
    }

    .chart-css-animations, .step-block {
        -webkit-border-radius: 0;
        border-radius: 0;
    }
}

@media (max-width: 767px) {
    .mode-superclean .dashboard-container {
        padding-top: 15px;
    }
}

@media (max-width: 543px) {
    .mode-superclean .dashboard-container {
        padding-top: 10px;
    }
}

/* SQUARED MODE */

.mode-squared {
    .panel {
        -webkit-border-radius: 0;
        border-radius: 0;
    }

    .widget {
        -webkit-border-radius: 0;
        border-radius: 0;

        .widget-header {
            -webkit-border-radius: 0;
            border-radius: 0;
        }
    }

    .chart-css-animations, .step-block {
        -webkit-border-radius: 0;
        border-radius: 0;
    }
}

/* BOX-SHADOW MODE */

.mode-box-shadow .left-menu {
    -webkit-box-shadow: 0 0 50px rgba(36, 34, 47, 0.3);
    box-shadow: 0 0 50px rgba(36, 34, 47, 0.3);
}

/* FIXED MENU MODE */

.menu-static {
    .page-content {
        margin-top: 0 !important;
    }

    .top-menu {
        position: relative;
        left: 0 !important;
    }

    .left-menu + .top-menu {
        margin-left: 240px;
    }
}

/* SINGLE PAGE (LOGIN, REGISTRATION ETC) */

body {
    &.single-page {
        height: 100%;

        .page-content {
            height: 100%;
            max-width: none !important;
            padding: 0;

            .page-content-inner {
                background-size: cover;
                min-height: 100%;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-pack: justify;
                -webkit-justify-content: space-between;
                -ms-flex-pack: justify;
                justify-content: space-between;

                &.background-repeated {
                    background-size: auto;
                }
            }
        }

        .single-page-block {
            color: #ffffff;

            a {
                color: #ffffff;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &.single-page-inverse .single-page-block-header {
        color: #514d6a !important;

        .single-page-block-header-menu ul li {
            &.active a {
                border-bottom: 1px solid rgba(81, 77, 106, 0.6);
            }

            a {
                color: #514d6a !important;

                &:hover {
                    border-bottom: 1px solid rgba(81, 77, 106, 0.6);
                }
            }
        }
    }
}

@media (max-width: 991px) {
    body.single-page {
        .single-page-block {
            padding: 0 20px;

            .single-page-block-inner {
                padding: 40px 30px 20px;
                -webkit-border-radius: 8px;
                border-radius: 8px;
            }
        }

        .single-page-block-header, .single-page-block-footer {
            padding: 40px 20px;
        }
    }
}

@media (max-width: 991px) and (max-width: 991px) {
    body.single-page .single-page-block-header .single-page-block-header-menu {
        text-align: left;
        margin-top: 30px;

        ul {
            margin-bottom: 0;

            li {
                margin-bottom: 0;
            }
        }
    }
}

.single-page-block {
    padding: 80px 20px 80px;
    width: 100%;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    z-index: 2;

    .single-page-block-inner {
        min-width: 300px;
        max-width: 500px;
        margin: 0 auto;
        padding: 50px 40px 20px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        overflow: hidden;
        background-color: #d2d9e5;
        position: relative;

        h3 {
            color: #ffffff;
            text-align: left;
            text-transform: uppercase;
        }
    }

    .avatar {
        height: 80px;
        width: 80px;
        margin-bottom: 10px;
    }

    .blur-placeholder {
        position: absolute;
        z-index: 1;
        background-size: cover;
        -webkit-filter: blur(20px);
        filter: blur(20px);
        opacity: .8;
    }

    .single-page-block-form {
        position: relative;
        z-index: 2;

        input.form-control {
            border-color: #ffffff !important;
            opacity: 0.7;

            &:focus {
                opacity: 1;
            }
        }

        .input-group-addon {
            border-color: #ffffff !important;
            opacity: 0.7;

            &:hover {
                opacity: 1;
            }
        }

        .form-actions {
            border-top-color: rgba(255, 255, 255, 0.4);
        }

        .form-input-icon i {
            color: #514d6a;
        }
    }
}

.single-page-block-header {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    width: 100%;
    color: #ffffff;
    padding: 40px;

    .logo {
        max-width: 150px;
        height: 50px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .single-page-block-header-menu {
        text-align: right;

        ul {
            font-size: 16px;

            li {
                margin-right: 20px;
                text-transform: uppercase;

                &:last-child {
                    margin-right: 0;
                }

                &.active a {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
                }

                a {
                    color: #ffffff !important;
                    line-height: 1.4;
                    display: inline-block;
                    font-weight: bold;

                    &:hover {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
                    }
                }
            }
        }
    }
}

.single-page-block-footer {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    width: 100%;
    padding: 40px;
    color: white;

    ul {
        margin-bottom: 0;

        li {
            margin-right: 20px;
            text-transform: uppercase;

            &:last-child {
                margin-right: 0;
            }

            a {
                color: #24222f !important;
                line-height: 1.4;
                display: inline-block;

                &:hover {
                    color: #ff0069 !important;
                    text-decoration: none;
                }
            }
        }
    }
}

.single-page-login-alpha {
    .single-page-block {
        padding: 10px 20px !important;

        .single-page-block-inner {
            background-color: #ffffff;
            -webkit-box-shadow: 0 15px 70px -5px rgba(36, 34, 47, 0.13);
            box-shadow: 0 15px 70px -5px rgba(36, 34, 47, 0.13);
            margin-bottom: 50px;
            -webkit-border-radius: 0;
            border-radius: 0;
            max-width: 600px;
            padding: 60px;

            .single-page-block-form {
                color: #24222f;

                h3, a {
                    color: #24222f !important;
                }

                input, .input-group-addon {
                    border-color: #5ac4f1 !important;
                }

                input {
                    &:focus, &:active {
                        border-color: #5ac4f1 !important;
                    }
                }

                .input-group-addon {
                    &:focus, &:active {
                        border-color: #5ac4f1 !important;
                    }

                    border-left: none !important;
                }

                .form-actions {
                    border-top-color: #dfe4ed !important;
                }
            }

            a.link-blue {
                color: #827ca1 !important;
                text-decoration: none;

                &:hover {
                    color: #0088ff !important;
                }
            }

            .register-link {
                display: inline-block;
                margin: 7px 0 0 15px;
            }

            .social-login {
                text-align: center;
                border-top: 1px solid #dfe4ed !important;
                padding-top: 15px;

                .title {
                    display: inline-block;
                }

                .social-container {
                    padding-top: 15px;

                    a {
                        margin: 0 5px;
                    }
                }
            }
        }
    }

    .welcome-text {
        color: #ffffff;
        padding: 0 0 30px;
        max-width: 800px;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        margin: 0 auto;

        .logo {
        }
    }

    .logo img {
        max-width: 300px;
        height: auto;
    }

    .welcome-text {
        h3, h5, h1 {
            color: #292b2c;
            text-transform: uppercase;
        }

        p {
            color: white;
        }
    }
}

@media (max-width: 767px) {
    .single-page-login-alpha .single-page-block {
        padding: 20px !important;
    }
}

@media (max-width: 767px) {
    .single-page-login-alpha .single-page-block .single-page-block-inner .register-link {
        margin-left: 0;
        display: block;
    }
}

.single-page-login-beta {
    .single-page-block-header {
        padding-bottom: 10px;
    }

    .single-page-block {
        padding: 10px 20px !important;

        .single-page-block-inner {
            background-color: #ffffff;
            -webkit-box-shadow: 0 15px 70px -5px rgba(36, 34, 47, 0.13);
            box-shadow: 0 15px 70px -5px rgba(36, 34, 47, 0.13);
            margin-bottom: 0;
            -webkit-border-radius: 0 !important;
            border-radius: 0 !important;
            max-width: 940px;
            padding: 0;
            height: 687px;
            position: relative;

            .single-page-block-form {
                padding: 100px 120px 70px;
                color: #24222f;
                margin-right: 300px;

                h3, a {
                    color: #24222f !important;
                }

                h3 .small, a .small {
                    color: #24222f;
                    font-size: 14px;
                    display: block;
                    margin-top: 10px;
                    font-weight: normal;
                    text-transform: none;
                }

                input, .input-group-addon {
                    border-color: #dfe4ed !important;
                }

                input {
                    &:focus, &:active {
                        border-color: #5ac4f1 !important;
                    }
                }

                .input-group-addon {
                    &:focus, &:active {
                        border-color: #5ac4f1 !important;
                    }

                    border-left: none !important;
                }

                .form-actions {
                    border-top-color: #dfe4ed !important;
                }
            }

            .single-page-block-sidebar {
                width: 300px;
                background: #eceeef;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                background-size: cover;
                background-position: center center;
            }

            .single-page-block-sidebar--shadow {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(23, 41, 75, 0.6);
            }

            .single-page-block-sidebar--content {
                position: relative;
                z-index: 2;
                padding: 90px 40px 40px;
            }

            .single-page-block-sidebar--content--title {
                margin-bottom: 20px;
                font-size: 24px;
            }

            .single-page-block-sidebar--content--item {
                padding-left: 20px;
                border-left: 2px solid #24222f;
                margin-bottom: 20px;
                color: #24222f;
            }

            .single-page-block-sidebar--place {
                font-size: 14px;
                font-weight: bold;
                position: absolute;
                z-index: 2;
                bottom: 40px;
                left: 40px;
            }

            a.link-blue {
                color: #827ca1 !important;
                text-decoration: none;

                &:hover {
                    color: #0088ff !important;
                }
            }

            .register-link {
                display: block;
                margin: 7px 0 0 0;
            }

            .social-login {
                text-align: left;
                border-top: 1px solid #dfe4ed !important;
                padding-top: 15px;

                .title {
                    display: inline-block;
                }

                .social-container {
                    padding-top: 15px;

                    a {
                        margin: 0 10px 0 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .single-page-login-beta .single-page-block .single-page-block-inner {
        height: auto !important;

        .single-page-block-form {
            padding: 100px 60px 70px !important;
        }
    }
}

@media (max-width: 767px) {
    .single-page-login-beta .single-page-block {
        padding: 20px !important;

        .single-page-block-inner {
            padding: 10px 0 0 !important;

            .single-page-block-form {
                padding: 40px 30px !important;
                margin-right: 0 !important;
            }

            .single-page-block-sidebar {
                display: none;
            }
        }
    }
}

@media (max-width: 767px) {
    .single-page-login-beta .single-page-block .single-page-block-inner .register-link {
        margin-left: 0;
        display: block;
    }
}

.lockscreen-typer {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px;
    -webkit-filter: blur(2px);
    filter: blur(2px);
    font-size: 21px;

    .locksreen-typer-inner {
        overflow: hidden;
        width: 100%;
        height: 100%;
        opacity: .5;
        line-height: 1;
        padding-bottom: 30px;
    }
}

@media (max-width: 543px) {
    .lockscreen-typer .locksreen-typer-inner {
        padding-bottom: 0;
    }
}

/* MATERIAL EFFECTS */

.mode-material {
    .top-menu {
        -webkit-box-shadow: 0 2px 10px -5px rgba(55, 58, 60, 0.3);
        box-shadow: 0 2px 10px -5px rgba(55, 58, 60, 0.3);
    }

    .widget, .panel {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .widget.widget-four, .panel.widget-four {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .widget.widget-four .step-block, .panel.widget-four .step-block {
        -webkit-box-shadow: 0 2px 10px -2px rgba(55, 58, 60, 0.3);
        box-shadow: 0 2px 10px -2px rgba(55, 58, 60, 0.3);
        border: none;
    }

    .widget .panel, .panel .panel {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .btn, .left-menu-link {
        position: relative;
        overflow: hidden;
        -webkit-transform: translate3d(0, 0, 0);
    }

    .btn {
        -webkit-box-shadow: 0 2px 4px -2px rgba(55, 58, 60, 0.6);
        box-shadow: 0 2px 4px -2px rgba(55, 58, 60, 0.6);
    }
}

.ink {
    display: block;
    position: absolute;
    pointer-events: none;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    background: rgba(140, 150, 167, 0.4);
    opacity: 1;
}

.btn-primary .ink, .btn-secondary .ink, .btn-success .ink, .btn-info .ink, .btn-warning .ink, .btn-danger .ink, .btn-default .ink, .btn-primary-outline .ink, .btn-secondary-outline .ink, .btn-success-outline .ink, .btn-info-outline .ink, .btn-warning-outline .ink, .btn-danger-outline .ink, .btn-default-outline .ink {
    background: rgba(255, 255, 255, 0.5);
}

.ink.animate {
    -webkit-animation: ripple .5s linear;
    -o-animation: ripple .5s linear;
    animation: ripple .5s linear;
}

@keyframes ripple {
    100% {
        opacity: 0;
        -webkit-transform: scale(2.5);
        -o-transform: scale(2.5);
        transform: scale(2.5);
    }
}

@-webkit-keyframes ripple {
    100% {
        opacity: 0;
        -webkit-transform: scale(2.5);
        transform: scale(2.5);
    }
}

@-o-keyframes ripple {
    100% {
        opacity: 0;
        -o-transform: scale(2.5);
        transform: scale(2.5);
    }
}

/* FOOTER BLOCK */

.cwt__footer {
    display: none;
    background: #38354a;
    color: #827ca1;
    padding: 50px 60px 40px;
    font-size: 14px;
    margin-right: 16px;
    margin-left: 16px;
    -webkit-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}

.left-menu + * + * + .cwt__footer {
    margin-left: 256px;
}

@media (max-width: 767px) {
    .left-menu + * + * + .cwt__footer {
        margin-left: 16px;
    }
}

body {
    &.mode-superclean .left-menu + * + * + .cwt__footer {
        margin-left: 240px;
    }

    &.menu-top .left-menu + * + * + .cwt__footer {
        margin-left: 16px;
    }

    &.layout-boxed .left-menu + * + * + .cwt__footer {
        max-width: 1248px;
        margin-left: auto;
        margin-right: auto;
    }

    &.mode-squared .cwt__footer {
        -webkit-border-radius: 0;
        border-radius: 0;
    }

    &.mode-superclean .cwt__footer {
        margin-left: 0;
        margin-right: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
    }

    &.theme-blue .cwt__footer {
        background: #0b0222;
    }
}

@media (max-width: 767px) {
    body.mode-superclean .left-menu + * + * + .cwt__footer {
        margin-left: 0;
    }
}

.cwt__footer.visible-footer {
    display: block;
}

.cwt__footer__title {
    text-transform: uppercase;
    margin-bottom: 30px;
    margin-top: 10px;
    font-size: 18px;
}

.cwt__footer__title--light {
    color: #dfe4ed;
}

.cwt__footer__link {
    color: #827ca1 !important;
    text-decoration: underline;
    display: inline-block;
    margin-bottom: 10px;

    &:hover {
        text-decoration: none;
    }
}

.cwt__footer__bottom {
    margin-top: 30px;
    padding-top: 40px;
    border-top: 1px solid rgba(187, 184, 203, 0.2);
}

.cwt__footer__company {
    padding-left: 50px;
    position: relative;
    line-height: 11px;
    display: inline-block;
    float: right;
    margin-top: 5px;
}

@media (max-width: 767px) {
    .cwt__footer__company {
        float: none;
        margin-top: 30px;
    }
}

.cwt__footer__company-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
}

/* NOTIFICATION BLOCK (TOP MENU) */

.notification-block {
    width: 290px;
    margin: -7px 0;
    background: #ffffff;

    .item {
        border-bottom: 1px solid #d2d9e5;
        padding: 10px 0;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background: #f9fafb;
        }

        .notification-icon {
            float: left;
            font-size: 18px;
            width: 35px;
            text-align: right;
            margin: 8px 0 0 0;
            color: #827ca1;
        }

        .inner {
            margin-left: 50px;
            padding-right: 15px;

            .descr {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .title > span {
                font-size: 13px;
            }

            .label {
                font-size: 12px;
            }
        }
    }
}
