.partner-details {
    .mat-icon[mattooltip], .mat-icon.mat-tooltip-trigger {
        color: #BFBFBF;
        margin-left: 10px;
        font-size: 16px;
        width: 16px;
        height: 16px;
    }

    .partners-tabs {
        .mat-icon {
            color: #BFBFBF;
            margin-left: 10px;
            font-size: 16px;
            width: 16px;
            height: 16px;
        }

        .additional-fee {
            mat-form-field {
                .mat-icon {
                    color: rgba(0, 0, 0, 0.3);
                    font-size: initial;
                    width: initial;
                    height: initial;
                }
            }
        }
    }
}
