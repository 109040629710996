/*
 * COLLAPSE
 */

.accordion {
    .panel {
        -webkit-border-radius: 0;
        border-radius: 0;
        margin-bottom: 0;
        border-bottom-width: 0;

        &:first-child {
            -webkit-border-radius: 5px 5px 0 0;
            border-radius: 5px 5px 0 0;
        }

        &:last-child {
            -webkit-border-radius: 0 0 5px 5px;
            border-radius: 0 0 5px 5px;
            border-bottom-width: 1px;
        }

        .panel-heading {
            padding: 10px 20px;
            cursor: pointer;
        }

        .panel-body {
            padding: 0 20px 10px 20px;
        }
    }

    &.accordion-margin-bottom .panel {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        margin-bottom: 10px;
        border-bottom-width: 1px;
    }

    .accordion-indicator {
        padding-top: 1px;
        color: #dfe4ed;

        .plus {
            display: none;
        }

        .minus {
            display: inline;
        }
    }

    .collapsed .accordion-indicator {
        .plus {
            display: inline;
        }

        .minus {
            display: none;
        }
    }
}
