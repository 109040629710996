/*
 * CHECKBOXES & RADIOS
 */

.radio-wrapper, .checkbox-wrapper {
    display: inline-block;
    min-width: 100px;
}

.radio-wrapper.no-label, .checkbox-wrapper.no-label {
    min-width: 0;
}

.radio, .checkbox {
    display: inline-block;
}

.radio label input {
    &[type=radio], &[type=checkbox] {
        margin-right: 5px;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
}

.checkbox label input {
    &[type=radio], &[type=checkbox] {
        margin-right: 5px;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
    }
}

label[disabled] {
    cursor: not-allowed;
    color: #bbb8cb;

    &.btn {
        opacity: .6;
        cursor: not-allowed;
    }
}

/* Material design */

.mat-checkbox-layout {
}

.mat-checkbox-inner-container {
    margin-right: 0 !important;
    top: 1px;
}

.mat-checkbox-label {
    padding-left: 5px !important;
}

.mat-radio-button {
}

.mat-radio-container, .mat-radio-outer-circle, .mat-radio-inner-circle {
    height: 16px !important;
    width: 16px !important;
}

.mat-radio-label-content {
    padding-left: 5px !important;
}
